import styled from "styled-components";
import { Link, NavLink } from 'react-router-dom'
import { CommonHelper } from "../helpers/CommonHelper";
import { useEffect, useState } from "react";

import depositing_technology from "../assets/img/depositing_technology.svg"
import depositing_sound from "../assets/img/depositing_sound.svg"
import depositing_text from "../assets/img/depositing_text.svg"
import depositing_image from "../assets/img/depositing_image.svg"
import depositing_video from "../assets/img/depositing_video.svg"
import depositing_code from "../assets/img/depositing_code.svg"
import depositing_arrow from "../assets/img/depositing_arrow.svg"
import { CBreadcrumb, CBreadcrumbItem, CButton } from "@coreui/react";
import { DepositInfoInput } from "../components/depositing/DepositInfoInput";
import { useSelector } from 'react-redux';
import { getRoleName } from "./ProfilePage";

const rias = [
    // {
    //     text: "Технология",
    //     image: depositing_technology,
    //     subtypes: [
    //         {text: "секрет производства"},
    //         {text: "технические инструкции"}
    //     ]
    // },
    {
        text: "Звук",
        image: depositing_sound,
        subtypes: [
            // {text: "мелодия"},
            {text: "музыкальное произведение"},
            {text: "фонограмма"}
        ]
    },
    {
        text: "Текст",
        image: depositing_text,
        subtypes: [
            {text: "научный отчёт"},
            {text: "книга"},
            {text: "кулинарный рецепт"},
            {text: "произведение науки"},
            {text: "произведение"},
            {text: "учебное пособие"}
        ]
    },
    {
        text: "Изображение",
        image: depositing_image,
        subtypes: [
            {text: "дизайн"},
            {text: "инфографика"},
            {text: "комикс"},
            {text: "логотип"},
            {text: "рисунок"},
            {text: "фотография"},
            {text: "чертеж"}
        ]
    },
    {
        text: "Видео",
        image: depositing_video,
        subtypes: [
            {text: "видеоролик"},
            {text: "видеокурс"},
            {text: "видеолекция"},
            {text: "видеофильм"},
            {text: "компьютерная игра"}
        ]
    },
    {
        text: "Код",
        image: depositing_code,
        subtypes: [
            {text: "база данных"},
            {text: "интерфейс"},
            // {text: "образовательная программа"},
            {text: "программа для ЭВМ"},
            {text: "электронная обучающая платформа"}
        ]
    }
]

export default function DepositingPage(props) {
    const {} = props;

    const [typeSelected, setTypeSelected] = useState(undefined);
    const [subtypeSelected, setSubtypeSelected] = useState(undefined);
    const [step, setStep] = useState(0);
    let depositData;

    const currentUser = useSelector(state => state.users.currentUser);

    useEffect(() => {
        depositData = localStorage.getItem('depositData');
        if (depositData) {
            depositData = JSON.parse(depositData);
            let typeIndex = 0;
            if (depositData.type) {
                for (let item of rias) {
                    if (item.text == depositData.type) {
                        break;
                    }
                    typeIndex += 1;
                }
                setTypeSelected(typeIndex);
            }
            if (depositData.subtype) {
                let index = 0;
                for (let item of rias[typeIndex].subtypes) {
                    if (item.text == depositData.subtype) {
                        break;
                    }
                    index += 1;
                }
                setSubtypeSelected(index);
            }
            if (depositData.author) {
                setStep(1);
            }
        }
    }, []);

    useEffect(() => {
        let data;
        if (subtypeSelected) {
            data = {
                type: rias[typeSelected]?.text,
                subtype: rias[typeSelected].subtypes[subtypeSelected]?.text
            };
        } else if (typeSelected) {
            data = {
                type: rias[typeSelected]?.text
            };
        }        

        if (data) {
            localStorage.setItem('depositData', JSON.stringify(data));
        }
    }, [typeSelected, subtypeSelected])

    return (
        <Wrapper>
            <TopPlaceholder>
                <CBreadcrumb>
                    <CBreadcrumbItemCustom>
                        <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                            Личный кабинет {getRoleName(currentUser.userType)}
                        </Link>
                    </CBreadcrumbItemCustom>
                    <CBreadcrumbItemCustom active>Депонирование РИД</CBreadcrumbItemCustom>
                </CBreadcrumb>
            </TopPlaceholder>

            {step !== 0 ? null :
                <CenterPlaceholder>
                    <Header>Тип РИД</Header>
                    <RIAComponent>
                        {rias.map((item, id) => {
                            return (
                                <RIAUnit
                                    key={`ria_${id}`}
                                    $selected={id === typeSelected}
                                    onClick={() => {
                                        setSubtypeSelected(undefined);
                                        setTypeSelected(id);
                                    }}>
                                    <img src={item.image} />
                                    <RIAUnitText>{`${item.text}`}</RIAUnitText>
                                </RIAUnit>
                            );
                        })}
                    </RIAComponent>

                    {typeSelected === undefined ? null :
                        <SubRIA>
                            <Header>Подтип РИД</Header>
                            <SubRIAComponent>
                                {rias[typeSelected].subtypes.map((item, id) => {
                                    return (
                                        <SubRIAUnit
                                            key={`sub_ria_${id}`}
                                            $selected={id === subtypeSelected}
                                            onClick={() => {
                                                setSubtypeSelected(id);
                                            }}>
                                            <SubRIAUnitText>
                                                {item.text}
                                            </SubRIAUnitText>
                                        </SubRIAUnit>
                                    );
                                })
                                }
                            </SubRIAComponent>
                            {subtypeSelected == undefined ? null :
                                <ContinueButton onClick={() => {setStep(1)}}>
                                    Далее
                                    <ContinueIcon src={depositing_arrow} />
                                </ContinueButton>
                            }
                        </SubRIA>
                    }
                </CenterPlaceholder>
            }

            {step !== 1 ? null :
                <DepositInfoInput
                    type={rias[typeSelected].text}
                    subtype={rias[typeSelected].subtypes[subtypeSelected].text}
                    goBack={() => {
                        setStep(0);
                    }}
                />
            }
    </Wrapper>
    );
}

const Wrapper = styled.div`
    max-width: 1320px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const TopPlaceholder = styled.div`
  margin-top: 50px;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;

const CenterPlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media (max-width: 400px) {
        gap: 20px;
    }
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 600;
`;

const RIAComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
`;

const RIAUnit = styled.div`
    width: 180px;
    height: 168px;
    border: ${props => props.$selected ? '3px solid var(--primary)' : '1px solid var(--border-light)'};
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
        border: 3px solid var(--primary);
    }
`;

const RIAUnitText = styled.div`
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
`;

const SubRIA = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SubRIAComponent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 60px;
    margin-top: 50px;

    @media (max-width: 400px) {
        margin-top: 20px;
    }
`;

const SubRIAUnit = styled.div`
    height: 48px;
    width: fit-content;
    border: ${props => props.$selected ? '2px solid var(--primary)' : '1px solid var(--border-light)'};
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
        border: 2px solid var(--primary);
    }
`;

const SubRIAUnitText = styled.div`
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
`;

const ContinueButton = styled.button`
    border: none;
    border-radius: 10px;
    background-color: var(--primary);
    color: white;
    font-size: 24px;
    font-weight: 600;
    padding: 15px 80px;
    margin-bottom: 120px;
`;

const ContinueIcon = styled.img`
    width: 24px;
    height: 24px;
    user-select: none;
`;