import React, { useState } from 'react'
import styled from 'styled-components';
import { CSpinner } from '@coreui/react';
import { Dropzone } from './Dropzone';

export const ImageUploader = (props) => {
    const {
        currentUser = undefined,
        onUpload = (data) => {

        },
        onClose = () => {

        }
    } = props;

    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async evt => {
        evt.preventDefault();
        evt.stopPropagation();

        const formData = new FormData();
        formData.append('image', file);
        formData.append('email', currentUser.email)

        await onUpload(formData);
        setLoading(false);
        onClose();
    }

    return (
        <Wrapper>
            <DropzonePlaceholder>
                <Dropzone
                    accept={{
                        'image/*': ['.jpg', '.png', '.jpeg']
                    }}
                    onFileChange={(file) => {
                        setFile(file);
                    }}
                />
            </DropzonePlaceholder>
            
            <SaveButton
                onClick={(evt) => {
                    setLoading(true);
                    handleSubmit(evt);
                }}
            >
                {loading ? <CSpinner size='sm' /> : 'Сохранить'}
            </SaveButton>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-width: 300px;
    min-height: 300px;
`;

const DropzonePlaceholder = styled.div`
    width: 400px;
    height: 300px;
`;

const UploadedImage = styled.img`
    width: 100%;
    height: 100%;
`;

const SaveButton = styled.button`
    width: 100%;
    margin: auto;
    display: block;
    width: fit-content;
    margin-top: 10px;
    padding: 10px 40px;
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
`;