import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom';
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";

export const AdminPage = () => {
    const currentUser = useSelector(state => state.users.currentUser);

    if (currentUser == undefined || currentUser.userType != 4) {
        return <Navigate to='/'/>
    }

    return (
        <Wrapper>
            <CBreadcrumb>
                <CBreadcrumbItemCustom>
                    <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                        Личный кабинет администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom active>Панель администратора</CBreadcrumbItemCustom>
            </CBreadcrumb>

            <ButtonsPlaceholder>
                <Link to='/админ/статистика'>
                    <Button>Статистика</Button>
                </Link>

                <Link to='/админ/пользователи'>
                    <Button>Пользователи</Button>
                </Link>
    
                <Link to='/админ/рид'>
                    <Button>РИД</Button>
                </Link>

                {/* <Link to='/админ/документы'>
                    <Button>Изменить документы</Button>
                </Link> */}
            </ButtonsPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
`;

const ButtonsPlaceholder = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const Button = styled.button`
    width: 200px;
    height: 140px;
    border-radius: 10px;
    border: none;
    background-color: white;
    box-shadow: var(--box-shadow);
    color: var(--primary);
    font-weight: 500;
    font-size: 24px;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;