import { API_ENDPOINT } from "../config";
import axios from "axios";
import $api from './axiosSettings';

axios.defaults.withCredentials = true;

const DepositAPI = {
    async updateDepositInfo(data) {
        try {
            
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async deposit(formData) {
        try {
            let res = await $api.post(`${API_ENDPOINT}/api/deposit/deposit`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                }
            });
            console.log(res);
            return res.data;
        } catch (error) {
            console.log('error: ', error);
            throw error.response?.data?.msg;
        }
    },

    async getDepositInfo() {
        try {
            
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getAllDeposits() {
        try {
            const res = await $api.get(`${API_ENDPOINT}/api/deposit/userDeposits`);
            return res.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getRegister(offset = 1, count = 10) {
        try {
            const res = await $api.get(`${API_ENDPOINT}/api/deposit/register?count=${count}&offset=${offset}`);
            return res.data;
        } catch (error) {
            console.log('getRegister error: ', error);
        }
    },

    async getRegistryCount() {
        try {
            const res = await $api.get(`${API_ENDPOINT}/api/deposit/count`);
            return res.data;
        } catch (error) {
            console.log('getRegistryCount error: ', error);
        }
    },

    async getRightholderInfo() {
        try {
            const res = await $api.get(`${API_ENDPOINT}/api/rightholder/`);
            return res.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async updateRightholderInfo(data) {
        try {
            const res = await $api.post(`${API_ENDPOINT}/api/rightholder/update`, data);
            return res.data;
        } catch (error) {
            console.log('error:', error);
        }
    },

    async getTsuInfo() {
        try {
            const res = await $api.get(`${API_ENDPOINT}/api/rightholder/tsu`);
            return res.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async checkRid(id) {
        try {
            const res = await axios.get(`${API_ENDPOINT}/api/deposit/certificate/${id}`);
            if (res?.data?.creationDate == undefined) {
                return undefined;
            }
            return res.data;
        } catch (error) {
            console.log('error: ', error);
        }
    }
}

export default DepositAPI;