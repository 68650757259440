import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import arrow_back from '../../assets/img/arrow_left_blue.svg';
import edit from '../../assets/img/edit_blue.svg';
import delete_icon from '../../assets/img/delete.svg';
import add_coauthor from '../../assets/img/add_coauthor.svg';
import { CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CTooltip, CSpinner } from '@coreui/react';
import { AuthorInfoModal } from '../modals/AuthorInfoModal';
import { RightHolderInfoModal } from '../modals/RightHolderInfoModal';
import { CoauthorInfoModal } from '../modals/CoauthorInfoModal';
import { IMaskMixin } from 'react-imask';
import { AuthorInfo } from './AuthorInfo';
import { CoauthorsInfo } from './CoauthorsInfo';
import { RightHolderInfo } from './RightHolderInfo';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import * as filesActions from '../../redux/actions/FilesActions.js';
import { Navigate } from 'react-router-dom';
import DepositAPI from '../../helpers/api/DepositAPI.js';

const authorKeys = ['lastName', 'firstName', 'email', 'address', 'inn', 'dateBorn', 'citizenship'];
const coauthorKeys = ['lastName', 'firstName', 'email', 'address', 'inn', 'dateBorn', 'citizenship'];
const rightHolderKeys = ['name', 'short_name', 'inn', 'ogrn', 'address'];

let emailRegex = /.+@.+\..+/g;

const infoError = (error) => {
    switch (error) {
        case 1:
            return 'Заполните информацию';
        case 2:
            return 'Заполните информацию об авторе';
        case 3:
            return 'Заполните информацию о правообладателе';
        case 4:
            return 'Заполните информацию о соавторах';
        case 5:
            return 'Неверная почта у автора';
        case 6:
            return 'Неверная почта у соавтора';
        default:
            break;
    }
}

const checkInfo = (data) => {
    if (data == undefined) {
        return 1;
    }
    if (data.author == undefined) {
        return 2;
    }
    for (let key of authorKeys) {
        if (data.author[key] == undefined || data.author[key].length == 0) {
            return 2;
        }
        if (key == 'email' && !data.author[key].match(emailRegex)) {
            return 5;
        }
    }
    if (data.rightHolder == undefined) { 
        return 3;
    }
    if (data.rightHolder?.authorIsOwner == true) {
        return 0;
    }
    for (let key of rightHolderKeys) {
        if (data.rightHolder[key] == undefined || data.rightHolder[key].length == 0) {
            return 3;
        }
    }
    if (data.coauthors == undefined) {
        return 0;
    }
    for (let coauthor of data?.coauthors) {
        for (let key of coauthorKeys) {
            if (coauthor[key] == undefined || coauthor[key].length == 0) {
                return 4;
            }
            if (key == 'email' && !coauthor[key].match(emailRegex)) {
                return 6;
            }
        }
    }
    return 0;
}

export const DepositInfoInput = (props) => {
    const {
        goBack = () => {

        },
        type,
        subtype
    } = props;
    
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser)
    const error = useSelector(state => state.files.error)
    const loading = useSelector(state => state.files.loading)
    const errorRef = useRef(error);

    const [data, setData] = useState({
        type,
        subtype,
        author: currentUser.userType == 2 ? undefined : currentUser,
        perioddepon: 5,
        certType: false,
        thirdPersonInfo: false,
        creator: currentUser
    });
    const [editAuthor, setEditAuthor] = useState(false);
    const [editOwner, setEditOwner] = useState(false);
    const [editCoauthor, setEditCoauthor] = useState(undefined);

    const onAuthorChange = (authorData) => {
        setData({
            ...data,
            author: authorData
        })
    }

    const onCoauthorChange = (coauthorData, index) => {
        let newCoauthors = data?.coauthors;
        if (newCoauthors == undefined || newCoauthors.length == 0) {
            newCoauthors = [coauthorData];
        } else {
            newCoauthors[index] = coauthorData;
        }
        setData({
            ...data,
            coauthors: newCoauthors
        })
    }

    const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
        <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
    ));

    const handleDeposit = async () => {
        const invalidData = checkInfo(data);
        if (invalidData > 0) {
            toast.error(infoError(invalidData));
            return;
        }
        const formData = new FormData();
        formData.append('files', data.file);
        formData.append('files', data.dogpuboffer);
        formData.append('body', JSON.stringify(data));

        await dispatch(filesActions.depositFile(formData));
        if (errorRef.current) {
            if (errorRef?.current?.includes('file is already used')) {
                toast.error('Этот файл уже был задепонирован');
            } else {
                toast.error('Ошибка');
            }
            return;
        }
        localStorage.removeItem('depositData');
        window.location.href = 'https://депонирую.рф/файлы';
    }

    useEffect(() => {
        errorRef.current = error;
    }, [error])

    useEffect(() => {
        let depositData = localStorage.getItem('depositData');
        if (depositData) {
            depositData = JSON.parse(depositData);
            setData(depositData);
        }
        if (depositData == undefined || depositData.rightHolder == undefined) {
            DepositAPI.getTsuInfo().then(res => {
                setData({
                    ...data,
                    rightHolder: res[0]
                })
            })
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('depositData', JSON.stringify(data));
    }, [data])

    return (
        <Wrapper>
            <BackButton onClick={goBack}>
                <ArrowLeft src={arrow_back} />

                <BackButtonText>
                    Назад
                </BackButtonText>
            </BackButton>

            <BottomPlaceholder>
                <InfoPlaceholder onSubmit={async (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    handleDeposit();
                }}>
                    <CFormLabelCustom>Название РИД</CFormLabelCustom>
                    <CFormInputCustom
                        required={true}
                        placeholder='Название РИД'
                        value={data.title}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                title: evt.target.value
                            })
                        }}
                    />

                    <CFormLabelCustom>Выбранный тип РИД</CFormLabelCustom>
                    <CFormInputCustom placeholder='Выбранный тип РИД' disabled value={type} />
                    
                    <CFormLabelCustom>Краткий реферат</CFormLabelCustom>
                    <CFormTextareaCustom
                        required={true}
                        placeholder='Краткий реферат'
                        value={data.desc}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                desc: evt.target.value
                            })
                        }}
                    />

                    <PersonalInfo>
                        <PersonalInfoHeader>
                            {data?.rightHolder?.authorIsOwner == true ? 'Автор является правообладателем' : 'Информация о правообладателе'}
                            <CTooltip content="Редактировать">
                                <EditIcon src={edit} onClick={() => setEditOwner(true)} />
                            </CTooltip>
                        </PersonalInfoHeader>
                        {data?.rightHolder?.authorIsOwner == true ? null :
                            <RightHolderInfo data={{
                                    ...data.rightHolder,
                                }}
                            />
                        }
                    </PersonalInfo>

                    <PersonalInfo>
                        <PersonalInfoHeader>
                            Информация об авторе
                            <CTooltip content="Редактировать">
                                <EditIcon src={edit} onClick={() => setEditAuthor(true)} />
                            </CTooltip>
                        </PersonalInfoHeader>

                        {data.author == undefined ? null :
                            <AuthorInfo data={data.author} />
                        }
                    </PersonalInfo>

                    <CoauthorsPlaceholder>
                        <CoauthorsHeader>
                            Информация об авторах
                            <CTooltip content="Добавить автора">
                                <AddCoauthorIcon
                                    src={add_coauthor}
                                    onClick={() => {
                                        setData({
                                            ...data,
                                            coauthors: data.coauthors == undefined ? [{}] : data.coauthors.concat({})
                                        });
                                    }}
                                />
                            </CTooltip>
                        </CoauthorsHeader>

                        <Coauthors>
                            {data.coauthors == undefined || data.coauthors.length == 0 ? null :
                                <>
                                    {data.coauthors.map((coauthor, key) => {
                                            return (
                                                <PersonalInfo key={`coauthor_info_${key}`}>
                                                    <CoauthorInfoHeader>
                                                        <span>
                                                            {`Информация об авторе ${key + 2}`}
                                                            <CTooltip content="Редактировать">
                                                                <EditIcon
                                                                    src={edit}
                                                                    onClick={() => {
                                                                        setEditCoauthor({
                                                                            ...data.coauthors[key],
                                                                            index: key
                                                                        });
                                                                    }}
                                                                />
                                                            </CTooltip>
                                                        </span>
                                                        <CTooltip content="Удалить">
                                                            <EditIcon
                                                                src={delete_icon}
                                                                onClick={() => {
                                                                    let newCoauthors = data.coauthors;
                                                                    newCoauthors = newCoauthors.slice(0, key).concat(newCoauthors.slice(key + 1))
                                                                    setData({
                                                                        ...data,
                                                                        coauthors: newCoauthors
                                                                    })
                                                                }}
                                                            />
                                                        </CTooltip>
                                                    </CoauthorInfoHeader>
                                                    <CoauthorsInfo data={coauthor} />
                                                </PersonalInfo>
                                            );
                                        })
                                    }  
                                </>
                            }
                        </Coauthors>
                    </CoauthorsPlaceholder>

                    <CFormLabelCustom>Дата опубликования РИД</CFormLabelCustom>
                    <CFormInputWithMask
                        mask={Date}
                        lazy={false}
                        defaultValue={data?.datepubl}
                        disabled={data?.statuspubl}
                        onComplete={(value, mask) => [
                            setData({
                                ...data,
                                datepubl: value
                            })
                        ]}

                    />
                    <CFormCheckCustom
                        id='was_published'
                        label='РИД не был опубликован'
                        checked={data?.statuspubl}
                        onChange={(evt) => {
                            let current = evt.target.checked
                            if (current) {
                                setData({
                                    ...data,
                                    datepubl: '',
                                    statuspubl: current
                                });
                            } else {
                                setData({
                                    ...data,
                                    statuspubl: current
                                });
                            }
                        }}
                    />

                    <div>
                        <CFormLabelCustom style={{fontSize: '16px'}}>Основания возникновения права на регистрируемый объект авторского права</CFormLabelCustom>
                        <CFormCheckCustom
                            id='base_to_right_1'
                            type='radio'
                            checked={data.baseToRight == 'Заявитель является работодателем автора'}
                            label={'Заявитель является работодателем автора'}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    baseToRight: 'Заявитель является работодателем автора'
                                });
                            }}
                        />
                        <CFormCheckCustom
                            id='base_to_right_2'
                            type='radio'
                            checked={data.baseToRight == 'Передача прав автором или его правопреемником заявителю в рамках договора авторского заказа'}
                            label={'Передача прав автором или его правопреемником заявителю в рамках договора авторского заказа'}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    baseToRight: 'Передача прав автором или его правопреемником заявителю в рамках договора авторского заказа'
                                });
                            }}
                        />
                        <CFormCheckCustom
                            id='base_to_right_3'
                            type='radio'
                            checked={data.baseToRight == 'Передача прав работодателем заявителю'}
                            label={'Передача прав работодателем заявителю'}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    baseToRight: 'Передача прав работодателем заявителю'
                                });
                            }}
                        />
                        <CFormCheckCustom
                            id='base_to_right_4'
                            type='radio'
                            checked={data.baseToRight == 'Заявитель является автором'}
                            label={'Заявитель является автором'}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    baseToRight: 'Заявитель является автором'
                                });
                            }}
                            />
                    </div>

                    <CFormLabelCustom>Загрузить файл</CFormLabelCustom>
                    <CFormInputCustom
                        required={true}
                        id='upload_file'
                        type='file'
                        // accept=''
                        onChange={(evt) => {
                            setData({
                                ...data,
                                file: evt.target.files[0]
                            })
                        }}
                    />
                    
                    <CFormCheckCustom
                        id='no_third_person_info'
                        type='radio'
                        checked={data.thirdPersonInfo == false}
                        label={'Не содержит персональные данные'}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                thirdPersonInfo: false
                            });
                        }}
                    />
                    <CFormCheckCustom
                        id='third_person_info'
                        type='radio'
                        checked={data.thirdPersonInfo == true}
                        label={'Содержит персональные данные третьих лиц'}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                thirdPersonInfo: true
                            });
                        }}
                    />
                    {/* <CFormCheckCustom
                        id='third_person_info'
                        label={'В документе имеются данные третьих лиц'}
                        checked={data.thirdPersonInfo}
                        onChange={(evt) => {
                            let current = evt.target.checked
                            setData({
                                ...data,
                                thirdPersonInfo: current
                            });
                        }}
                    /> */}

                    <CFormLabelCustom>Срок депонирования</CFormLabelCustom>
                    <CFormSelectCustom
                        disabled={true}
                        value={data?.perioddepon}
                        options={[
                            // {label: '1 год', value: 1},
                            // {label: '2 года', value: 2},
                            // {label: '3 года', value: 3},
                            // {label: '4 года', value: 4},
                            {label: '5 лет', value: 5},
                        ]}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                perioddepon: evt.target.value
                            })
                        }}
                    />

                    <CFormLabelCustom>Загрузить скан подписанного автором договора публичной оферты</CFormLabelCustom>
                    <CFormInputCustom
                        required={true}
                        id='dogpuboffer'
                        type='file'
                        //accept=''
                        onChange={(evt) => { 
                            setData({
                                ...data,
                                dogpuboffer: evt.target.files[0]
                            })
                        }}
                    />
                    
                    <CFormLabelCustom>Тип сертификата</CFormLabelCustom>
                    <CFormSelectCustom
                        value={data?.certType}
                        options={[
                            {label: 'Цифровой', value: false},
                            {label: 'Печатный', value: true},
                        ]}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                certType: evt.target.value
                            })
                        }}
                    />

                    <CFormCheckCustom
                        id='publish'
                        label='Опубликовать в Реестре РИД НИ ТГУ'
                        checked={data.publish}
                        onChange={(evt) => {
                            let current = evt.target.checked;
                            setData({
                                ...data,
                                publish: current
                            })
                        }}
                    />

                    <BlueButton type='submit'>
                        {loading ? <CSpinner size='sm' color='light'/> : 'Сохранить'}
                    </BlueButton>
                </InfoPlaceholder>

            </BottomPlaceholder>

            {editAuthor == false ? null :
                <AuthorInfoModal
                    info={data.author}
                    currentUser={currentUser}
                    visible={true}
                    onClose={() => setEditAuthor(false)}
                    onSave={(d) => {
                        setEditAuthor(false);
                        onAuthorChange(d);
                    }}
                />
            }

            {editOwner == false ? null :
                <RightHolderInfoModal
                    info={data.rightHolder}
                    visible={true}
                    onClose={() => setEditOwner(false)}
                    onSave={(d) => {
                        setData({
                            ...data,
                            rightHolder: d
                        })
                        setEditOwner(false);
                    }}
                />
            }

            {editCoauthor == undefined ? null :
                <CoauthorInfoModal
                    info={editCoauthor}
                    visible={true}
                    onClose={() => setEditCoauthor(undefined)}
                    onChange={(data) => {
                        onCoauthorChange(data, editCoauthor.index);
                    }}
                    onSave={(data) => {
                        // onCoauthorChange(data, editCoauthor.index);
                        setEditCoauthor(undefined);
                    }}
                />
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
`;

const BottomPlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`;

const BackButton = styled.button`
    max-height: 54px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid var(--primary);
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    margin-right: 10px;

    &:hover {
        opacity: 0.8;
    }
`;

const ArrowLeft = styled.img`
    width: 16px;
    height: 12px;
    user-select: none;
`;

const BackButtonText = styled.span`
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 768px) {
        display: none;
    }
`;

const InfoPlaceholder = styled.form`
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 768px) {
        max-width: 400px;
    }
`;

const CFormInputCustom = styled(CFormInput)`
    width: 100%;

    &:focus, &:active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`; 

const CFormLabelCustom = styled(CFormLabel)`
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -15px;
`;

const CFormTextareaCustom = styled(CFormTextarea)`
    &:focus, &:active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    :focus, :active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`;

const CFormSelectCustom = styled(CFormSelect)`
    &:focus, &:active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`;

const PersonalInfo = styled.div`
    max-width: calc((100% - 20px) / 2);
    width: 100%;
    height: fit-content;
    padding: 15px 20px;
    border-radius: 10px;
    border: 1px solid var(--border-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    @media (max-width: 768px) {
        max-width: 400px;
    }
`;

const PersonalInfoHeader = styled.div`
    font-size: 16px;
    font-weight: 600;
`;

const CoauthorInfoHeader = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const EditIcon = styled.img`
    width: 24px;
    height: 24px;
    user-select: none;
    cursor: pointer;
    margin-left: 10px;
`;

const PersonalInfoValue = styled.div`
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 5px;
`;

const PersonalInfoText = styled.p`
    font-weight: 500;
`;

const CoauthorsPlaceholder = styled.div`
    width: 100%;
`;

const Coauthors = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
`;

const CoauthorsHeader = styled.span`
    font-size: 16px;
    font-weight: 600;
`;

const AddCoauthorIcon = styled.img`
    margin-left: 15px;
    width: 30px;
    height: 30px;
    user-select: none;
    cursor: pointer;
`;

const BlueButton = styled.button`
    margin-top: 50px;
    width: fit-content;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;