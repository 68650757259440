import React from 'react';
import styled from 'styled-components';

export const BlueBorderButton = (props) => {
    return (
        <Button>{props.children}</Button>
    );
};

const Button = styled.button`
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: 1px solid var(--primary);
    background-color: white;
    border-radius: 5px;
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
    
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 400px) {
        font-size: 16px;
        padding: 10px 25px;
    }
`;