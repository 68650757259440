import React from 'react'
import styled from 'styled-components';

export const AuthorInfo = (props) => {
    const {
        data,
    } = props

    return (
        <Wrapper>
            <Line>
                <Title>ФИО:</Title>
                <Value>{data?.lastName} {data?.firstName} {data?.secondName}</Value>
            </Line>
            <Line>
                <Title>Дата рождения:</Title>
                <Value>{data?.dateBorn}</Value>
            </Line>
            <Line>
                <Title>Гражданство:</Title>
                <Value>{data?.citizenship}</Value>
            </Line>
            <Line>
                <Title>Место жительства:</Title>
                <Value>{data?.address}</Value>
            </Line>
            <Line>
                <Title>ИНН:</Title>
                <Value>{data?.inn}</Value>
            </Line>
            <Line>
                <Title>email:</Title>
                <Value>{data?.email}</Value>
            </Line>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;

const Line = styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
`;

const Title = styled.span`
    font-weight: 500;
`;

const Value = styled.span`
    text-align: right;
`;
