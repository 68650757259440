import axios from "axios";

export const CommonHelper = {
    linkTo(patentUrl, inNewTab = false) {
        let url = window.location.origin + patentUrl;
        if (inNewTab == false) {
            window.location.href = url;
        } else {
            let win = window.open(url, '_blank');
            win.focus();
        }
    },

    checkEmail(email) {
        if (email == undefined || email.trim() == '') {
            return false;
        }

        const found = email.match(/.+@.+\..+/g);
        return found != undefined;
    },

    checkName(name) {
        if (name == undefined || name.trim() == '') {
            return false;
        }

        name = name.trim();
        name = name.toLowerCase();
        return name.length == name.replace(/[^а-я]/g, '').length
    },

    capitalizeFirstLetter(word) {
        if (word == undefined || word.trim() == '') {
            return '';
        }

        return word.charAt(0).toUpperCase() + word.slice(1)
    },

    checkPassword(password) {
        if (password == undefined || password.length < 6) {
            return false;
        }

        if (password.includes(' ')) {
            return false;
        }

        let specialSymbol = /[\(\.\,\:\;\?\!\*\+\%\-\<\>\@\[\]\/_\{\}\$\#\)]/g;
        if (password.match(specialSymbol) == null) {
            return false;
        }

        let regex = /[^A-Za-z0-9\(\.,:;\?!\*\+%-<>@\[\]{}\/_{}$#\)]+/g;
        if (password.match(regex) != null) {
            return false;
        }

        return true;
    },

    correctDate(date) {
        if (date == undefined || date.trim() == '') {
            return '';
        }
        let newDate = date.split('T')[0];
        newDate = newDate.split('-');

        return newDate[2] + '.' + newDate[1] + '.' + newDate[0]; 
    },

    dateAndTime(dateWithTimezone) {
        if (dateWithTimezone == undefined || dateWithTimezone.trim() == '') {
            return '';
        }
        dateWithTimezone = new Date(dateWithTimezone).toLocaleString();
        let newDate = dateWithTimezone.split(',')[0];
        
        let time = (dateWithTimezone.split(',')[1]).slice(1, 6);

        return time + ' ' + newDate;
    },

    async getIp() {
        try {
            return (await axios.get('https://api.ipify.org?format=json')).data?.ip;
        } catch (error) {
            console.log('error: ', error);
        }
    },
}