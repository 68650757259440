import { API_ENDPOINT } from "../config";
import axios from "axios";
import $api from './axiosSettings';

axios.defaults.withCredentials = true;

const UsersAPI = {

    async addUser(data) {
        try {
            const pld = (await axios.post(`${API_ENDPOINT}/api/user/register`, {
                    ...data
            })).data;

            return pld;
        } catch (error) {
            console.log("error: ", error);
        }
    },

    async isEmailAlreadyUsed(email) {
        try {
            const pld = (await axios.get(`${API_ENDPOINT}/api/user/checkEmail`, {
                params: {
                    email
                }
            })).data;

            return pld.isUsed;
        } catch (error) {
            console.log("isEmailAlreadyUsed: ", error);
        }
    },

    async isPhoneAlreadyUsed(phone) {
        try {
            const pld = (await axios.get(`${API_ENDPOINT}/api/user/checkPhone`, {
                params: {
                    phone
                }
            })).data;

            return pld.isUsed;
        } catch (error) {
            console.log('isPhoneAlreadyUsed: ', error);
        }
    },

    async loginUser(data) {
        try {
            const pld = (await axios.post(`${API_ENDPOINT}/api/user/login`, {
                    ...data
            })).data;

            if (pld?.isLogin == false) {
                return undefined;
            }

            localStorage.setItem('accessToken', pld.accessToken);
            return pld; // userInfo + accessToken
        } catch (error) {
            console.log("loginUser: ", error);
        }
    },

    async getAccessToken(token) {
        try {
            const pld = (await axios.get(`${API_ENDPOINT}/api/user/getAccessToken`, {
                params: {
                    token
                }
            })).data;

            console.log(pld);
            // TODO return
        } catch (error) {
            
        }
    },

    async getUser(email) {
        try {
            const pld = (await $api.get(`${API_ENDPOINT}/api/user/getUser`, {
                params: {
                    email
                }
            })).data;

            return pld;
        } catch (error) {
            console.log(error);
        }
    },

    async auth() {
        try {
            //for testing
            // return {
            //     firstName: 'test',
            //     lastName: 'test',
            // }

            let pld = (await $api.get(`${API_ENDPOINT}/api/user/authenticate`));

            if (pld?.data?.isValid == false) {
                this.logout();
                return undefined;
            }

            localStorage.setItem('accessToken', pld.accessToken);
            return pld.data;
        } catch (error) {
            console.log(error);
        }
    },

    async logout() {
        try {
            let pld = await axios.get(`${API_ENDPOINT}/api/user/logout`);
            if (pld.status == 200) {
                localStorage.removeItem('accessToken');
                return undefined;
            }
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async updateUser(data) {
        try {
            let pld = await $api.post(`${API_ENDPOINT}/api/user/updateUser`, data);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async tsuAuth(token) {
        try {
            let pld = (await axios.get(`${API_ENDPOINT}/api/user/tsuAuth`, {
                params: {
                    token
                }
            })).data;
            localStorage.setItem('accessToken', pld.accessToken);

            return pld;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async uploadAvatar(data) {
        try {
            const pld = await $api.post(`${API_ENDPOINT}/api/upload/avatar`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return pld?.data?.imageName;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async confirmEmail() {
        try {
            const pld = await $api.get(`${API_ENDPOINT}/api/user/activate`);
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async forgotPassword(email) {
        try {
            const pld = await $api.get(`${API_ENDPOINT}/api/user/resetPassword?email=${email}`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async updateForgotPassword(email, password) {
        try {
            const pld = await $api.post(`${API_ENDPOINT}/api/user/setNewPassword`, { email, password });
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getNotifications() {
        try {
            const pld = await $api.get(`${API_ENDPOINT}/api/user/notification`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async readNotification(id) {
        try {
            const pld = await $api.get(`${API_ENDPOINT}/api/user/notification/read/${id}`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    }

}

export default UsersAPI;