import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { reducer } from './redux/reducers';
import { configureStore } from '@reduxjs/toolkit';
import * as usersActions from './redux/actions/UsersActions';

const store = configureStore({ reducer });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
//   </React.StrictMode>
);

let init = () => {
    return (dispatch, getState) => {
        return dispatch(usersActions.initAuth())
    }
};

store.dispatch(init());