import React from 'react'
import styled from 'styled-components'
import arrowLeft from '../assets/img/menu_arrow_left.svg'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as userActions from '../redux/actions/UsersActions';

export const SidebarMenu = (props) => {
    const {
        currentUser,
        toggleMenu = () => {

        },
    } = props;

    const dispatch = useDispatch();

    return (
        <Wrapper>
            <Sidebar>
                <CloseButton onClick={() => toggleMenu()}>
                    <Arrow src={arrowLeft} />
                </CloseButton>

                <NavBar>
                    <Header>Меню</Header>
                    <NavItem>О нас</NavItem>
                    <Link to='/депонирование'
                        style={{ textDecoration: 'none' }}
                        onClick={() => toggleMenu()}
                    >
                        <NavItem>Депонирование</NavItem>
                    </Link>

                    <Link to='/консультации'
                        style={{ textDecoration: 'none' }}
                        onClick={() => toggleMenu()}
                    >
                        <NavItem>Консультации</NavItem>
                    </Link>

                    <Link to='/проверка'
                        style={{ textDecoration: 'none' }}
                        onClick={() => toggleMenu()}
                    >
                        <NavItem>Проверка свидетельства</NavItem>
                    </Link>

                    <Link to='/реестр'
                        style={{ textDecoration: 'none' }}
                        onClick={() => toggleMenu()}
                    >
                        <NavItem>Реестр РИД</NavItem>
                    </Link>

                    {/* <Link to='/проверка'
                        style={{ textDecoration: 'none' }}
                        onClick={() => toggleMenu()}
                    > */}
                        <NavItem>Правовая информация</NavItem>
                    {/* </Link> */}

                    <Link to='/проверка' style={{ textDecoration: 'none' }}>
                        <NavItem>Контакты</NavItem>
                    </Link>

                    {currentUser == undefined ?
                        <LoginPlaceholder>
                            <Link to='/вход'
                                style={{ textDecoration: 'none' }}
                                onClick={() => toggleMenu()}
                            >
                                <NavItem style={{ fontWeight: '600' }}>Войти</NavItem>
                            </Link>
                            <Link to='/регистрация'
                                style={{ textDecoration: 'none' }}
                                onClick={() => toggleMenu()}
                            >
                                <NavItem style={{ fontWeight: '600' }}>Регистрация</NavItem>
                            </Link>
                        </LoginPlaceholder>
                        :
                        <UserPlaceholder>
                            <Link to='/аккаунт'
                                style={{ textDecoration: 'none' }}
                                onClick={() => toggleMenu()}
                            >
                                <NavItem style={{ fontWeight: '600' }}>Профиль</NavItem>
                            </Link>
                            <NavItem
                                style={{ fontWeight: '600' }}
                                onClick={async () => {
                                    await dispatch(userActions.logout());
                                }}
                            >
                                Выйти
                            </NavItem>
                        </UserPlaceholder>
                    }
                </NavBar>
            </Sidebar>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    @media (max-width: 400px) {
        max-width: 250px;
    }
`;

const Sidebar = styled.div`
    width: 400px;
    height: 100%;
    background-color: white;
    padding-left: 10px;

    @media (max-width: 400px) {
        max-width: 250px;
        margin: 0;
    }
`;

const CloseButton = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--border-light);

    &:hover {
        background-color: whitesmoke;
    }
`;

const Arrow = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 10px;
`;

const NavBar = styled.div`

`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    border-bottom: 1px solid var(--border-light);
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
`;

const NavItem = styled.div`
    height: 60px;
    width: 100%;
    color: var(--primary);
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-light);
    cursor: pointer;

    &:hover {
        background-color: whitesmoke;
    }
`;

const LoginPlaceholder = styled.div`
    @media (min-width: 640px) {
        display: none;
    }
`;

const UserPlaceholder = styled.div`
    @media (min-width: 700px) {
        display: none;
    }
`;