import React from 'react'
import styled from 'styled-components'
import { CommonHelper } from '../helpers/CommonHelper';
import { CTable, CTableHead, CTableHeaderCell, CTableRow, CTableBody, CTableDataCell } from '@coreui/react'

const getName = (obj) => {
    if (obj == undefined) {
        return '';
    }
    if (obj.lastName === undefined || obj.firstName === undefined) {
        return '';
    }
    if (obj.secondName === undefined || obj.secondName === '') {
        return obj.lastName + ' ' + obj.firstName;
    }
    return obj.lastName + ' ' + obj.firstName + ' ' + obj.secondName;
}

const getRightholder = (rightholder) => {
    if (rightholder == undefined) {
        return ``;
    }
    if (rightholder?.firstName == undefined) {
        return rightholder.short_name;
    } else {
        return `${rightholder.lastName} ${rightholder.firstName} ${rightholder.secondName}`
    }
}

export const RegistryTable = (props) => {
    const {
        currentPage = 1,
        data,
    } = props;

    return (
        <Wrapper>
            <CTableCustom hover>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell scope="col">#</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Тип РИД</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Название РИД</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Реферат</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Правообладатель</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Автор(ы)</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Дата депонирования</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Номер свидетельства</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {data?.map((item, index) => {
                        let authors = getName(item.authorId);
                        if (item.idsCoauthor.length > 0) {
                            for (let coauthor of item.idsCoauthor) {
                                authors = authors.concat(', ' + getName(coauthor));
                            }
                        }

                        return (
                            <CTableRow key={`registry_table_${index}`}>
                                <CTableDataCell scope="row">{index + 1 + 10 * (currentPage - 1)}</CTableDataCell>
                                <CTableDataCell>{item.typeName}</CTableDataCell>
                                <CTableDataCell>{item.ridTitle}</CTableDataCell>
                                <CTableDataCell><Text>{item.ridDescription}</Text></CTableDataCell>
                                <CTableDataCell>{getRightholder(item.rightholder)}</CTableDataCell>
                                <CTableDataCell><Text>{authors}</Text></CTableDataCell>
                                <CTableDataCell>{CommonHelper.correctDate(item.creationDate)}</CTableDataCell>
                                <CTableDataCell>{item.certificateNumber}</CTableDataCell>
                            </CTableRow>
                        )
                    })}
                </CTableBody>
            </CTableCustom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: fit-content;
    border: 1px solid var(--border-light);
    border-radius: 10px;
`;

const CTableCustom = styled(CTable)`
    margin: 0;

    tbody tr:last-child {
        border-color: transparent;
        border-radius: 10px;
    }
`;

const Text = styled.span`
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 5;
    line-clamp: 5;
   -webkit-box-orient: vertical;
`;