import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminAPI from '../helpers/api/AdminAPI';

export const AdminRidApprove = () => {

    const ridId = decodeURIComponent(window.location.pathname).split('/').at(-1);
    const [data, setData] = useState(undefined);
    //get RID info

    useEffect(() => {
        AdminAPI.getRidInfo(Number(ridId) - 100).then(x => {
            setData(x);
        })
    }, [])

    return (
        <Wrapper>
            <RidInfo>
                <Name>{data?.title}</Name>
                <Description>
                    Реферат: <br />
                    {data?.rid_desc}
                </Description>
            </RidInfo>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1200px;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
`;

const RidInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Name = styled.span`
    font-size: 24px;
    font-weight: 600;
`;

const Description = styled.p`
    font-size: 20px;
`;

const AuthorInfo = styled.div`
    
`;

const RightHolderInfo = styled.div`

`;