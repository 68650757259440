import React, { useCallback } from 'react';
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone';

export const Dropzone = (props) => {
    const {
        accept,
        multipleFiles = false,
        onFileChange = (file) => {

        }
    } = props;

    const onDrop = useCallback(acceptedFiles => { 
        onFileChange(acceptedFiles[0]);
    }, [])

    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop, accept, multiple: multipleFiles });

    const acceptedTypes = () => {
        let types = [];
        for (let key in accept) {
            types.push(accept[key].join(', '));
        }
        return types;
    }

    return (
        <Wrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {acceptedFiles == undefined || acceptedFiles.length == 0 ? null :
                <FileName>{acceptedFiles[0].name}</FileName>
            }
            {isDragActive ?
                isDragAccept ? <p>Перетащите файл сюда</p> : <RejectText>Файл данного типа не разрешен</RejectText>
                :
                <p>
                    Перетащите файл сюда или нажмите, чтобы выбрать файл<br />
                    Разрешенные файлы: {acceptedTypes()}
                </p>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    margin: 20px 0;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
`;

const FileName = styled.p`
    font-size: 20px;
    word-break: break-all;
`;

const RejectText = styled.p`
    color: var(--error);
`;