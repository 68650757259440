import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal';
import { CForm, CFormCheck, CFormInput, CFormLabel } from '@coreui/react';
import { IMaskMixin } from 'react-imask';
import { CommonHelper } from '../../helpers/CommonHelper';
import Select from 'react-select';
import countries from '../../countriesList';

const getCurrentCitizenship = (citizenship) => {
    if (citizenship) {
        return countries.find(c => c.label == citizenship)
    }
    return undefined;
}

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
));

export const AuthorInfoModal = (props) => {
    const {
        currentUser = {},
        info = undefined,
        visible = false,
        onClose = () => {

        },
        onSave = (data) => {

        },
    } = props

    const [data, setData] = useState(info);

    return (
        <Wrapper>
            <Modal visible={visible} onClose={onClose} header='Информация об авторе'>
                <CFormCustom>
                    <Input>
                        <CFormLabelCustom>Фамилия</CFormLabelCustom>
                        <CFormInputCustom
                            required
                            id='author_last_name'
                            placeholder='Фамилия'
                            value={data?.lastName || ''}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return;
                                }
                                setData({
                                    ...data,
                                    lastName: s
                                })
                            }}
                        />
                    </Input>
                    
                    <Input>
                        <CFormLabelCustom>Имя</CFormLabelCustom>
                        <CFormInputCustom
                            required
                            id='author_first_name'
                            placeholder='Имя'
                            value={data?.firstName || ''}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return
                                }
                                setData({
                                    ...data,
                                    firstName: s
                                })
                            }}
                        />
                    </Input>

                    <Input>
                        <CFormLabelCustom>Отчество</CFormLabelCustom>
                        <CFormInputCustom
                            id='author_second_name'
                            placeholder='Отчество'
                            value={data?.secondName || ''}
                            disabled={data?.hasSecondName}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return
                                }
                                setData({
                                    ...data,
                                    secondName: s
                                })
                            }}
                        />
                    
                        <CFormCheckCustom
                            id='author_info_checkbox'
                            label='Отчество отсутствует'
                            checked={data?.hasSecondName}
                            onChange={(evt) => {
                                let current = evt.target.checked
                                if (current) {
                                    setData({
                                        ...data,
                                        hasSecondName: current,
                                        secondName: ''
                                    })
                                } else {
                                    setData({
                                        ...data,
                                        hasSecondName: current
                                    })
                                }
                            }}
                        />
                    </Input>

                    <Input>
                        <CFormLabelCustom>Электронная почта</CFormLabelCustom>
                        <CFormInputCustom
                            required
                            id='author_email'
                            type='email'
                            placeholder='email@example.com'
                            value={data?.email}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    email: evt.target.value
                                })
                            }}
                        />
                    </Input>

                    <Input>
                        <CFormLabelCustom>Дата рождения</CFormLabelCustom>
                        <CFormInputWithMask
                            required
                            id='author_birth_date'
                            placeholder='Дата рождения'
                            mask={Date}
                            max={new Date()}
                            lazy={false}
                            value={data?.dateBorn}
                            onComplete={(value, mask) => {
                                setData({
                                    ...data,
                                    dateBorn: value
                                })
                            }}
                        />
                    </Input>

                    <Input>
                        <CFormLabelCustom>Гражданство</CFormLabelCustom>
                        <SelectPlaceholder>
                            <Select
                                value={getCurrentCitizenship(data?.citizenship) || ''}
                                isClearable={true}
                                options={countries}
                                placeholder={'Гражданство'}
                                maxMenuHeight={120}
                                noOptionsMessage={() => 'Нет результатов'}
                                onChange={(option, type) => {
                                    if (type.action == 'clear') {
                                        setData({
                                            ...data,
                                            citizenship: ''
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            citizenship: option.label
                                        })
                                    }
                                }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        borderColor: 'var(--border-light)',
                                        cursor: 'pointer',
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        cursor: 'pointer',
                                    })
                                }}
                                on
                            />
                        </SelectPlaceholder>
                    </Input>

                    <Input>
                        <CFormLabelCustom>ИНН</CFormLabelCustom>
                        <CFormInputWithMask
                            required
                            id='author_inn'
                            placeholder='ИНН'
                            mask='0000000000[00]'
                            value={data?.inn}
                            onComplete={(value, mask) => {
                                setData({
                                    ...data,
                                    inn: value
                                })
                            }}
                        />
                    </Input>

                    <Input>
                        <CFormLabelCustom>Место жительства</CFormLabelCustom>
                        <CFormInputCustom
                            id='author_living_place'
                            placeholder='Место жительства'
                            value={data?.address}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    address: evt.target.value
                                })
                            }}
                        />
                    </Input>
                </CFormCustom>

                <SaveButtonPlaceholder>
                    <BlueButton onClick={() => {
                        let lastName = CommonHelper.capitalizeFirstLetter(data.lastName);
                        let firstName = CommonHelper.capitalizeFirstLetter(data.firstName);
                        let secondName = '';
                        if (data?.secondName?.length > 0) {
                            secondName = CommonHelper.capitalizeFirstLetter(data.secondName);
                        }
                        onSave({
                            ...data,
                            lastName,
                            firstName,
                            secondName
                        });
                    }}>
                        Сохранить
                    </BlueButton>
                </SaveButtonPlaceholder>    
            </Modal>
        </Wrapper>
    )
}

const Wrapper = styled.div`

`;

const CFormCustom = styled(CForm)`
    margin: 20px 30px;
    margin-bottom: 0;
    max-width: 620px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    @media (max-width: 400px) {
        max-width: 300px;
        margin: 0;
    }
`;

const Input = styled.div`
    max-width: 300px;
    width: 100%;
    height: fit-content;
`;

const SelectPlaceholder = styled.div`
    width: 100%;
    /* max-width: 300px; */
`; 

const CFormInputCustom = styled(CFormInput)`
    margin-bottom: 10px;
    max-width: 300px;
    width: 100%;
    
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    margin-top: -5px;
    margin-bottom: 10px;

    :focus, :active {
        box-shadow: none;
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -1px;
`;

const SaveButtonPlaceholder = styled.div`
    width: 250px;
    margin: auto;
`;

const BlueButton = styled.button`
    width: 250px;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;
`;