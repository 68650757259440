import React, { useState } from 'react'
import styled from 'styled-components';
import { Modal } from '../Modal';
import { CForm, CFormCheck, CFormInput, CFormLabel, CSpinner, CFormSelect } from '@coreui/react';
import { IMaskMixin } from 'react-imask'
import UsersAPI from '../../helpers/api/UserAPI';
import { useSelector } from 'react-redux';
import countries from '../../countriesList';
import Select from 'react-select';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}}/>
))

const getCurrentCitizenship = (citizenship) => {
    if (citizenship) {
        return countries.find(c => c.label == citizenship)
    }
    return undefined;
}

export const EditProfileModal = (props) => {
    const {
        currentUser,
        onClose = () => {

        },
        onSave = (data) => {

        }
    } = props;
    
    const loading = useSelector((state) => state.users.loading);

    const [data, setData] = useState(currentUser ? { ...currentUser, hasSecondName: currentUser.secondName.length > 0 } : {});
    const [isEmailUsed, setIsEmailUsed] = useState(false);
    const [isPhoneUsed, setIsPhoneUsed] = useState(false);

    return (
        <Modal onClose={onClose} visible={true} header={'Редактирование профиля'}>
            <ModalInner>
                <CFormCustom>
                    <Line>
                        <CFormLabelCustom>Фамилия</CFormLabelCustom>
                        <CFormInputCustom
                            value={data.lastName}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return
                                }

                                setData({
                                    ...data,
                                    lastName: s
                                })
                            }}
                        />
                    </Line>
                    <Line>
                        <CFormLabelCustom>Имя</CFormLabelCustom>
                        <CFormInputCustom
                            value={data.firstName}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return
                                }

                                setData({
                                    ...data,
                                    firstName: s
                                })
                            }}
                        />
                    </Line>
                    <Line>
                        <CFormLabelCustom>Отчество</CFormLabelCustom>
                        <CFormInputCustom
                            value={data.secondName}
                            disabled={!data.hasSecondName}
                            onChange={(evt) => {
                                let s = evt.target.value;
                                if (s.match(/[^А-Яа-яёЁ]+/g)) {
                                    return
                                }

                                setData({
                                    ...data,
                                    secondName: s
                                })
                            }}
                        />

                        <CFormCheckCustom
                            id='edit_check_second_name'
                            checked={!data.hasSecondName}
                            label='Отчество отсутствует'
                            onChange={() => {
                                let value = !data.hasSecondName;
                                if (value == false) {
                                    setData({
                                        ...data,
                                        hasSecondName: value,
                                        secondName: ''
                                    })
                                } else {
                                    setData({
                                        ...data,
                                        hasSecondName: value
                                    })
                                }
                            }}
                        />
                    </Line>
                    <Line>
                        <CFormLabelCustom>Почта</CFormLabelCustom>
                        <CFormInputCustom
                            required
                            type='email'
                            value={data.email}
                            onChange={(evt) => {
                                if (setIsEmailUsed) {
                                    setIsEmailUsed(false);
                                }

                                setData({
                                    ...data,
                                    email: evt.target.value
                                })
                            }}
                        />
                        {isEmailUsed == false ? null : 
                            <FeedbackInvalid>Данная почта уже используется</FeedbackInvalid>
                        }
                    </Line>
                    <Line>
                        <CFormLabelCustom>Телефон</CFormLabelCustom>
                        <CFormInputWithMask
                            mask="+{7} (000) 000-00-00"
                            lazy={false}
                            unmask={true}
                            defaultValue={data?.phone}
                            onComplete={(value, mask) => {
                                if (isPhoneUsed) {
                                    setIsPhoneUsed(false);
                                }

                                setData({
                                    ...data,
                                    phone: value
                                });
                            }}
                        />
                        {isPhoneUsed == false ? null : 
                            <FeedbackInvalid>Данный номер телефона уже используется</FeedbackInvalid>
                        }
                    </Line>
                    <Line>
                        <CFormLabelCustom>Дата рождения</CFormLabelCustom>
                        <CFormInputWithMask
                            value={data.dateBorn}
                            mask={Date}
                            min={new Date(1930, 0, 1)}
                            max={new Date(2020, 0, 1)}
                            lazy={false}
                            unmask={false}
                            defaultValue={data?.dateBorn || ''}
                            onComplete={(value, mask) => {
                                setData({
                                    ...data,
                                    dateBorn: value
                                });
                            }}
                        />
                    </Line>
                    <Line>
                        <CFormLabelCustom>ИНН</CFormLabelCustom>
                        <CFormInputWithMask
                            mask='0000000000[00]'
                            defaultValue={data?.inn || ''}
                            onComplete={(value, mask) => {
                                setData({
                                    ...data,
                                    inn: value
                                })
                            }}
                        />
                    </Line>
                    <Line>
                        <CFormLabelCustom>Гражданство</CFormLabelCustom>
                        <SelectPlaceholder>
                            <Select
                                value={getCurrentCitizenship(data?.citizenship) || ''}
                                isClearable={true}
                                options={countries}
                                placeholder={'Гражданство'}
                                maxMenuHeight={120}
                                noOptionsMessage={() => 'Нет результатов'}
                                onChange={(option, type) => {
                                    if (type.action == 'clear') {
                                        setData({
                                            ...data,
                                            citizenship: ''
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            citizenship: option.label
                                        })
                                    }
                                }}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        width: '100%',
                                        borderColor: 'var(--border-light)',
                                        cursor: 'pointer',
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                        cursor: 'pointer',
                                    })
                                }}
                                on
                            />
                        </SelectPlaceholder>
                        
                    </Line>
                    <Line style={{alignSelf: 'flex-start'}}>
                        <CFormLabelCustom>Место проживания</CFormLabelCustom>
                        <CFormInputCustom
                            value={data?.address || ''}
                            onChange={(evt) => {
                                setData({
                                    ...data,
                                    address: evt.target.value
                                })
                            }}
                        />
                    </Line>
                </CFormCustom>
                
                <SaveButton onClick={async () => {
                    let emailIsUsed = false, phoneIsUsed = false;
                    
                    if (data.email != currentUser.email) {
                        emailIsUsed = await UsersAPI.isPhoneAlreadyUsed(data.email);
                        if (emailIsUsed) {
                            setIsEmailUsed(true);
                        }
                    }

                    if (data.phone != currentUser.phone) {
                        phoneIsUsed = await UsersAPI.isPhoneAlreadyUsed(data.phone);
                        if (phoneIsUsed) {
                            setIsPhoneUsed(true);
                        }
                    }

                    if (phoneIsUsed || emailIsUsed) {
                        return
                    }

                    onSave(data);
                }}>
                    {loading ? <CSpinner size='sm' color='light'/> : 'Сохранить'}
                </SaveButton>
            </ModalInner>
        </Modal>
    )
}

const ModalInner = styled.div`
    min-width: 300px;
    max-width: 620px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const CFormCustom = styled(CForm)`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;  
`;

const Line = styled.div`
    max-width: 300px;
    width: 100%;
`;

const CFormInputCustom = styled(CFormInput)`
    max-width: 300px;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    margin-top: 5px;

    :focus, :active {
        box-shadow: none;
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    font-size: 14px;
    margin: 0px;
`;

const SaveButton = styled.button`
    border: none;
    background-color: var(--primary);
    border-radius: 4px;
    color: white;
    padding: 10px 20px;
    width: fit-content;
`;

const FeedbackInvalid = styled.p`
    color: #e55353;
    font-size: 13px;
    margin: 0;
`;

const SelectPlaceholder = styled.div`
    max-width: 300px;
`;