import { API_ENDPOINT } from "../config";
import axios from "axios";
import $api from './axiosSettings';

const EmailAPI = {
    async sendConsultation(data) {
        try {
            let pld = await $api.post(`${API_ENDPOINT}/api/email/consultation`, data);
            return pld.data;
        } catch (error) {
            console.error('error: ', error);
        }
    },

    async sendContacts(data) {
        try {
            let pld = await $api.post(`${API_ENDPOINT}/api/email/contact`, data);
            return pld.data;
        } catch (error) {
            console.error('error: ', error);
        }
    },
};

export default EmailAPI;