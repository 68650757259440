import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CSpinner } from '@coreui/react'
import { useDispatch, useSelector } from'react-redux';
import * as userActions from '../redux/actions/UsersActions';
import { Navigate } from 'react-router-dom';

export const TsuAccountAuthPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const error = useSelector(state => state.users.error);
    
    useEffect(() => {
        if (token) {
            dispatch(userActions.tsuLogin(token));
        }
    }, [])

    if (currentUser && !error) {
        return <Navigate to="/аккаунт"/>
    }

    if (error) {
        return <Navigate to="/регистрация"/>
    }

    return (
        <Wrapper>
            <CSpinner />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;
