import React from 'react'
import styled from 'styled-components'

export const Pagination = (props) => {
    const {
        currentPage = 1,
        totalPages = 1,
        onPageChange = (pageNumber) => {

        },
    } = props;

    let arr = [];
    if (totalPages <= 5) {
        for (let i = 0; i < totalPages; i++) {
            arr.push(i);
        }
    }

    return (
        <Wrapper>
            <Arrow
                $disabled={currentPage == 1}
                $left={true}
                onClick={() => {
                    if (currentPage > 1) {
                        onPageChange(currentPage - 1);
                    }
                }}
            >
                {'<'}
            </Arrow>
            
            {totalPages <= 5 ?
                <Center>
                    {arr.map(x => {
                        return (
                            <Item
                                $current={currentPage === x + 1}
                                key={`pagination_${x}`}
                                onClick={() => {
                                    onPageChange(x + 1);
                                }}
                            >
                                {x + 1}
                            </Item>
                        )
                    })}
                </Center>    
                :
                <Center>
                    <Item
                        $current={currentPage == 1}
                        onClick={() => onPageChange(1)}
                    >1</Item>
                    
                    {currentPage < 3 ? null :
                        <Dots>...</Dots>
                    }
                    
                    {currentPage < 3 ? null :
                        <Item onClick={() => onPageChange(currentPage - 1)}>{currentPage - 1}</Item>
                    }
                    {currentPage == 1 || currentPage == totalPages ? null :
                        <Item $current={true}>{currentPage}</Item>
                    }
                    {currentPage >= totalPages - 1 ? null :
                        <Item onClick={() => onPageChange(currentPage + 1)}>{currentPage + 1}</Item>
                    }
                    
                    {currentPage >= totalPages - 2 ? null :
                        <Dots>...</Dots>
                    }
                    
                    <Item
                        $current={currentPage == totalPages}
                        onClick={() => onPageChange(totalPages)}
                    >
                        {totalPages}
                    </Item>
                </Center>
            }

            <Arrow
                $disabled={currentPage == totalPages}
                onClick={() => {
                    if (currentPage < totalPages) {
                        onPageChange(currentPage + 1);
                    }
                }}
            >
                {'>'}
            </Arrow>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 10px;
    display: flex;
`;

const Center = styled.div`
    display: flex;
`;

const Item = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid ${props => props.$current ? 'var(--primary)' : props.$disabled ? 'lightgray' : 'var(--border-light)'};  */
    border-left: none;
    color: ${props => props.$current ? 'white' : props.$disabled ? 'grey' : 'var(--primary)'};
    font-size: 18px;
    font-weight: 600;
    user-select: none;
    cursor: ${props => props.$current || props.$disabled ? '' : 'pointer'};
    background-color: ${props => props.$current ? 'var(--primary)' : ''};
    /* background-color: ${props => props.$disabled ? 'lightgray' : ''}; */
    border-radius: 5px;

    &:hover {
        background-color: ${props => props.$current || props.$disabled ? '' : 'whitesmoke'};
    }
`;

const Arrow = styled(Item)`
    /* border-top-right-radius: ${props => props.$left ? '0' : '5px'};
    border-bottom-right-radius: ${props => props.$left ? '0' : '5px'};
    border-top-left-radius: ${props => props.$left ? '5px' : '0'};
    border-bottom-left-radius: ${props => props.$left ? '5px' : '0'};
    border-left: ${props => props.$left && !props.$disabled ? '1px solid var(--border-light)' : props.$disabled ? 'lightgray' : 'none'}; */
`;

const Dots = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid var(--border-light);  */
    /* border-left: none; */
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
    user-select: none;
`;