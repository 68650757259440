import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import AdminAPI from '../helpers/api/AdminAPI';
import { User } from '../components/admin/User.js';
import { Pagination } from '../components/Pagination.js';
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import { RegisterModal } from '../components/modals/RegisterModal.js';
import { Modal } from '../components/Modal.js';
import UsersAPI from '../helpers/api/UserAPI.js';
import { toast } from 'react-hot-toast';

export const AdminUsersPage = () => {
    
    const currentUser = useSelector(state => state.users.currentUser);

    const [users, setUsers] = useState(undefined);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [createUserModal, setCreateUserModal] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);

    useEffect(() => {
        AdminAPI.getUsersCount().then(x => {
            setCount(x?.all?.count);
        })
    }, [])

    useEffect(() => {
        AdminAPI.getUsers(10, currentPage).then(x => {
            let data = x.sort((a, b) => a.ID - b.ID);
            setUsers(data);
        });
    }, [currentPage])

    if (currentUser?.userType != 4) {
        return <Navigate to='/'/>
    }

    return (
        <Wrapper>
            <TopPlaceholder>
                <CBreadcrumb>
                    <CBreadcrumbItemCustom>
                        <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                            Личный кабинет администратора
                        </Link>
                    </CBreadcrumbItemCustom>
                    <CBreadcrumbItemCustom>
                        <Link to='/админ' style={{textDecoration: 'none'}}>
                            Панель администратора
                        </Link>
                    </CBreadcrumbItemCustom>
                    <CBreadcrumbItemCustom active>Пользователи</CBreadcrumbItemCustom>
                </CBreadcrumb>

                <AddUserButton onClick={() => setCreateUserModal(true)}>Добавить пользователя</AddUserButton>
            </TopPlaceholder>

            <Table>
                <User
                    bold={true}
                    data={{
                        ID: 'ID',
                        lname: 'ФИО',
                        email: 'Почта',
                        phone: 'Телефон',
                        citizenship: 'Гражданство',
                        address: 'Адрес',
                        INN: 'ИНН',
                        type: 'Роль'
                    }} 
                />

                {users?.map((user, key) => {
                    return (
                        <User
                            key={`user_admin_panel_${key}`}
                            data={user}
                            onChange={() => {
                                AdminAPI.getUsers(10, currentPage).then(x => {
                                    let data = x.sort((a, b) => a.ID - b.ID);
                                    setUsers(data);
                                });
                            }}
                        />
                    )
                })}
            </Table>

            <PaginationPlaceholder>
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(count / 10)}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                    }}
                />
            </PaginationPlaceholder>

            {createUserModal === false ? null :
                <Modal
                    visible={createUserModal}
                    onClose={() => setCreateUserModal(false)}
                    header={'Создать пользователя'}
                >
                    <RegisterModal
                        loading={registerLoading}
                        onSubmit={async (data) => {
                            setRegisterLoading(true);
                            let emailUsed = await UsersAPI.isEmailAlreadyUsed(data?.email);
                            if (emailUsed == false) {
                                let pld = await AdminAPI.registerAuthor(data);
                                setCreateUserModal(false);
                            } else {
                                toast.error('Почта уже используется');
                            }
                            setRegisterLoading(false);
                        }}
                    />   
                </Modal>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1400px;
    margin: auto;
    margin-top: 50px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
`;

const AddUserButton = styled.button`
    background-color: white;
    border-radius: 5px;
    border: 2px solid var(--primary);
    color: var(--primary);
    padding: 5px 10px;
    font-weight: 500;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const PaginationPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;