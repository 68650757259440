import React from 'react'
import styled from 'styled-components'
import check from "../../assets/img/check_icon.svg"
import { Link } from 'react-router-dom';

const options = [
    'Загрузка объекта ИС',
    'Проверка хэш-записей файлов загруженного и эталонного',
    'Выдача приложения к свидетельству о депонировании'
]

export const ThirdTariff = () => {
    return (
        <Wrapper>
            <Header>Верификация эталона</Header>
            <PricePlaceholder>
                <Price>2000</Price>
                <Currency>₽/ед</Currency>
            </PricePlaceholder>

            <Text>Включает:</Text>
            <OptionsPlaceholder>
                {options.map((item, id) => {
                    return (
                      <Option key={`third_tariff_${id}`}>
                            <Check src={check}/>
                            <OptionText>{item}</OptionText>
                        </Option>
                    )
                })}
            </OptionsPlaceholder>
            <Link to='/вход'>
                <Button>Попробовать сейчас</Button>
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 360px;
    height: 600px;
    background-color: var(--primary);
    border-radius: 20px;
    padding: 53px 44px;

    @media (max-width: 768px) {
        width: 324px;
        height: 540px;
        padding: 47px 40px;
    }
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: white;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const PricePlaceholder = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
`;

const Price = styled.span`
    font-size: 36px;
    font-weight: 700;
    color: white;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Currency = styled.span`
    font-size: 24px;
    color: white;
    margin-left: 8px;
    
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Text = styled.div`
    margin-top: 22px;
    font-size: 14px;
    font-weight: 700;
    color: white;

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const OptionsPlaceholder = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const Option = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;
`;

const Check = styled.img`
    width: 18px;
    height: 18px;
`;

const OptionText = styled.div`
    font-size: 16px;
    line-height: 160%;
    color: white;
    width: 255px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Button = styled.button`
    border: none;
    border-radius: 12px;
    width: 283px;
    height: 60px;
    color: var(--primary);
    background-color: #EAF3FF;
    font-size: 16px;
    font-weight: 700;
    margin-top: 40px;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-top: 20px;
        width: 255px;
        height: 54px;
    }
`;