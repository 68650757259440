import React from 'react'
import styled from 'styled-components'
import { BlueBorderButton } from '../components/BlueBorderButton';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
    return (
        <Wrapper>
            <Number>404</Number>
            
            <Text>Такой страницы не существует</Text>

            <Link to='/'>
                <BlueBorderButton>На главную</BlueBorderButton>
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Number = styled.span`
    font-size: 250px;
    font-weight: 800;
    color: var(--primary);

    @media (max-width: 400px) {
        font-size: 150px;
    }
`;

const Text = styled.span`
    font-size: 32px;
    font-weight: 500;
    color: var(--text-dark-blue);
    margin-top: -20px;
    margin-bottom: 50px;

    @media (max-width: 400px) {
        font-size: 20px;
    }
`;