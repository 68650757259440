import * as types from '../ActionTypes.js';
import { CommonHelper } from '../../helpers/CommonHelper.js';

const initialState = {
    initialized: false,
    loading: false,
    currentUserId: undefined,
    currentUser: undefined,
    error: undefined,
    authentication: false,
    notifications: [],
};

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
};

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error };
};

const UsersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.LOGIN:
        case types.LOGOUT:
        case types.SIGNUP:
        case types.UPDATE_USER:
            return startLoading(state, action);

        case types.LOGIN_SUCCESS:
            return {
                ...state,
                currentUserId: action?.user?.id,
                currentUser: action?.user,
                loading: false
            }
        case types.LOGIN_FAIL:
            return stopLoading(state, action);
        
        case types.INITIALIZE_TSU_LOGIN:
            return {
                ...state,
                authentication: true,
            }
            
        case types.INITIALIZE_TSU_LOGIN_SUCCESS:
            return {
                ...state,
                currentUserId: action?.user?.id,
                currentUser: action?.user,
                loading: false,
                error: undefined,
                authentication: false
            }
            
            case types.INITIALIZE_TSU_LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                authentication: false,
                error: action?.error,
                currentUserId: undefined,
                currentUser: undefined,
            }
        
        case types.SIGNUP_SUCCESS:
            return {
                ...state,
                currentUserId: action?.user?.id,
                currentUser: action?.user,
                loading: false,
            };
        
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                currentUserId: undefined,
                currentUser: undefined,
                notifications: [],
                loading: false,
                error: undefined,
            }
        
        case types.INITIALIZE_AUTH:
            return {...state, authentication: true, initialized: false}

        case types.INITIALIZE_AUTH_FAIL:
            return {...state, authentication: false, initialized: false}

        case types.INITIALIZE_AUTH_SUCCESS:
            return {
                ...state,
                authentication: false,
                initialized: true,
                currentUserId: action?.user?.id,
                currentUser: action?.user,
            }
        
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                currentUser: action?.user,
                currentUserId: action?.user?.id,
                loading: false,
            }
        
        case types.UPDATE_USER_FAIL:
            return {
                ...state,
                loading: false,
            }
        
        case types.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action?.notifications,
                error: undefined,
            }
        
        case types.GET_NOTIFICATIONS_FAIL:
            return {
                ...state,
                notifications: [],
                error: action?.error
            }
        case types.READ_NOTIFICATION:
            return {
                ...state,
                notifications: action?.notifications,
                error: undefined
            }
        
        default:
            return state
    }
};

export default UsersReducer;