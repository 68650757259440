
//users
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const INITIALIZE_AUTH = 'INITIALIZE_AUTH'
export const INITIALIZE_AUTH_SUCCESS = 'INITIALIZE_AUTH_SUCCESS'
export const INITIALIZE_AUTH_FAIL = 'INITIALIZE_AUTH_FAIL';

export const INITIALIZE_TSU_LOGIN = 'INITIALIZE_TSU_LOGIN';
export const INITIALIZE_TSU_LOGIN_SUCCESS = 'INITIALIZE_TSU_LOGIN_SUCCESS';
export const INITIALIZE_TSU_LOGIN_FAIL = 'INITIALIZE_TSU_LOGIN_FAIL';

export const INITIALIZE_TSU_SIGNUP = 'INITIALIZE_TSU_SIGNUP';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';

//files
export const LOAD_DEPOSITED_FILES = 'LOAD_DEPOSITED_FILES';
export const LOAD_DEPOSITED_FILES_SUCCESS = 'LOAD_DEPOSITED_FILES_SUCCESS';
export const LOAD_DEPOSITED_FILES_FAIL = 'LOAD_DEPOSITED_FILES_FAIL';

export const DEPOSIT_FILE = 'DEPOSIT_FILE';
export const DEPOSIT_FILE_SUCCESS = 'DEPOSIT_FILE_SUCCESS';
export const DEPOSIT_FILE_FAIL = 'DEPOSIT_FILE_FAIL';