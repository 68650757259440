import { CFormInput, CFormLabel } from '@coreui/react';
import React, { useState } from 'react'
import styled from 'styled-components'
import cross from '../assets/img/cross_red.svg';
import download from '../assets/img/download.svg';
import DepositAPI from '../helpers/api/DepositAPI';
import { CommonHelper } from '../helpers/CommonHelper';

const successData = [
    {
        label: 'certificateNumber',
        text: 'Номер свидетельства',
        value: '23784892739857982475',
    },
    {
        label: 'depositDate',
        text: 'Дата депонирования',
        value: '24.09.2023',
    },
    {
        label: 'fileName',
        text: 'Файл',
        value: 'something.pdf',
    },

];

export const VerifyCertificatePage = (props) => {

    const [found, setFound] = useState(undefined);
    const [data, setData] = useState('');

    return (
        <Wrapper>
            <Header>Проверить свидетельство</Header>
            
            <InputPlaceholder>
                <CFormLabelCustom>Номер свидетельства</CFormLabelCustom>
                <CFormInputCustom
                    placeholder='Номер свидетельства'
                    value={data}
                    onChange={(evt) => {
                        setData(evt.target.value);
                    }}
                />
            </InputPlaceholder>
            
            {found == undefined || found == false ? null :
                <SuccessPlaceholder>
                    <CertificateInfo>
                        <RowName>Номер свидетельства</RowName>
                        <RowValue>{found.id}</RowValue>
                    </CertificateInfo>
                    <CertificateInfo>
                        <RowName>Дата депонирования</RowName>
                        <RowValue>{CommonHelper.correctDate(found.creationDate)}</RowValue>
                    </CertificateInfo>
                    {/* {successData.map((data, key) => {
                        return (
                            <CertificateInfo key={`certificate_success_${key}`}>
                                <RowName>{data.text}</RowName>
                                <RowValue>
                                    {data.label != 'fileName' ? null : 
                                        <DownloadIcon src={download} />
                                    }
                                    {data.value}
                                </RowValue>
                            </CertificateInfo>
                        )
                    })} */}
                </SuccessPlaceholder>
            }

            {found != false ? null :
                <FailedPlaceholder>
                    <FailedIcon src={cross} />
                    <FailedText>
                        Свидетельство с этим номером не найдено
                    </FailedText>
                </FailedPlaceholder>
            }

            <BlueButton onClick={async () => {
                let id = data;
                let res = await DepositAPI.checkRid(id);
                setFound(res == undefined ? false : {...res, id});
            }}>Проверить</BlueButton>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

const Header = styled.span`
    margin-top: 50px;
    color: var(--text-dark-blue);
    font-size: 32px;
    font-weight: 600;
`;

const InputPlaceholder = styled.div`
    max-width: 600px;
    width: 100%;
`;

const CFormInputCustom = styled(CFormInput)`
    width: 100%;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -1px;
`;

const BlueButton = styled.button`
    padding: 10px 50px;
    background-color: var(--primary);
    color: white;
    border-radius: 10px;
    border: none;

    &:hover {
        opacity: 0.8;
    }
`;

const SuccessPlaceholder = styled.div`
    max-width: 600px;
    width: 100%;
    border: 2px solid var(--border-light);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
`;

const RowName = styled.span`
    font-weight: 600;
    font-size: 15px;
`;

const RowValue = styled.span`
    font-size: 15px;
    word-wrap: break-all;
`;

const DownloadIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
`;

const CertificateInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FailedPlaceholder = styled.div`
    padding: 10px;
    max-width: 600px;
    width: 100%;
    border: 2px solid #FF9A9A;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 15px;
`;

const FailedIcon = styled.img`
    width: 32px;
    height: 32px;
`;

const FailedText = styled.span`
    font-size: 15px;
    font-weight: 500;
`;