import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal';
import { CForm, CFormCheck, CFormInput, CFormLabel } from '@coreui/react';
import { IMaskMixin } from 'react-imask';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)', marginBottom: '10px'}}/>
));

export const RightHolderInfoModal = (props) => {
    const {
        info = {},
        visible = false,
        isProfile = false,
        onClose = () => {

        },
        onSave = (data) => {

        },
    } = props

    const [data, setData] = useState(info);

    return (
        <Wrapper>
            <Modal visible={visible} onClose={onClose} header='Информация о правообладателе'>
                <CFormCustom>
                    <CFormLabelCustom>Название организации</CFormLabelCustom>
                    <CFormInputCustom
                        disabled={!isProfile}
                        id='owner_name'
                        placeholder='Название организации'
                        value={data.name}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                name: evt.target.value
                            })
                        }}
                    />
                    
                    <CFormLabelCustom>Сокращенное название организации</CFormLabelCustom>
                    <CFormInputCustom
                        disabled={!isProfile}
                        id='owner_short_name'
                        placeholder='Сокращенное название организации'
                        value={data.short_name}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                short_name: evt.target.value
                            })
                        }}
                    />

                    <CFormLabelCustom>ИНН</CFormLabelCustom>
                    <CFormInputWithMask
                        disabled={!isProfile}
                        id='owner_inn'
                        placeholder='ИНН'
                        value={data.inn}
                        mask='0000000000'
                        onComplete={(value, mask) => {
                            setData({
                                ...data,
                                inn: value
                            });
                        }}
                    />

                    <CFormLabelCustom>ОГРН</CFormLabelCustom>
                    <CFormInputWithMask
                        disabled={!isProfile}
                        id='owner_ogrn'
                        placeholder='ОГРН'
                        value={data.ogrn}
                        mask='0000000000000'
                        onComplete={(value, mask) => {
                            setData({
                                ...data,
                                ogrn: value
                            })
                        }}
                    />

                    <CFormLabelCustom>Адрес регистрации</CFormLabelCustom>
                    <CFormInputCustom
                        disabled={!isProfile}
                        id='owner_registration'
                        placeholder='Адрес регистрации'
                        value={data.address}
                        onChange={(evt) => {
                            setData({
                                ...data,
                                address: evt.target.value
                            })
                        }}
                    />
                    {isProfile == true ? null :
                        <CFormCheckCustom
                            id='author_is_owner'
                            label='Автор является правообладателем'
                            checked={data.authorIsOwner}
                            onChange={(evt) => {
                                let value = evt.target.checked;
                                setData({
                                    ...data,
                                    authorIsOwner: value
                                })
                            }}
                        />
                    }
                    {/* <CFormLabel htmlFor='author_is_owner'>Автор является правообладателем</CFormLabel> */}
                </CFormCustom>

                <SaveButtonPlaceholder>
                    <BlueButton onClick={() => {
                        onSave(data);
                    }}>
                        Сохранить
                    </BlueButton>
                </SaveButtonPlaceholder>    
            </Modal>
        </Wrapper>
    )
}

const Wrapper = styled.div`

`;

const CFormCustom = styled(CForm)`
    margin: 20px 30px;
    margin-bottom: 0;
    width: 350px;
    display: flex;
    flex-direction: column;

    @media (max-width: 400px) {
        max-width: 300px;
        margin: 0;
        margin-top: 40px;
    }
`;

const CFormInputCustom = styled(CFormInput)`
    margin-bottom: 10px;
    
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    margin-top: -5px;
    margin-bottom: 20px;

    :focus, :active {
        box-shadow: none;
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -1px;
`;

const SaveButtonPlaceholder = styled.div`
    width: 250px;
    margin: auto;
`;

const BlueButton = styled.button`
    margin-top: 20px;
    width: 250px;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;
`;