import React from 'react'
import styled from 'styled-components'

export const BlueButton = (props) => {
    return (
        <Button>{props.children}</Button>
    )
}

const Button = styled.button`
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;