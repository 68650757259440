import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CSpinner } from '@coreui/react'
import DepositAPI from '../helpers/api/DepositAPI';
import { RegistryTable } from './RegistryTable';
import { Link } from 'react-router-dom';

export const ShortRegistry = () => {

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        DepositAPI.getRegister()
            .then(x => {
                setData(x);
                setLoading(false);
            })
            .catch(err => { 
                setLoading(false);
            })
        
    }, [])

    if (loading) { 
        return (
            <CSpinner />
        )
    }

    return (
        <Wrapper>
            <Table>
                <RegistryTable data={data} />
            </Table>

            <Link to='/реестр'>
                <BlueButton>Перейти в реестр</BlueButton>
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Table = styled.div`
    max-width: 1200px;
    box-shadow: var(--box-shadow);
    padding: 20px;
    border-radius: 10px;
`;

const BlueButton = styled.button`
    margin-top: 30px;
    width: fit-content;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;