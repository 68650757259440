import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CertificateAPI from '../helpers/api/CertificateAPI';
import * as DOMPurify from 'dompurify';
import { CSpinner } from '@coreui/react';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import { Link } from 'react-router-dom';
import { getRoleName } from './ProfilePage';
import { useSelector } from 'react-redux';

export const CertificatePage = () => {
    let certificateId = decodeURI(window.location.href).split('/');
    certificateId = certificateId[certificateId.length - 1]

    const [certificateHtml, setCertificateHtml] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const currentUser = useSelector(state => state.users.currentUser)

    const saveToPDF = async (pages = 1) => {
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'px',
            compress: true,
        });
        let canvas = await html2canvas(document.getElementsByClassName('box')[0], {
            scale: 3,
            allowTaint: true,
            useCORS: true,
            width: 440,
            height: 630
        });
        pdf.addImage(canvas.toDataURL("image/png"), 'PNG', 0, 0, 450, 640);
        // pdf.addPage();
        
        // for (let index = 0; index < pages - 1; index++) {
        //     let canvas = await html2canvas(document.getElementsByClassName('back_box')[index], {
        //         scale: 3,
        //         allowTaint: true,
        //         useCORS: true,
        //         width: 440,
        //         height: 630
        //     });

        //     pdf.addImage(canvas.toDataURL("image/png"), 'PNG', 0, 0, 450, 640);
        //     if (index < pages - 2) {
        //         pdf.addPage();
        //     }
        // };

        pdf.save(`Свидетельство_${certificateId}.pdf`);
    }

    useEffect(() => {
        setLoading(true);
        CertificateAPI.getCertificate(certificateId).then(html => {
            const cleanHtml = DOMPurify.sanitize(html);
            setCertificateHtml(html);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }, [])

    return (
        <Wrapper>
            <CBreadcrumb>
                <CBreadcrumbItemCustom>
                    <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                        Личный кабинет {getRoleName(currentUser.userType)}
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom>
                    <Link to='/файлы' style={{textDecoration: 'none'}}>
                        Мои свидетельства
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom active>РИД</CBreadcrumbItemCustom>
            </CBreadcrumb>

            <Inner>
                <Header>
                    Свидетельство №{certificateId}
                </Header>
                {loading == false ? null :
                    <CSpinner style={{marginTop: '50px'}} />
                }
                {certificateHtml == undefined ? null :
                    <CertificatePlaceholder>
                        <Certificate dangerouslySetInnerHTML={{ __html: certificateHtml }} />
                    </CertificatePlaceholder>
                }
                {certificateHtml == undefined ? null :
                    <SaveCertificateButton
                        onClick={async () => {
                            setDownloading(true);
                            saveToPDF().then(() => {
                                setDownloading(false);
                            });
                        }}
                    >
                        {downloading ? <CSpinner color='light' size='sm' /> : 'Сохранить PDF'}
                    </SaveCertificateButton>
                }
                {certificateHtml != undefined || loading != false ? null :
                    <Fail>Не удалось загрузить, вероятно, свидетельства с таким номером не существует</Fail>
                }
            </Inner>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 50px 20px;
    margin: auto;
    max-width: 1200px;
    box-sizing: border-box;
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 600;
`;

const CertificatePlaceholder = styled.div`
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Certificate = styled.div`
    margin-top: 20px;
    user-select: none;
    /* zoom: 2; */
    /* max-width: 1240px; */
    /* max-height: 874px; */
`;

const Fail = styled.span`
    margin-top: 20px;
    font-size: 20px;
`;

const SaveCertificateButton = styled.button`
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;