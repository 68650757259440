import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import defaultAvatar from '../assets/img/avatar_placeholder.png';
import edit from '../assets/img/edit.svg';
import files_icon from '../assets/img/files.svg';
import certificate_icon from '../assets/img/certificate.svg';
import search_icon from '../assets/img/search.svg';
import sell_icon from '../assets/img/sell.svg';
import coins_icon from '../assets/img/coins.svg';
import admin_icon from '../assets/img/admin_dashboard.svg';
import { CFormCheck, CFormInput, CFormLabel, CTooltip } from '@coreui/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../components/Modal';
import { ImageUploader } from '../components/ImageUploader';
import { API_ENDPOINT } from '../helpers/config';
import { EditProfileModal } from '../components/modals/EditProfileModal';
import { IMaskMixin } from 'react-imask'
import * as userActions from '../redux/actions/UsersActions.js';
import UsersAPI from '../helpers/api/UserAPI.js';
import DepositAPI from '../helpers/api/DepositAPI.js';
import { RightHolderInfo } from '../components/depositing/RightHolderInfo.js';
import edit_icon from '../assets/img/edit_blue.svg';
import { RightHolderInfoModal } from '../components/modals/RightHolderInfoModal.js';
import toast from 'react-hot-toast';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', fontSize: '18px'}}/>
))

export const getRoleName = (role) => {
    switch (role) {
        case 1:
            return 'автора';
        case 2:
            return 'правообладателя';
        case 3:
            return 'представителя правообладателя';
        case 4:
            return 'администратора';
        
        default:
            break;
    }
}

const personalInfo = [
    {
        name: 'dateBorn',
        label: 'дата рождения',
        value: '12.12.1990'
    },
    {
        name: 'phone',
        label: 'номер телефона',
        value: '+7 (777) 777 77-77'
    },
    {
        name: 'email',
        label: 'почта',
        value: 'email@example.com'
    },
    {
        name: 'inn',
        label: 'ИНН',
        value: '1234567890'
    },
    {
        name: 'citizenship',
        label: 'гражданство',
        value: 'Российская Федерация'
    },
    {
        name: 'address',
        label: 'место проживания',
        value: 'г. Томск, ул. Ленина 36'
    },
]

const buttons = [
    {
        label: 'Мои свидетельства',
        icon: files_icon,
        link: '/файлы'
    },
    {
        label: 'Депонирование РИД',
        icon: certificate_icon,
        link: '/депонирование'
    },
    {
        label: 'Продажа и покупка РИД',
        icon: sell_icon,
        link: '/'
    },
    // {
    //     label: 'Реестр РИД',
    //     icon: search_icon,
    //     link: '/реестр'
    // },
    {
        label: 'Мои платежи',
        icon: coins_icon,
        link: '/'
    },
]

const getUserInfo = (user) => {
    if (user == undefined) {
        return [];
    }

    let info = JSON.parse(JSON.stringify(personalInfo));
    for (let value of info) {
        value.value = user[value.name];
    }
    return info;
}

export const ProfilePage = (props) => {

    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);

    const userInfo = getUserInfo(currentUser);
    const avatarSrc = currentUser && currentUser.photo ? `${API_ENDPOINT}/api/user/avatar/` + currentUser.photo : undefined;

    const [editing, setEditing] = useState(false);
    const [avatarModal, setAvatarModal] = useState(false);
    const [rightholderInfo, setRightholderInfo] = useState(undefined);
    const [editRightholderInfo, setEditRightholderInfo] = useState(false);
    const [sendConfirmation, setSendConfirmation] = useState(false);

    useEffect(() => {
        if (currentUser && currentUser?.userType == 2) {
            DepositAPI.getRightholderInfo()
            .then(res => {
                setRightholderInfo(res[0]);    
            })
            .catch(err => {
                console.log('error: ', err);    
            })
        }
    }, [currentUser])

    return (
        <Wrapper>
            <Header>Личный кабинет {getRoleName(currentUser.userType)}</Header>

            <AccountInfoPlaceholder>
                <AvatarPlaceholder>
                    <Avatar $src={avatarSrc == undefined ? defaultAvatar : avatarSrc}>
                        <ChangeAvatar onClick={() => setAvatarModal(true)}>
                            <ChangeAvatarText>Изменить</ChangeAvatarText>
                        </ChangeAvatar>
                    </Avatar>

                    <EditButton onClick={() => { setEditing(!editing); }}>
                        Редактировать профиль
                        <Edit src={edit} />
                    </EditButton>

                </AvatarPlaceholder>

                <PersonalInfo>
                    <Name>{currentUser.lastName} {currentUser.firstName} {currentUser.secondName}</Name>

                    {userInfo.map((info, key) => {
                        if (info.name == 'email') {
                            return (
                                <React.Fragment key={`personal_info_${info.value}`}>
                                    <EmailRow>
                                        <CFormLabelCustom>{info.label}</CFormLabelCustom>
                                        <ConfirmedSpan>
                                            {currentUser?.emailConfirmed == true ? '(подтверждена)'
                                                :
                                                sendConfirmation == false ? 
                                                    <Confirm onClick={() => {
                                                        UsersAPI.confirmEmail();
                                                        toast.success('Письмо с подтверждением отправлено Вам на почту');
                                                        setSendConfirmation(true);
                                                    }}>
                                                        Подтвердить
                                                    </Confirm>
                                                    :
                                                    <ConfirmedSpan>письмо отправлено</ConfirmedSpan>
                                            }
                                        </ConfirmedSpan>
                                    </EmailRow>
                                    <CFormInputCustom value={info.value} disabled plainText/>
                                </React.Fragment>
                            )
                        }

                        if (info.name == 'phone') {
                            return (
                                <React.Fragment key={`personal_info_${info.value}`}>
                                    <CFormLabelCustom>{info.label}</CFormLabelCustom>
                                    <CFormInputWithMask
                                        value={info.value}
                                        mask="+{7} (000) 000-00-00"
                                        lazy={false}
                                        disabled
                                        readOnly
                                        plainText
                                    />
                                </React.Fragment>
                            )
                        }

                        if (info.name == 'dateBorn') { 
                            if (currentUser.dateBorn == '') {
                                return (
                                    <React.Fragment key={`personal_info_${info.value}`}>
                                        <CFormLabelCustom>{info.label}</CFormLabelCustom>
                                        <CFormInputCustom
                                            defaultValue={'Необходимо заполнить'}
                                            disabled
                                            readOnly
                                            plainText
                                        />
                                    </React.Fragment>
                                )
                            }
                            return (
                                <React.Fragment key={`personal_info_${info.value}`}>
                                    <CFormLabelCustom>{info.label}</CFormLabelCustom>
                                    <CFormInputWithMask
                                        value={currentUser.dateBorn}
                                        mask="00.00.0000"
                                        lazy={false}
                                        disabled
                                        readOnly
                                        plainText
                                    />
                                </React.Fragment>
                            )
                        }

                        return (
                            <React.Fragment key={`personal_info_${info.value}`}>
                                <CFormLabelCustom>{info.label}</CFormLabelCustom>
                                <CFormInputCustom
                                    defaultValue={info.value || 'Необходимо заполнить'}
                                    disabled
                                    readOnly
                                    plainText
                                />
                                {info.label !== 'отчество' ? null :
                                    <CFormCheckCustom
                                        id='patronymic_check'
                                        label="Отчество отсутствует"
                                        />
                                }
                            </React.Fragment>
                        )
                    })}   
                </PersonalInfo>

                {currentUser.userType != 2 || rightholderInfo == undefined ? null :
                    <RightholderInfo>
                        <RightholderHeader>Информация о правообладателе</RightholderHeader>
                        <CTooltip content="Редактировать">
                            <Edit src={edit_icon} onClick={() => setEditRightholderInfo(true)}/>
                        </CTooltip>
                        <RightHolderInfo data={rightholderInfo} />
                    </RightholderInfo>
                }
            </AccountInfoPlaceholder>

            {currentUser?.userType != 2 ? null :
                <OrderPlaceholder>
                    <div>
                        Действует согласно №155 от 12.12.2023
                    </div>
                    <div>
                        срок действия до 31.12.2024
                    </div>
                </OrderPlaceholder>
            }
            

            <BottomPlaceholder>
                {buttons.map((button, key) => {
                    return (
                            <Link to={button.link} style={{textDecoration: 'none'}} key={`account_buttons_${key}`}>
                                <Button>
                                    <ButtonIcon src={button.icon} />
                                    <ButtonText>{button.label}</ButtonText>
                                </Button>
                            </Link>
                        );
                    }
                )}
                {currentUser.userType != 4 ? null :
                    <Link to='/админ' style={{textDecoration: 'none'}}>
                        <Button>
                            <ButtonIcon src={admin_icon} />
                            <ButtonText>Панель администратора</ButtonText>
                        </Button>
                    </Link>
                }
            </BottomPlaceholder>

            <Modal
                visible={avatarModal}
                onClose={() => setAvatarModal(false)}
                header={'Изменить аватар'}
            >    
                <ImageUploader 
                    currentUser={currentUser}
                    onUpload={async (data) => {
                        let res = await UsersAPI.uploadAvatar(data);
        
                        await dispatch(userActions.updateUser({...currentUser, photo: res}));
                    }}
                    onClose={() => setAvatarModal(false)}
                />
            </Modal>

            {editing == false ? null :
                <EditProfileModal
                    currentUser={currentUser}
                    onClose={() => setEditing(false)}
                    onSave={async (data) => {
                        await dispatch(userActions.updateUser(data));
                        setEditing(false);
                    }}
                />
            }

            {editRightholderInfo == false ? null :
                <RightHolderInfoModal
                    visible={true}
                    onClose={() => setEditRightholderInfo(false)}
                    isProfile={true}
                    info={rightholderInfo}
                    onSave={async (data) => {
                        const rhInfo = await DepositAPI.updateRightholderInfo(data);
                        setRightholderInfo(rhInfo[0]);
                        setEditRightholderInfo(false);
                    }}
                />
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1225px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 55px;

    @media (max-width: 640px) {
        align-items: center;
    }
`;

const Header = styled.h1`
    font-size: 32px;
    font-weight: 600;
    color: var(--text-dark-blue);
`;

const AccountInfoPlaceholder = styled.div`
    width: 100%;
    display: flex;
    gap: 50px;
    padding-top: 20px;
    flex-wrap: wrap;

    @media (max-width: 640px) {
        flex-direction: column;
        gap: 20px;
    }
`;

const AvatarPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const Avatar = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 10px;
    background-image: url(${props => props.$src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

const ChangeAvatar = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;

    &:hover {
        background-color: black;
        opacity: 0.3;
    }
`;

const ChangeAvatarText = styled.span`
    color: white;
    font-weight: 600;
`;  

const PersonalInfo = styled.div`
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Name = styled.span`
    max-width: 400px;
    font-size: 24px;
    font-weight: 500px;
    color: #373737;
`;

const EmailRow = styled.div`
    display: flex;
    align-items: center;
`;

const ConfirmedSpan = styled.span`
    margin-left: 10px;
    font-size: 14px;
    color: #B4B4B4;
`;

const Confirm = styled.div`
    color: var(--error);
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    max-width: 400px;
    font-size: 14px;
    color: #B4B4B4;
    margin: 0px;
`;

const CFormInputCustom = styled(CFormInput)`
    font-size: 18px;
    max-width: 400px;

    &:focus, &:active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    margin-top: 5px;

    :focus, :active {
        box-shadow: none;
    }
`;

const SaveButton = styled.button`
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-weight: 500;
    background-color: var(--primary);
    color: white;
`;

const EditButton = styled.div`
    font-size: 18px;
    font-weight: 600; 
    color: var(--text-dark-blue);
    cursor: pointer;
`;

const Edit = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 10px;
    cursor: pointer;
`;

const BottomPlaceholder = styled.div`
    margin: 90px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 640px) {
        margin: 40px 0;
        justify-content: center;
    }
`;

const Button = styled.button`
    border: none;
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 10px;
    padding: 15px 24px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;
    user-select: none;
`;

const ButtonIcon = styled.img`
    width: 32px;
    height: 32px;
`;

const ButtonText = styled.p`
    max-width: 190px;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    margin: 0;
    color: var(--primary);
    text-decoration: none;
`;

const RightholderInfo = styled.div`
    margin: auto;
    max-width: 370px;
    height: fit-content;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--border-light);
`;

const RightholderHeader = styled.span`
    font-weight: 600;
    font-size: 16px;
`;

const OrderPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 20px;
    padding-bottom: 0px;
`;