import { API_ENDPOINT } from "../config";
import axios from "axios";
import $api from './axiosSettings';

axios.defaults.withCredentials = true;

const AdminAPI = {
    
    async getUsersCount() {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/statistic/users/count`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getRidCount() {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/statistic/rids/count`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getUsers(count = 10, offset = 1) {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/user/all/admin?count=${count}&offset=${offset}`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getRids(count = 10, offset = 1) {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/deposit/all/admin?count=${count}&offset=${offset}`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async changeUserProperty(userId, property, newValue) {
        try {
            let data = {
                userId,
                column: property,
                value: newValue
            }
            let pld = await $api.post(`${API_ENDPOINT}/api/user/change/admin`, data);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async getRidInfo(id) {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/deposit/id?ridId=${id}`);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async confirmRid(ridId) {
        try {
            let pld = await $api.post(`${API_ENDPOINT}/api/deposit/confirm/admin`, { ridId });
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },

    async registerAuthor(data) {
        try {
            let pld = await $api.post(`${API_ENDPOINT}/api/user/register/admin`, data);
            return pld.data;
        } catch (error) {
            console.log('error: ', error);
        }
    },
};

export default AdminAPI;