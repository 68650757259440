import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import micro_icon from '../assets/img/voice.svg';
import text_icon from '../assets/img/text_icon.svg';
import image_icon from '../assets/img/image_icon.svg';
import video_icon from '../assets/img/video_icon.svg';
import code_icon from '../assets/img/code_icon.svg';
import tech_icon from '../assets/img/technology_icon.svg';
import options from '../assets/img/options.svg';
import notConfirmed from '../assets/img/exclamation.svg';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

const getIcon = (type) => {
    switch (type) {
        case 'Звук':
            return micro_icon
        case 'Текст':
            return text_icon
        case 'Изображение':
            return image_icon
        case 'Видео':
            return video_icon
        case 'Код':
            return code_icon
        case 'Технология':
            return tech_icon
        default:
            return micro_icon        
    }
}

const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toUpperCase();
}

export const File = (props) => {
    const {
        id,
        type,
        fileName = 'Название файла',
        fileType = 'Тип файла',
        uploadDate = 'Дата загрузки',
        confirmed = false,
        onInfoClick = () => {

        }
    } = props;

    let contextMenuRef = useRef();
    
    const [menuVisible, setMenuVisible] = useState(false);
    const [position, setPosition] = useState(undefined);

    useEffect(() => {
        const handleClick = (evt) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(evt.target)) {
                setMenuVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClick);
    }, [])

    return (
        <Wrapper>
            <LeftSidePlaceholder $confirmed={confirmed}>
                <Icon src={getIcon(type)} />
                <FileName
                    data-tooltip-id={`file_name_${id}`}
                    data-tooltip-place="top"
                >
                    {fileName}
                </FileName>
                <Tooltip
                    id={`file_name_${id}`}
                    content={fileName}
                    style={{maxWidth: '300px', opacity: 1}}
                />
            </LeftSidePlaceholder>
            
            <Text $confirmed={confirmed}>{getFileExtension(fileType)}</Text>

            <RightSidePlaceholder>
                <Options
                    src={options}
                    onClick={(evt) => {
                        setPosition({ x: evt.pageX - 100, y: evt.pageY - (confirmed == false ? 70 : 100) });
                        setMenuVisible(true);
                    }}
                />
                {menuVisible == false ? null :
                    <ContextMenu ref={contextMenuRef} $top={position.y} $left={position.x}>
                        <MenuOption
                            onClick={() => {
                                onInfoClick();
                                setMenuVisible(false);
                            }}
                        >
                            Подробнее
                        </MenuOption>
                        
                        {confirmed == false ? null :
                            <Link to={`/свидетельство/${id}`} style={{ textDecoration: 'none' }}>
                                <MenuOption>
                                    Свидетельство
                                </MenuOption>
                            </Link>
                        }
                    </ContextMenu>
                }
                <Text>{uploadDate}</Text>
                {confirmed == true ? null :
                    <>
                        <Options
                            src={notConfirmed}
                            data-tooltip-id={`file_conf_${id}`}
                            data-tooltip-place="top"
                        />
                        <Tooltip id={`file_conf_${id}`} content={"РИД на проверке у администратора"} />
                    </>
                }
            </RightSidePlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--border-light);
    height: 70px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 19px 24px;
    box-sizing: border-box;
    background-color: white;

    @media (max-width: 450px) {
        flex-wrap: wrap;
        height: 100px;
        padding: 16px 24px;
        gap: 10px;
    }
`;

const LeftSidePlaceholder = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    width: 100%;
    opacity: ${props => props.$confirmed ? 1 : 0.7};

    @media (max-width: 450px) {
        gap: 10px;
        width: 100%;
    }
`;

const RightSidePlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 25px;


    @media (max-width: 450px) {
        width: fit-content;
    }
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
    user-select: none;
`;

const FileName = styled.span`
    font-weight: 600;
    font-size: 20px;
    max-width: 450px;
    /* width: 100%; */
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
`;

const Text = styled.span`
    opacity: ${props => props.$confirmed ? 1 : 0.7};
    font-size: 18px;
    cursor: default;
`;

const Options = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    user-select: none;
`;

const ContextMenu = styled.div`
    position: absolute;
    top: ${props => props.$top + 'px'};
    left: ${props => props.$left + 'px'};
    padding: 10px 5px;
    border-radius: 7px;
    background-color: white;
    border: 1px solid var(--border-light);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
`;

const MenuOption = styled.p`
    margin: 0;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: rgba(0, 0, 0, 0.02);
    }
`;