import React from 'react'
import styled from 'styled-components'

export const RightHolderInfo = (props) => {
    const {
        data,
    } = props

    if (data == undefined) {
        return
    }

    return (
        <Wrapper>
            <Line>
                <Title>Название организации:</Title>
                <Value>{data.name}</Value>
            </Line>
            <Line>
                <Title>Сокращенное название организации:</Title>
                <Value>{data.short_name}</Value>
            </Line>
            <Line>
                <Title>ИНН:</Title>
                <Value>{data.inn}</Value>
            </Line>
            <Line>
                <Title>ОГРН:</Title>
                <Value>{data.ogrn}</Value>
            </Line>
            <Line>
                <Title>Адрес регистрации:</Title>
                <Value>{data.address}</Value>
            </Line>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;

const Line = styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
`;

const Title = styled.span`
    font-weight: 500;
    margin-right: 10px;
`;

const Value = styled.span`
    text-align: right;
`;
