import React from 'react'
import styled from 'styled-components'
import { CommonHelper } from '../../helpers/CommonHelper';
import { Link } from 'react-router-dom';
import AdminAPI from '../../helpers/api/AdminAPI';

const getName = (author) => {
    if (author?.lastName == undefined) {
        return ''
    }
    return `${author?.lastName} ${author?.firstName} ${author?.secondName}`
}

export const Rid = (props) => {
    const {
        data,
        bold = false,
        onChange = () => {

        },
    } = props;

    console.log(data);

    return (
        <Wrapper>
            <ID style={{ fontWeight: bold ? 'bold' : '' }}>{data?.ridId}</ID>
            <Text style={{ fontWeight: bold ? 'bold' : '' }}>{data?.typeName}</Text>
            <Text style={{ fontWeight: bold ? 'bold' : '' }}>{data?.subTypeName}</Text>
            <Name style={{ fontWeight: bold ? 'bold' : '' }}>{data?.ridTitle}</Name>
            <Name style={{ fontWeight: bold ? 'bold' : '' }}>
                {typeof(data?.authorId) == 'string' ? data?.authorId : getName(data?.authorId)}
            </Name>
            <Text style={{ fontWeight: bold ? 'bold' : '', maxWidth: '160px' }}>
                {data?.creationDate.includes('Дата') ? data.creationDate : CommonHelper.correctDate(data?.creationDate)}
            </Text>
            {bold ?
                <Approved style={{ fontWeight: 'bold'}}>
                    Подтвержден
                </Approved>
                :
                <Approved>
                    {data.isActual ? 'Подтвержден'
                        :
                        <Confirm onClick={async () => {
                            await AdminAPI.confirmRid(Number(data.ridId) - 100);
                            onChange();
                        }}>
                            Подтвердить
                        </Confirm>
                    }
                </Approved>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid var(--border-light);
`;

const ID = styled.span`
    max-width: 50px;
    width: 100%;
    height: 100%;
    font-weight: 600;
    cursor: pointer;
`;

const Name = styled.span`
    max-width: 400px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const Text = styled.span`
    max-width: 140px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const Approved = styled.div`
    max-width: 140px;
    width: 100%;
    height: 100%;
`;

const Confirm = styled.button`
    border: 2px solid var(--primary);
    border-radius: 5px;
    background-color: transparent;
    color: var(--primary);
    padding: 5px 10px;
    cursor: pointer;
    font-weight: 500;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }
`;