import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { CCloseButton } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css';

export const Modal = (props) => {
    const {
        header = '',
        visible = false,
        onClose = () => {

        },
    } = props;

    useEffect(() => {
        const wrapper = document.getElementById('modal_wrapper');
        if (wrapper) {
            wrapper.addEventListener('mousedown', (evt) => {
                if (evt.target == wrapper) {
                    onClose();
                }
            })
        }
    }, []);

    if (visible == false) {
        return null;
    }

    return (
        <Wrapper id='modal_wrapper'>
            <ModalPlaceholder>
                <ModalBox onClick={(evt) => evt.stopPropagation()}>
                    <Header>{header}</Header>
                    <Cross>
                        <CCloseButton onClick={onClose}/>
                    </Cross>
                    
                    <ModalInner>
                        {props.children}
                    </ModalInner>
                </ModalBox>
            </ModalPlaceholder>
        </Wrapper>
    )
}

const modalAnimation = keyframes`
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
`;

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: all;
`;

const ModalPlaceholder = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Cross = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;

const Header = styled.span`
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: 600;
    font-size: 20px;
    color: var(--text-dark-blue);
`;

const ModalBox = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    pointer-events: all;
    animation: ${modalAnimation} 0.15s ease-in;
    
    @media (max-width: 400px) {
        max-width: 380px;
        /* width: fit-content; */
    }
`;

const ModalInner = styled.div`
    margin-top: 30px;
    overflow: auto;
    max-height: 650px;
`;
