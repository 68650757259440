import React from "react";
import styled from "styled-components";
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import WelcomePage from "../pages/WelcomePage";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { LoginPage } from "../pages/LoginPage";
import DepositingPage from "../pages/DepositingPage";
import { ProfilePage } from "../pages/ProfilePage";
import { FilesPage } from "../pages/FilesPage";
import { RegisterPage } from "../pages/RegisterPage";
import { ErrorPage } from "../pages/ErrorPage";
import { VerifyCertificatePage } from "../pages/VerifyCertificatePage";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast"; 
import { TsuAccountAuthPage } from "../pages/TsuAccountAuthPage";
import { RegistryPage } from "../pages/RegistryPage";
import { CertificatePage } from "../pages/CertificatePage";
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { ConsultationsPage } from "../pages/ConsultationsPage";
import { ContactsPage } from "../pages/ContactsPage";
import { AdminPage } from "../pages/AdminPage";
import { AdminStatsPage } from "../pages/AdminStatsPage";
import { AdminUsersPage } from "../pages/AdminUsersPage";
import { AdminRidPage } from "../pages/AdminRidPage";
import { AdminRidApprove } from "../pages/AdminRidApprove";
import { AdminChangeDocsPage } from "../pages/AdminChangeDocsPage";

const privateRoutes = (
    <Routes>
        <Route path='/' element={<WelcomePage />} />
        <Route path='/вход' element={<LoginPage />} />
        <Route path='/регистрация' element={<RegisterPage />} />
        <Route path='/аккаунт' element={<ProfilePage />} />
        <Route path='/файлы' element={<FilesPage />} />
        <Route path='/депонирование' element={<DepositingPage />} />
        <Route path='/проверка' element={<VerifyCertificatePage />} />
        <Route path='/тгу' element={<TsuAccountAuthPage />} />
        <Route path='/реестр' element={<RegistryPage />} />
        <Route path='/свидетельство/*' element={<CertificatePage />} />
        <Route path='/консультации' element={<ConsultationsPage />} />
        <Route path='/контакты' element={<ContactsPage />} />
        <Route path='/админ' element={<AdminPage />} />
        <Route path='/админ/статистика' element={<AdminStatsPage />} />
        <Route path='/админ/пользователи' element={<AdminUsersPage />} />
        <Route path='/админ/рид' element={<AdminRidPage />} />
        <Route path='/админ/документы' element={<AdminChangeDocsPage />} />
        <Route path='*' element={<ErrorPage />} />
    </Routes>
);

const publicRoutes = (
    <Routes>
        <Route path='/' element={<WelcomePage />} />
        <Route path='/вход' element={<LoginPage />} />
        <Route path='/регистрация' element={<RegisterPage />} />
        <Route path='/проверка' element={<VerifyCertificatePage />} />
        <Route path='/тгу' element={<TsuAccountAuthPage />} />
        <Route path='/реестр' element={<RegistryPage />} />
        <Route path='/сбросПароля' element={<ForgotPasswordPage />} />
        <Route path='/восстановление' element={<ResetPasswordPage />} />
        <Route path='/консультации' element={<ConsultationsPage />} />
        <Route path='/контакты' element={<ContactsPage />} />
        <Route path='*' element={<Navigate to='/вход' />} />
    </Routes>
)

const pagesNoHeader = ['/вход', '/тгу', '/регистрация', '/сбросПароля', '/восстановление'];
const pagesNoFooter = ['/вход', '/тгу', '/регистрация', '/сбросПароля', '/восстановление'];

const checkHeader = (location) => {
    let hasHeader = true;
    let url = decodeURI(location.pathname);
    for (let page of pagesNoHeader) {
        if (url.includes(page)) {
            hasHeader = false;
        }
    }

    return hasHeader;
}

const checkFooter = (location) => {
    let hasFooter = true;
    let url = decodeURI(location.pathname);
    for (let page of pagesNoFooter) {
        if (url.includes(page)) {
            hasFooter = false;
        }
    }

    return hasFooter;
}

export default function RouterApp(props) {

    const currentUser = useSelector(state => state.users.currentUser);
    const location = useLocation();

    let hasHeader = checkHeader(location);
    let hasFooter = checkFooter(location);

    return (
        <>
            {hasHeader === false ? null :
                <HeaderPlaceholder>
                    <Header />
                </HeaderPlaceholder>
            }
            
            <Content $hasHeader={hasHeader}>
                {currentUser == undefined ? publicRoutes : privateRoutes}
            </Content>

            {hasFooter === false ? null :
                <Footer />
            }
            <Toaster />
        </>
    );
}

const HeaderPlaceholder = styled.header`
  width: 100vw;
  height: 90px;
  z-index: 5;
  position: fixed;

  @media (max-width: 768px) {
    height: 70px;
  }
`;

const Content = styled.main`
  padding-top: ${props => props.$hasHeader ? '90px' : ''};
  min-height: calc(100vh - 250px);

  @media (max-width: 768px) {
      padding-top: ${props => props.$hasHeader ? '70px' : ''};

  }
`;
