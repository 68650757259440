import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import AdminAPI from '../helpers/api/AdminAPI';
import { Rid } from '../components/admin/Rid';
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import { Pagination } from '../components/Pagination';

export const AdminRidPage = () => {
    const currentUser = useSelector(state => state.users.currentUser);

    const [rids, setRids] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        AdminAPI.getRidCount().then(x => {
            setCount(x?.all?.count);
        })
    }, [])

    useEffect(() => {
        AdminAPI.getRids(10, currentPage).then(x => {
            if (x.length > 0) {
                setRids(x);
            }
        });
    }, [currentPage])

    if (currentUser?.userType != 4) {
        return <Navigate to='/'/>
    }

    return (
        <Wrapper>
            <CBreadcrumb>
                <CBreadcrumbItemCustom>
                    <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                        Личный кабинет администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom>
                    <Link to='/админ' style={{textDecoration: 'none'}}>
                        Панель администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom active>РИД</CBreadcrumbItemCustom>
            </CBreadcrumb>
            <Table>
                <Rid
                    bold={true}
                    data={{
                        ridId: 'ID',
                        typeName: 'Тип',
                        subTypeName: 'Подтип',
                        ridTitle: 'Название',
                        authorId: 'Автор',
                        creationDate: 'Дата депонирования',
                    }}
                />
                {rids?.map((rid, key) => {
                    return (
                        <Rid
                            data={rid}
                            key={`rid_admin_panel_${key}`}
                            onChange={() => {
                                AdminAPI.getRids(10, currentPage).then(x => {
                                    setRids(x);
                                });
                            }}
                        />
                    )
                })}
            </Table>

            <PaginationPlaceholder>
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(count / 10)}
                    onPageChange={(page) => {
                        setCurrentPage(page);
                    }}
                />
            </PaginationPlaceholder>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1300px;
    margin: auto;
    margin-top: 50px;
`;

const Table = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const PaginationPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px 0;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;