import React from 'react'
import styled from 'styled-components'

const firstTariff = [
    {
        header: 'Цифровое свидетельство',
        prices: [
            {
                sum: '0*',
                currency: '₽/ед',
            },
            {
                sum: '1 000',
                currency: '₽/ед',
            }
        ]
    },
    {
        header: 'Бумажное свидетельство ',
        prices: [
            {
                sum: '500*',
                currency: '₽/ед',
            },
            {
                sum: '1 500',
                currency: '₽/ед',
            }
        ]
    },
]

export const FirstTariff = () => {
    return (
        <Wrapper>
            {firstTariff.map((item, id) => {
                return (
                    <Block key={`first_tariff_block_${id}`}>
                        <Header>{item.header}</Header>
                        {item.prices.map((price, key) => {
                            return (
                                <Price key={`first_tariff_${key}`}>
                                    <Sum>{price.sum}</Sum>
                                    <Currency>{price.currency}</Currency>
                                </Price>
                            );
                        })}
                    </Block>
                );
            })}
            <Text>* Тариф действует для сотрудников НИ ТГУ, обучающихся лиц, участников научно-исследовательских и образовательных программ.</Text>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 360px;
    height: 600px;
    border-radius: 20px;
    border: 1px solid #E5E5E5;
    padding: 13px 44px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.10);

    @media (max-width: 768px) {
        width: 324px;
        height: 540px;
        padding: 10px 30px;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: 768px) {
        gap: 5px;
    }
`;

const Header = styled.span`
    margin-top: 15px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: var(--text-dark-blue);

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Sum = styled.span`
    font-size: 36px;
    font-weight: 700;
    color: var(--primary);
`;

const Currency = styled.span`
    font-size: 24px;
    margin-left: 8px;
    color: #465E6F;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Price = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Text = styled.div`
    margin-top: 11px;
    font-size: 14px;
    color: rgba(10, 27, 50, 0.50);
    line-height: 160%;
`;