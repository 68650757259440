import React, { useState } from 'react';
import styled from 'styled-components';
import tsu from '../assets/img/tsu_img.jpg';
import logo from '../assets/img/tsu_patent_logo_blue.svg';
import { CModal, CModalBody, CForm, CFormInput, CModalHeader, CButton, CModalFooter } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import { BlueBorderButton } from '../components/BlueBorderButton';
import { BlueButton } from '../components/BlueButton';
import { Register } from '../components/Register';
import { Link } from 'react-router-dom';

export const RegisterPage = () => {

    return (
        <Wrapper>
            <LeftPart>
                <Link to='/'>
                    <Logo src={logo} />
                </Link>

                <Register />
            </LeftPart>
            <RightPart>
                <TSU $src={tsu} />
            </RightPart>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`;

const LeftPart = styled.div`
    flex-basis: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 768px) {
        flex-basis: 100%;
        justify-content: center;
        padding: 0;
    }
`;

const Logo = styled.img`
    height: 70px;

    @media (max-width: 400px) {
        height: 50px;
    }
`;

const RightPart = styled.div`
    flex-basis: 50%;
    height: 100%;
    overflow: hidden;

    @media (max-width: 768px) {
        display: none;
    }
`;

const TSU = styled.div`
    background-image: url(${props => props.$src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
`;