import React from 'react'
import styled from 'styled-components'

export const DepositSteps = (props) => {
    const {
        header,
        text,
        number
    } = props;

    return (
        <Wrapper>
            <Top>
                <Number>{number}</Number>
                <Header>{header}</Header>
            </Top>
            
            <Bottom>
                <Text>{text}</Text>
            </Bottom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.10));
    padding: 25px 20px;
    border-radius: 10px;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const Number = styled.div`
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    background-color: #41B4FF;
    border-radius: 100%;
    border: 3px solid #9FD9FF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    font-size: 40px;
    user-select: none;

    @media (max-width: 400px) {
        width: 72px;
        height: 72px;
        font-size: 36px;
    }
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: var(--text-dark-blue);
    max-width: 210px;

    @media (max-width: 400px) {
        max-width: 185px;
        font-size: 21px;
    }
`;

const Bottom = styled.div`
    margin-top: 10px;
`;

const Text = styled.span`
    font-size: 16px;
    line-height: 28px;
    color: #3E4462;

    @media (max-width: 400px) {
        max-width: 270px;
        font-size: 14px;
        line-height: 120%;
    }
`;
