import React from 'react'
import styled from 'styled-components'
import tick from '../../assets/img/tick_blue.svg';
import { CommonHelper } from '../../helpers/CommonHelper';
import { useDispatch } from 'react-redux';
import * as userActions from '../../redux/actions/UsersActions';

export const Notification = (props) => {
    const {
        id,
        header = 'Тема',
        text = 'Сообщение',
        read = false,
        date,
    } = props;

    const dispatch = useDispatch();

    return (
        <Wrapper>
            <TextPlaceholder>
                <Header>
                    {header == null ? 'Без темы' : header}
                    {read == true ? null :
                        <Unread />
                    }
                </Header>

                <Text>{text}</Text>
                
                <Date>{CommonHelper.dateAndTime(date)}</Date>
            </TextPlaceholder>

            {read == true ? null :
                <Read onClick={() => {
                    dispatch(userActions.readNotification(id));
                }}>
                    ОК
                </Read>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background 200ms;

    &:hover {
        background-color: #f8fbff;
    }
`;

const Unread = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--primary);
`;

const TextPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
`;

const Text = styled.span`
    font-weight: 400;
    font-size: 13px;
`;

const ButtonIcon = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid var(--primary);
    padding: 4px;
    cursor: pointer;
`;

const Read = styled.button`
    background-color: var(--primary);
    color: white;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    border: none;
    padding: 3px 10px;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }
`;

const Date = styled.span`
    font-size: 11px;
    font-weight: 300;
    color: #979797;
`;