import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const options = [
    {
        amount: '50',
        size: 'Мб',
        price: '2 000 ₽',
    },
    {
        amount: '100',
        size: 'Мб',
        price: '3 500 ₽',
    },
    {
        amount: '500',
        size: 'Мб',
        price: '9 000 ₽',
    },
    {
        amount: '1',
        size: 'гб',
        price: '15 000 ₽',
    },
]

export const SecondTariff = () => {
    const [radioId, setRadioId] = useState(Math.floor(Math.random() * options.length));

    return (
        <Wrapper>
            <Header>Емкость хранилища</Header>
            <OptionsPlaceholder>
                {options.map((item, id) => {
                    return (
                        <Option key={`second_tariff_${id}`}>
                            <OptionInner>
                                <Radio checked={radioId == id} onClick={() => {setRadioId(id)}}/>
                                <Number>{item.amount}</Number>
                                <Size>{item.size}</Size>
                            </OptionInner>

                            <Price>{item.price}</Price>
                        </Option>
                    )
                })}
            </OptionsPlaceholder>

            <Text>* Срок хранения 5 лет</Text>

            <Link to='/вход'>
                <Button>Выбрать тариф</Button>
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 360px;
    height: 600px;
    padding: 53px 41px;
    border-radius: 20px;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.10);

    @media (max-width: 768px) {
        width: 324px;
        height: 540px;
        padding: 47px 37px;
    }
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: var(--text-dark-blue);

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const OptionsPlaceholder = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Option = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const OptionInner = styled.div`
    display: flex;
    align-items: center;
`;

const Radio = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 100%;
    outline: rgba(70, 94, 111, 0.50) solid 1px;
    outline-offset: 4px;
    background-color: ${props => props.checked ? 'var(--primary)' : 'transparent'};
    cursor: pointer;
`;

const Number = styled.span`
    margin-left: 16px;
    font-size: 36px;
    font-weight: 700;
    color: var(--primary);

    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

const Size = styled.span`
    margin-left: 5px;
    font-size: 14px;
    color: var(--primary);

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const Price = styled.span`
    font-size: 24px;
    color: #465E6F;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Text = styled.div`
    margin-top: 27px;
    font-size: 14px;
    color: rgba(10, 27, 50, 0.50);
`;

const Button = styled.button`
    border: none;
    border-radius: 12px;
    width: 283px;
    height: 60px;
    color: var(--primary);
    background-color: #EAF3FF;
    font-size: 16px;
    font-weight: 700;
    margin-top: 82px;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-top: 40px;
        width: 255px;
        height: 54px;
    }
`;