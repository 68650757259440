import { API_ENDPOINT } from "../config";
import axios from "axios";
import $api from './axiosSettings';

const CertificateAPI = {
    async getCertificate(id) {
        try {
            let pld = await $api.get(`${API_ENDPOINT}/api/deposit/certificate?ridId=${id}`);
            return pld.data;
        } catch (error) {
            console.error('error: ', error);
        }
    }
};

export default CertificateAPI;