import styled from "styled-components";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { DepositSteps } from "../components/DepositSteps";
import { DepositObjects } from "../components/DepositObjects";
import background from "../assets/img/main_page_background_pattern.svg";
import backgroundTablet from "../assets/img/main_page_background_pattern_tablet.svg";
import backgroundPhone from "../assets/img/main_page_background_pattern_phone.svg";
import man from "../assets/img/man_landing.svg";
import video_audio_text from "../assets/img/video_audio_text.svg";
import dashboard_landing from "../assets/img/dashboard_landing.jpg";
import message from "../assets/img/message_icon.svg";
import bookmark from "../assets/img/bookmark_icon.svg";
import contact from "../assets/img/contact_icon.svg";
import paper from "../assets/img/mockup_paper_filled_small.png";
import text_icon from "../assets/img/text_icon_white.svg";
import code_icon from "../assets/img/code_icon_white.svg";
import technology_icon from "../assets/img/technology_icon.svg";
import mic_icon from "../assets/img/microphone_icon.svg";
import video_icon from "../assets/img/video_icon_white.svg";
import image_icon from "../assets/img/image_icon_white.svg";
import { FirstTariff } from "../components/tariffs/FirstTariff";
import { SecondTariff } from "../components/tariffs/SecondTariff";
import { ThirdTariff } from "../components/tariffs/ThirdTariff";
import { CCarousel, CCarouselItem } from "@coreui/react";
import '@coreui/coreui/dist/css/coreui.min.css';
import { useEffect } from "react";
import { ShortRegistry } from "../components/ShortRegistry";


const depositSteps = [
	{
		number: '01',
		header: 'Загрузка файла',
		text: 'Объект интеллектуальной собственности загружается в зашифрованном виде в защищенное хранилище, а информация о нем записывается в распределенный реестр.'
	},
	{
		number: '02',
		header: 'Фиксация в блокчейне',
		text: 'Объекту интеллектуальной собственности присваивается уникальный идентификатор (хэш-файла). Вся информация об объекте - прозрачна и неизменна.'
	},
	{
		number: '03',
		header: 'Свидетельство',
		text: 'Пользователь получает Свидетельство о депонировании с цифровой подписью, штампом даты и времени.'
	},
]

const depositObjects = [
	{
		image: image_icon,
		header: 'Изображение',
		items: ['Произведение искусства', 'Чертеж', 'Фотография']
	},
	{
		image: code_icon,
		header: 'Код',
		items: ['База данных', 'Программа для ЭВМ', 'Сайт']
	},
	{
		image: text_icon,
		header: 'Текст',
		items: ['Учебное пособие', 'Произведение науки', 'Произведение литературы']
	},
	{
		image: mic_icon,
		header: 'Звук',
		items: ['Фонограмма', 'Музыкальное произведение']
	},
	{
		image: video_icon,
		header: 'Видео',
		items: ['Видеокурс', 'Видеолекция', 'Видеоролик']
	},
];

export default function WelcomePage(props) {
    const {
        scrollTo = undefined,
    } = props;

    useEffect(() => {
        if (scrollTo != undefined) {
            const element = document.getElementById(scrollTo);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [window.location.href])

    return (
        <Wrapper id='welcome_page_top'>
			<CenterPanel>
                <FirstSection>
                    <FirstSectionInner>
						<CTA>
							<CTAText>Сервис цифрового депонирования</CTAText>
                            <CTAPic src={video_audio_text}></CTAPic>
                            <Link to='/вход'>
                                <CTAButton>
                                    депонировать объект авторского права
                                </CTAButton>
                            </Link>
						</CTA>
						<RightImg src={man} />
                    </FirstSectionInner>
				</FirstSection>

				{/* <SecondSection>
					<DashboardPic src={dashboard_landing} />
					<SecondSectionText>
						<SectionHeader>Ключевые функции</SectionHeader>
						<SectionSpan>Введите ваши личные данные, выберите тип объекта депонирования и загрузите файл в требуемом формате. Составьте максимально точное описание файла для внесения в Свидетельство о депонировании.</SectionSpan>
						<IconsPlaceholder>
							<IconDescription>
								<Icon src={message} />
								<IconDescriptionText style={{color: '#BB6BD9'}}>Свидетельство о депонировании</IconDescriptionText>
							</IconDescription>

							<IconDescription>
								<Icon src={bookmark} />
								<IconDescriptionText>Подтверждение авторского права</IconDescriptionText>
							</IconDescription>
							
							<IconDescription>
								<Icon src={contact} />
								<IconDescriptionText>Авторизованный доступ к копии файла</IconDescriptionText>
							</IconDescription>
						</IconsPlaceholder>
					</SecondSectionText>
				</SecondSection> */}

				<ThirdSection>
					<LeftPart>
						<SectionHeader>Что такое депонирование?</SectionHeader>
						<ThirdSectionSpan style={{width: '498px', marginTop: '16px'}}>Онлайн-услуга добровольной регистрации экземпляра произведения в цифровом реестре с выдачей свидетельства и хранения данного экземпляра для подтверждения даты депонирования в случае возникновения судебных споров.</ThirdSectionSpan>
                        <Link to='/вход'>
                            <ThirdSectionButton>депонировать объект авторского права</ThirdSectionButton>
                        </Link>
					</LeftPart>
					<Paper src={paper}/>
				</ThirdSection>

				<FourthSection>
					<SectionHeader>Как задепонировать файл</SectionHeader>
					<Steps>
						{depositSteps.map((item, id) => {
                            return (
                                <Step>
                                    <DepositSteps
                                        key={`deposit_steps_${id}`}
                                        number={item.number}
                                        header={item.header}
                                        text={item.text}
                                    />
                                </Step>
							)
						})}
                    </Steps>

                    <CCarouselCustom controls={window.innerWidth > 400} indicators dark interval={false}>
                        {depositSteps.map((item, id) => {
                            return (
                                <CCarouselItemCustom>
                                    <Step>
                                        <DepositSteps
                                            key={`${id}_deposit_steps`}
                                            number={item.number}
                                            header={item.header}
                                            text={item.text}
                                        />
                                    </Step>
                                </CCarouselItemCustom>
                            );
                        })}
                    </CCarouselCustom>
				</FourthSection>

				<FifthSection>
					<SectionHeader>Объекты депонирования</SectionHeader>
					<DepositObjectsPlaceholder>

						{depositObjects.map((item, id) => {
							return (
								<DepositObjects
									key={`deposit_object_${id}`}
									image={item.image}
									header={item.header}
									items={item.items}
								/>
							)
						})}
					
					</DepositObjectsPlaceholder>
                </FifthSection>
                
                <SixthSection>
					<SectionHeader>Реестр РИД</SectionHeader>
                    <ShortRegistry />
                </SixthSection>

				{/* <SixthSection id='tariffs_page'>
					<SectionHeader>Тарифы</SectionHeader>

                    <TariffPlaceholder>
                        <FirstTariff />
                        <SecondTariff />
                        <ThirdTariff />                        
                    </TariffPlaceholder>

                    <CCarouselTariff controls={window.innerWidth > 400} indicators dark interval={false}>
                            <CCarouselItemTariff>
						        <FirstTariff />
                            </CCarouselItemTariff>

                            <CCarouselItemTariff>
                                <SecondTariff />
                            </CCarouselItemTariff>

                            <CCarouselItemTariff>
                                <ThirdTariff />
                            </CCarouselItemTariff>
                        </CCarouselTariff>
				</SixthSection> */}
			</CenterPanel>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow: hidden;
`;

const CenterPanel = styled.div`
    max-width: 1440px;
    padding: 0 20px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        max-width: 768px;
    }
`;

const FirstSection = styled.section`
    width: 100%;
    max-width: 1380px;
    max-height: 769px;
    background-image: url(${props => props.$src});
    background-size: cover;
    box-sizing: border-box;
    margin-top: 20px;
    user-select: none;
    z-index: 0;
    display: block;
    border-radius: 10px;

    @media (max-width: 768px) {
        max-height: 408px;
        max-width: 732px;
        margin-top: 10px;
        background-image: url(${props => props.$tablet});
    }

    @media (max-width: 400px) {
        max-height: 625px;
        max-width: 335px;
        margin-top: 10px;
        background-image: url(${props => props.$phone});
    }
`;

const FirstSectionInner = styled.div`
    max-width: 100%;
    height: auto; 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100%, 769px);
    align-items: center;
    
    @media (max-width: 768px) {
        grid-auto-rows: minmax(100%, 408px);
    }

    @media (max-width: 400px) {
        padding: 20px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
`;

const CTA = styled.div`
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 100px;
    margin-left: 10px;
    z-index: 2;

    @media (device-max-width: 768px) {
        margin-bottom: 30px;
    }

    @media (max-width: 400px) {
        align-items: center;
        margin-bottom: 10px;
        margin-left: 0;
        justify-self: flex-end;
    }
`;

const CTAText = styled.span`
    max-width: 550px;
    color: var(--primary);
    font-size: 50px;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 32px;
    }

    @media (max-width: 768px) {
        max-width: 290px;
        font-size: 28px;
        text-align: center;
    }
`;

const CTAPic = styled.img`
    max-height: 111px;
    user-select: none;

    @media (max-width: 768px) {
        max-height: 72px;
    }
`;

const CTAButton = styled.button`
    box-sizing: border-box;
    max-width: 303px;
    max-height: 80px;
    width: fit-content;
    padding: 10px 30px;
    margin-top: 60px;
    border: none;
    border-radius: 8px;
    background-color: var(--primary);
    color: white;
    font-size: 20px;
    font-weight: 600;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 13px;
        margin-top: 20px;
    }
`;

const RightImg = styled.div`
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    max-width: 500px;
    max-height: 570px;
    width: 100%;
    height: 100%;
    z-index: 1;
    user-select: none;
    justify-self: center;

    @media (max-width: 768px) {
        max-height: 301px;
        max-width: 264px;
    }
`;

const SecondSection = styled.section`
    width: 100%;
    margin-top: 100px;
    margin-left: 80px;
    display: flex;
    gap: 30px;

    @media (max-width: 768px) {
        justify-content: center;
        margin-left: 0;
    }
`;

const SecondSectionText = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionHeader = styled.span`
    font-size: 48px;
    font-weight: 700;
    color: var(--text-dark-blue);
    z-index: 1;

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 400px) {
        font-size: 30px;
    }
`;

const SectionSpan = styled.span`
    max-width: 446px;
    margin-top: 7px;
    font-size: 20px;
    line-height: 150%;
    color: rgba(10, 27, 50, 0.50);
    z-index: 1;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const IconsPlaceholder = styled.div`
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    gap: 20px;
`;

const IconDescription = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const Icon = styled.img`
    width: 50px;
    height: 50px;
`;

const IconDescriptionText = styled.span`
    font-size: 20px;
    font-weight: 700;
    color: #18214D;
`;

const DashboardPic = styled.div`
    background-image: url(${props => props.src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 711px;
    width: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const ThirdSection = styled.section`
    max-width: 1200px;
    max-height: 512px;
    width: 100%;
    margin-top: 150px;
    display: flex;
    overflow: visible;

    @media (max-width: 400px) {
        margin-top: 100px;
    }
`;

const LeftPart = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;

    @media (max-width: 400px) {
        align-items: center;
    }
`;

const ThirdSectionSpan = styled.span`
    max-width: 498px;
    margin-top: 7px;
    font-size: 20px;
    line-height: 150%;
    color: rgba(10, 27, 50, 0.50);
    z-index: 1;

    @media (max-width: 768px) {
        font-size: 18px;
        max-width: 398px;
    }

    @media (max-width: 400px) {
        max-width: 340px;
        width: 100%;
    }
`;

const ThirdSectionButton = styled.button`
    width: 248px;
    height: 60px;
    background-color: #EAF3FF;
    border: none;
    border-radius: 12px;
    color: var(--primary);
    font-weight: 600;
    font-size: 16px;
    margin-top: 43px;
    z-index: 1;
`;

const Paper = styled.div`
    z-index: 0;
    width: 100%;
    height: 512px;
    background-color: transparent;
    
    &::after {
        position: absolute;
        height: 600px;
        width: 100%;
        content: '';
        background-image: url(${props => props.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top left;
    }

    @media (max-width: 400px) {
        display: none;
    }
`;

const FourthSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 250px;

    @media (max-width: 400px) {
        margin-top: 100px;
    }
`;

const Steps = styled.div`
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const Step = styled.div`
    width: 350px;
    height: 300px;

    @media (max-width: 400px) {
        width: 315px;
        height: 270px;
    }
`;

const CCarouselCustom = styled(CCarousel)`
    display: none;

    @media (max-width: 768px) {
        display: block;
        width: 450px;
        height: 400px;
    }

    @media (max-width: 400px) {
        width: 355px;
        height: 340px;
    }
`;

const CCarouselItemCustom = styled(CCarouselItem)`
    width: 450px;
    height: 370px;
    padding: 50px;

    @media (max-width: 400px) {
        width: 355px;
        height: 400px;
        padding: 20px;
    }
`;

const FifthSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 250px;
    margin-bottom: 150px;

    @media (max-width: 768px) {
        text-align: center;
    }

    @media (max-width: 400px) {
        margin-top: 100px;
    }
`;

const DepositObjectsPlaceholder = styled.div`
    max-width: 1138px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 85px;
    margin-top: 70px;
    
    @media (max-width: 768px) {
        gap: 50px;
    }
`;

const SixthSection = styled.section`
    margin-top: 50px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 400px) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
`;

const TariffPlaceholder = styled.div`
    margin-top: 60px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 60px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const CCarouselTariff = styled(CCarousel)`
    display: none;
    margin-top: 50px;

    @media (max-width: 768px) {
        display: block;
        width: 424px;
        height: 590px;
    }
`;

const CCarouselItemTariff = styled(CCarouselItem)`
    padding: 0 50px;

    @media (max-width: 768px) {
        width: 424px;
        height: 590px;
    }
`;