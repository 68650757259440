import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

export const DropdownItem = (props) => {
    const {
        onClick = () => {

        },
    } = props;

    return (
        <Wrapper onClick={onClick}>
            <Link to={props.link} style={{ textDecoration: 'none' }}>
                <Span>
                    {props.text}
                </Span>
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 500ms;

    &:hover {
        background-color: #EAF3FF;
    }
`;

const Span = styled.span`
    color: var(--primary);
    font-weight: 500;
`;