import React, { useState } from 'react'
import styled from 'styled-components'
import { CForm, CFormInput, CSpinner } from '@coreui/react'
import UsersAPI from '../helpers/api/UserAPI';
import toast from 'react-hot-toast';

export const ForgotPasswordPage = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>
            <CForm onSubmit={async (evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                setLoading(true);

                UsersAPI.forgotPassword(email).then(send => {
                    setLoading(false);
                    if (send) {
                        toast.success('Письмо для восстановления пароля отправлено на почту')
                    } else {
                        toast.error('Пользователь с таким email не зарегистрирован')
                    }
                }).catch(err => setLoading(false));
            }}>
                Введите email
                <CFormInputCustom
                    required
                    type='email'
                    value={email}
                    placeholder='email'
                    onChange={(evt) => {
                        setEmail(evt.target.value);
                    }}
                />
                <BlueButton>{loading ? <CSpinner size='sm' color='light' /> : 'Восстановить пароль'}</BlueButton>
            </CForm>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CFormInputCustom = styled(CFormInput)`
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const BlueButton = styled.button`
    margin-top: 20px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;