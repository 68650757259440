import React from 'react'
import styled from 'styled-components'

const contributionType = (type) => {
    if (type == undefined) {
        type = 'false';
    }

    type = type.toLowerCase() == 'true';

    let contributionTypes = [
        { label: 'Раздельное', value: false },
        { label: 'Нераздельное', value: true }
    ];

    return contributionTypes.filter(x => x.value == type)[0].label;
}

export const CoauthorsInfo = (props) => {
    const {
        data,
    } = props

    if (data.lastName == undefined) {
        return
    }

    return (
        <Wrapper>
            <Line>
                <Title>ФИО:</Title>
                <Value>{data?.lastName} {data?.firstName} {data?.secondName}</Value>
            </Line>
            <Line>
                <Title>Дата рождения:</Title>
                <Value>{data?.dateBorn}</Value>
            </Line>
            <Line>
                <Title>ИНН:</Title>
                <Value>{data?.inn}</Value>
            </Line>
            <Line>
                <Title>Место жительства:</Title>
                <Value>{data?.address}</Value>
            </Line>
            <Line>
                <Title>Гражданство:</Title>
                <Value>{data?.citizenship}</Value>
            </Line>
            <Line>
                <Title>Вид соавторства:</Title>
                <Value>{contributionType(data?.contributionType)}</Value>
            </Line>
            <Line>
                <Title>Вклад соавтора:</Title>
                <Contribution>{data?.contribution}</Contribution>
            </Line>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;

const Line = styled.div`
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
`;

const Title = styled.span`
    font-weight: 500;
    margin-right: 10px;
`;

const Value = styled.span`
    text-align: right;
`;

const Contribution = styled.p`
    margin: 0;
    text-align: right;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
`;
