import React from 'react'
import styled from 'styled-components'
import { CommonHelper } from '../helpers/CommonHelper';

const getYearsInRussian = (years) => {
    switch (years) {
        case 1:
            return 'год'

        case 2:
        case 3:
        case 4:
            return 'года'
        default:
            return 'лет';
    }
}

const getName = (name) => {
    if (name == undefined) {
        return '';
    }

    if (name.secondName == undefined) {
        return name.lastName + ' ' + name.firstName;
    }

    return name.lastName + ' ' + name.firstName + ' ' + name.secondName;
}

export const DepositedFileInfo = (props) => {
    const {
        fileInfo
    } = props

    return (
        <Wrapper>
            <Row>
                <PropertyName>Название</PropertyName>
                <FileName>{fileInfo?.ridTitle}</FileName>
            </Row>

            <Row>
                <PropertyName>Тип</PropertyName>
                <FileName>{fileInfo?.typeName}</FileName>
            </Row>

            <Row>
                <PropertyName>Подтип</PropertyName>
                <FileName>{fileInfo?.subTypeName}</FileName>
            </Row>
           
            <Row>
                <PropertyName>Хеш файла</PropertyName>
                <FileName>{fileInfo?.hashFile}</FileName>
            </Row>

            <Row>
                <PropertyName>Автор(-ы)</PropertyName>
                <FileName>
                    {getName(fileInfo?.authorId)}
                    {fileInfo?.idsCoauthor?.map((coauthor, id) => {
                        return (
                            <FileName>, {getName(coauthor)}</FileName>
                        )
                    })}
                </FileName>
            </Row>

            <Row>
                <PropertyName>Правообладатель</PropertyName>
                <FileName>{fileInfo?.rightholder?.short_name ? fileInfo?.rightholder.short_name : getName(fileInfo?.rightholder)}</FileName>
            </Row>

            <Row>
                <PropertyName>Основания возникновения права на регистрируемый объект авторского права</PropertyName>
                <FileName>{fileInfo?.baseToRight}</FileName>
            </Row>

            <Row>
                <PropertyName>Дата депонирования</PropertyName>
                <FileName>{CommonHelper.correctDate(fileInfo?.creationDate)}</FileName>
            </Row>
            
            <Row>
                <PropertyName>Период депонирования</PropertyName>
                <FileName>{fileInfo?.periodDepon} {getYearsInRussian(fileInfo?.periodDepon)}</FileName>
            </Row>

            <Row>
                <PropertyName>Номер свидетельства</PropertyName>
                <FileName>{fileInfo?.certificateNumber}</FileName>
            </Row>

            <Row>
                <PropertyName>Был опубликован в реестре РИД</PropertyName>
                <FileName>{fileInfo?.statusPubl ? 'Да' : 'Нет'}</FileName>
            </Row>

            <EssayRow>
                <PropertyName>Реферат</PropertyName>
                <Essay>{fileInfo?.ridDescription}</Essay>
            </EssayRow>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const FileName = styled.span`
    max-width: 250px;
    word-break: break-word;
    text-align: right;
`;

const PropertyName = styled.span`
    font-weight: 600;
    max-width: 250px;
`;

const EssayRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const Essay = styled.span`
    width: 100%;
    word-break: break-word;
`;