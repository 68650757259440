import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CModal, CModalBody, CForm, CFormInput, CModalHeader, CFormCheck, CFormLabel, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { IMaskMixin } from 'react-imask';
import { Link, Navigate } from 'react-router-dom';
import UsersAPI from '../helpers/api/UserAPI';
import { CommonHelper } from '../helpers/CommonHelper';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../redux/actions/UsersActions.js';

const checkNames = ['firstName', 'secondName', 'lastName']

export const Register = (props) => {

    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.users.currentUser);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);

    const [hasSecondName, setHasSecondName] = useState(true);
    const [password, setPassword] = useState('');
    const [data, setData] = useState({});
    const phoneRef = useRef(null);

    const [validated, setValidated] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEmailUsed, setIsEmailUsed] = useState(false);
    const [isPhoneUsed, setIsPhoneUsed] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidNames, setInvalidNames] = useState([]);
    const [agreed, setAgreed] = useState(false);
    const [newsAgreed, setNewsAgreed] = useState(false);
    const [testPassword, setTestPassword] = useState('');
    const [samePasswords, setSamePasswords] = useState(false);

    let canRegister = !(invalidPassword || isPhoneUsed || isEmailUsed || samePasswords == false);

    const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
        <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
    ));

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        let isEmailCorrect = CommonHelper.checkEmail(data.email);
        let correctNames = true;
        let incorrectNames = [];
        for (let name of checkNames) {
            if (name == 'secondName' && data.secondName == '' && hasSecondName == false) {
                continue;
            }

            let current = CommonHelper.checkName(data[name]);

            if (current == false) {
                correctNames = false;
                incorrectNames.push(name);
            }
        }

        if (isEmailCorrect && correctNames) {
            setInvalidNames([]);
            let firstName = CommonHelper.capitalizeFirstLetter(data.firstName.trim());
            let secondName = CommonHelper.capitalizeFirstLetter(data.secondName.trim());
            let lastName = CommonHelper.capitalizeFirstLetter(data.lastName.trim());
            let userAgent = window.navigator.userAgent;
            let emailIsUsed = await UsersAPI.isEmailAlreadyUsed(data.email);
            let phoneIsUsed = await UsersAPI.isPhoneAlreadyUsed(data.phone);

            if (emailIsUsed || phoneIsUsed) {
                if (emailIsUsed) {
                    setIsEmailUsed(true);
                }
                if (phoneIsUsed) {
                    setIsPhoneUsed(true);
                }
                return;
            }

            await dispatch(userActions.signUp({
                ...data,
                firstName,
                secondName,
                lastName,
                userAgent,
                news: newsAgreed
            }))
        }
        if (isEmailCorrect == false) {
            setIsEmailValid(false);
        }
        if (correctNames == false) {
            setInvalidNames(incorrectNames);
        }

        setValidated(true);
    }

    useEffect(() => {
        if (error) {
            setData(error);
            dispatch(userActions.tsuRegister());
        }
    }, [error])

    if (currentUser != undefined) {
        return (
            <Navigate to='/аккаунт' />
        )
    }

    return (
        <Wrapper>

            <CFormCustom
                onSubmit={handleSubmit}
            >
                <Header>Регистрация</Header>
                <CFormLabelCustom>Фамилия</CFormLabelCustom>
                <CFormInputCustom
                    required
                    placeholder="Фамилия"
                    value={data.lastName || ''}
                    invalid={invalidNames.includes('lastName')}
                    onChange={(evt) => {
                        let text = evt.target.value;
                        if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                return;
                        }
                        setData({
                            ...data,
                            lastName: text
                        })
                    }}
                />
                {invalidNames.includes('lastName') == false ? null : 
                    <FeedbackInvalid>Фамилия должна содержать только кириллицу</FeedbackInvalid>
                }

                <CFormLabelCustom>Имя</CFormLabelCustom>
                <CFormInputCustom
                    required
                    placeholder="Имя"
                    value={data.firstName || ''}
                    invalid={invalidNames.includes('firstName')}
                    onChange={(evt) => {
                        let text = evt.target.value;
                        if (text.match(/[^А-Яа-яёЁ]+/g)) {
                            return;
                        }
                        setData({
                            ...data,
                            firstName: text
                        });
                    }}
                />
                {invalidNames.includes('firstName') == false ? null : 
                    <FeedbackInvalid>Имя должно содержать только кириллицу</FeedbackInvalid>
                }
                
                <CFormLabelCustom>Отчество</CFormLabelCustom>
                <CFormInputCustom
                    required={hasSecondName}
                    placeholder="Отчество"
                    value={data.secondName || ''}
                    disabled={!hasSecondName}
                    invalid={invalidNames.includes('secondName') && hasSecondName}
                    onChange={(evt) => {
                        let text = evt.target.value;
                        if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                return;
                        }
                        setData({
                            ...data,
                            secondName: text
                        });
                    }}
                />
                {(invalidNames.includes('secondName') && hasSecondName) == false ? null : 
                    <FeedbackInvalid>Отчество должно содержать только кириллицу</FeedbackInvalid>
                }
                
                <HasSecondName>
                    <CFormCheckCustom
                        validated={false}
                        id='register_patronymic'
                        style={{cursor: 'pointer'}}
                        checked={!hasSecondName}
                        onChange={(evt) => {
                            evt.stopPropagation();
                            let newState = !hasSecondName;
                            setHasSecondName(newState);
                            setData({
                                ...data,
                                secondName: newState == false ? '' : data.secondName,
                                hasSecondName: newState,
                            });
                        }}
                    />
                    <CFormLabelCheck htmlFor='register_patronymic'>Отчество отсутствует</CFormLabelCheck>
                </HasSecondName>
                
                <CFormLabelCustom>Электронная почта</CFormLabelCustom>
                <CFormInputCustom
                    required
                    type="email"
                    placeholder="example@email.com"
                    value={data.email || ''}
                    invalid={isEmailValid == false}
                    onChange={(evt) => {
                        let text = evt.target.value;
                        if (text.match(/[А-Яа-яёЁ]+/g)) {
                            return;
                        }
                        setIsEmailUsed(false);
                        setData({
                            ...data,
                            email: text
                        });
                    }}
                />
                {isEmailValid == true ? null : 
                    <FeedbackInvalid>Введена неверная электронная почта</FeedbackInvalid>
                }
                {isEmailUsed == false ? null : 
                    <FeedbackInvalid>Аккаунт с такой почтой уже существует</FeedbackInvalid>
                }
                
                <CFormLabelCustom>Номер телефона</CFormLabelCustom>
                <CFormInputWithMask
                    required
                    mask="+{7} (000) 000-00-00"
                    lazy={false}
                    inputRef={phoneRef}
                    unmask={true}
                    defaultValue={data?.phone}
                    onComplete={(value, mask) => {
                        setIsPhoneUsed(false);
                        setData({
                            ...data,
                            phone: value
                        });
                    }}
                />
                {isPhoneUsed == false ? null : 
                    <FeedbackInvalid>Номер телефона уже привязан к другому аккаунту</FeedbackInvalid>
                }
                
                <CFormLabelCustom>Пароль <span style={{opacity: '0.8', fontWeight: '400'}}>(не менее  6 символов, без пробелов)</span></CFormLabelCustom>
                {invalidPassword == false ? null :
                    <FeedbackInvalidList>
                        <ListItem>Пароль должен состоять из букв латинского алфавита, цифр и спец. символов.</ListItem>
                        <ListItem>Пароль должен содержать хотя бы один специальный символ: {`. , : ; ? ! * + % - <> @ () [] {} / _ $ #`}</ListItem>
                    </FeedbackInvalidList>
                }
                <CFormInputCustom
                    required
                    type="password"
                    placeholder="Пароль"
                    invalid={invalidPassword}
                    value={password}
                    onChange={(evt) => {
                        let text = evt.target.value;
                        setPassword(text);
                        if (CommonHelper.checkPassword(text)) {
                            setInvalidPassword(false);
                            setData({
                                ...data,
                                password: text
                            });
                        } else {
                            setInvalidPassword(true);
                        }
                    }}
                />
                {invalidPassword == true || password.length == 0 ? null :
                    <RepeatPassword>
                        <CFormLabelCustom>Введите пароль еще раз</CFormLabelCustom>
                        {samePasswords == true || password.length == 0 || testPassword.length == 0 ? null :
                            <FeedbackInvalid>Пароли не совпадают</FeedbackInvalid>
                        }
                        <CFormInputCustom
                            required
                            type="password"
                            placeholder="Пароль"
                            value={testPassword}
                            onChange={(evt) => {
                                let text = evt.target.value;
                                setTestPassword(text);
                                if (password != text) {
                                    setSamePasswords(false);
                                } else {
                                    setSamePasswords(true);
                                }
                            }}
                        />
                    </RepeatPassword>
                }

                <BlueButton
                    disabled={!canRegister}
                    onSubmit={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                    }}
                >
                    {loading ? <CSpinner color='light' size='sm'/> : 'Зарегистрироваться'}
                </BlueButton>

                <AgreeCheck>
                    <CFormCheckCustom
                        required
                        style={{cursor: 'pointer'}}
                        id='agree_check'
                        checked={agreed}
                        onChange={() => setAgreed(!agreed)}
                    />
                    <CFormLabelCheck htmlFor='agree_check'>
                        Я принимаю условия&nbsp;
                        <Link to='/'>
                            <Span>
                                пользовательского соглашения
                            </Span>
                        </Link>
                        &nbsp;и даю&nbsp;
                        <Link to='/'>
                            <Span>
                                согласие на обработку персональных данных
                            </Span>
                        </Link>
                    </CFormLabelCheck>
                </AgreeCheck>

                <AgreeCheck>
                    <CFormCheckCustom
                        style={{cursor: 'pointer'}}
                        id='news_check'
                        checked={newsAgreed}
                        onChange={() => setNewsAgreed(!newsAgreed)}
                    />
                    <CFormLabelCheck htmlFor='news_check'>
                        Согласен на получение рекламной рассылки
                    </CFormLabelCheck>
                </AgreeCheck>

                <HaveAccount>
                    Уже есть аккаунт?&nbsp;
                    <Link to='/вход'>
                        <Span>Войти</Span>
                    </Link>
                </HaveAccount>
            </CFormCustom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Header = styled.p`
    font-size: 24px;
    font-weight: 600;
    color: #555555;
    margin: 0;
`;

const CFormInputCustom = styled(CFormInput)`
    margin-bottom: -5px;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
`;

const CFormLabelCheck = styled(CFormLabel)`
    cursor: pointer;
    margin: 0;
`;

const HasSecondName = styled.div`
    display: flex;
    gap: 10px;
    align-self: flex-start;
    margin-bottom: -5px;
    cursor: pointer;

    :focus, :active {
        box-shadow: none;
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    cursor: pointer;
`;

const CFormCustom = styled(CForm)`
    width: 350px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media (max-width: 400px) {
        width: 270px;
    }
`;

const BlueButton = styled.button`
    margin-top: 10px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        background-color: grey;
        cursor: default;
    }
`;

const HaveAccount = styled.p`
    cursor: default;
    font-size: 14px;
    font-weight: 500;
`;

const Span = styled.span`
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const FeedbackInvalid = styled.p`
    color: #e55353;
    font-size: 13px;
    margin: 0;
`;

const FeedbackInvalidList = styled.ul`
    list-style: none;
    margin: 0;
`;

const ListItem = styled.li`
    color: #e55353;
    font-size: 13px;
`;

const AgreeCheck = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    font-size: 13px;

    :focus, :active {
        box-shadow: none !important;
    }
`;

const RepeatPassword = styled.div`
    width: 350px;
    
    @media (max-width: 400px) {
        width: 270px;
    }
`;