import * as types from '../ActionTypes.js';
import UsersAPI from '../../helpers/api/UserAPI';
import { CommonHelper } from '../../helpers/CommonHelper.js';

//login
let startLoggingIn = () => {
    return {
        type: types.LOGIN
    }
};

let onLoginSuccess = (user) => {
    if (user) {
        user = {...user, dateBorn: CommonHelper.correctDate(user.dateBorn)};
    }
    return {
        type: types.LOGIN_SUCCESS,
        user: user
    };
};

let onLoginFail = (error) => {
    return {
        type: types.LOGIN_FAIL,
        error: error
    }
};

export function logIn(data) {
    return (dispatch, getState) => {
        dispatch(startLoggingIn());
        return UsersAPI.loginUser(data).then(
            user => dispatch(onLoginSuccess(user)),
            error => dispatch(onLoginFail(error))
        )
    }
}

//sign up
let startSigningUp = () => {
    return {
        type: types.SIGNUP,
        loading: true
    }
};

let onSignUpSuccess = (user) => {
    if (user) {
        user = {...user, dateBorn: CommonHelper.correctDate(user.dateBorn)};
    }
    return {
        type: types.SIGNUP_SUCCESS,
        user: user,
        loading: false
    };
};

let onSignUpFail = (error) => {
    return {
        type: types.SIGNUP_FAIL,
        error: error,
        loading: false,
    };
};

export function signUp(data) {
    return (dispatch, getState) => {
        dispatch(startSigningUp());
        return UsersAPI.addUser(data).then(
            user => dispatch(onSignUpSuccess(user)),
            error => dispatch(onSignUpFail(error))
        )
    }
}

//auth
let startAuth = () => {
    return {
        type: types.INITIALIZE_AUTH,
        authentication: true
    };
};

let authSuccess = (user) => {
    if (user) {
        user = {...user, dateBorn: CommonHelper.correctDate(user?.dateBorn)};
    }
    return {
        type: types.INITIALIZE_AUTH_SUCCESS,
        user: user,
        authentication: false,
    };
};

let authFailed = (err) => {
    return {
        type: types.INITIALIZE_AUTH_FAIL,
        error: err,
        authentication: false,
    };
};

export function initAuth() {
    return (dispatch, getState) => {
        dispatch(startAuth());
        return UsersAPI.auth().then(
            user => dispatch(authSuccess(user)),
            err => dispatch(authFailed(err))
        );
    }
};

//logout

let startLogout = () => {
    return {
        type: types.LOGOUT,
        loading: true,
    }
};

let logoutSuccess = () => {
    return {
        type: types.LOGOUT_SUCCESS,
        loading: false,
        user: undefined,
        notifications: [],
    }
}

let logoutFailed = (err) => {
    return {
        type: types.LOGOUT_FAIL,
        loading: false,
        error: err
    };
};

export function logout() {
    return (dispatch, getState) => {
        dispatch(startLogout());
        return UsersAPI.logout().then(
            user => dispatch(logoutSuccess(user)),
            err => dispatch(logoutFailed(err))
        );
    }
}

//update user
let startUpdating = () => {
    return {
        type: types.UPDATE_USER,
        loading: true,
    }
};

let updateSuccess = (user) => {
    if (user) {
        user = {...user, dateBorn: CommonHelper.correctDate(user?.dateBorn)};
    }
    return {
        type: types.UPDATE_USER_SUCCESS,
        loading: false,
        user: user
    }
}

let updateFailed = (err) => {
    return {
        type: types.UPDATE_USER_FAIL,
        loading: false,
        error: err
    };
};

export function updateUser(data) {
    return (dispatch, getState) => {
        dispatch(startUpdating());
        return UsersAPI.updateUser(data).then(
            user => dispatch(updateSuccess(user)),
            err => dispatch(updateFailed(err))
        );
    }
}

//tsu login
let startTsuLogin = () => {
    return {
        type: types.INITIALIZE_TSU_LOGIN,
        authentication: true,
    }
};

let tsuAuthSuccess = (user) => {
    if (user?.registered == false) {
        return {
            type: types.INITIALIZE_TSU_LOGIN_FAIL,
            authentication: false,
            error: user
        }
    }
    
    return {
        type: types.INITIALIZE_TSU_LOGIN_SUCCESS,
        authentication: false,
        user: {...user, dateBorn: CommonHelper.correctDate(user?.dateBorn)}
    }
}

let tsuAuthFailed = (err) => {
    return {
        type: types.INITIALIZE_TSU_LOGIN_FAIL,
        authentication: false,
        error: err
    };
};

export function tsuLogin(data) {
    return (dispatch, getState) => {
        dispatch(startTsuLogin());
        return UsersAPI.tsuAuth(data).then(
            user => dispatch(tsuAuthSuccess(user)),
            err => dispatch(tsuAuthFailed(err))
        );
    }
}

let startTsuRegister = () => {
    return {
        type: types.INITIALIZE_TSU_SIGNUP,
        error: undefined,
    }
}

export function tsuRegister() {
    return (dispatch, getState) => {
        dispatch(startTsuRegister());
    }
}

let startGetNotifications = () => {
    return {
        type: types.GET_NOTIFICATIONS
    }
};

let onGetNotificationsSuccess = (notifications) => {
    return {
        type: types.GET_NOTIFICATIONS_SUCCESS,
        notifications: notifications
    };
};

let onGetNotificationsFail = (error) => {
    return {
        type: types.GET_NOTIFICATIONS_FAIL,
        error: error
    }
};

export function getNotifications() {
    return (dispatch, getState) => {
        dispatch(startGetNotifications());
        return UsersAPI.getNotifications().then(
            notifications => dispatch(onGetNotificationsSuccess(notifications)),
            error => dispatch(onGetNotificationsFail(error))
        )
    }
}

let readNotif = (notifications) => {
    return {
        type: types.READ_NOTIFICATION,
        notifications: notifications
    }
};

export function readNotification(notificationId) {
    return (dispatch, getState) => {
        return UsersAPI.readNotification(notificationId).then(
            () => {
                let notifications = JSON.parse(JSON.stringify(getState().users.notifications));
                let newNotifications = [];
                for (let notification of notifications) {
                    if (notification.ID == notificationId) {
                        let changed = notification;
                        changed.is_read = true;
                        newNotifications.push(changed);
                    } else {
                        newNotifications.push(notification);
                    }
                }
                dispatch(readNotif(newNotifications));
            },
        )
    }
}