import React, { useEffect } from "react";
import RouterApp from "./route/RouterApp";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from './redux/actions/UsersActions.js';
import { CSpinner } from "@coreui/react";
import styled from "styled-components";

export default function App() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.users.authentication);
    const token = localStorage.getItem('accessToken');
    
    useEffect(() => {
        if (token != undefined) {
            dispatch(userActions.initAuth());
        }
    }, []);

    if (loading) {
        return (
            <Center>
                <CSpinner/>
            </Center>
        )
    }

    return (
        <BrowserRouter>
            <RouterApp/>
        </BrowserRouter>
    );
}

const Center = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;