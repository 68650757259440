import React from 'react'
import styled from 'styled-components'
import logo from "../assets/img/tsu_patent_logo_white.svg"
import yt from "../assets/img/youtube_icon.svg"
import vk from "../assets/img/vk_icon.png"
import tg from "../assets/img/telegram_icon.svg"
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <Wrapper>
            <Inner>
                <LeftPart>
                    <Logo src={logo} />
                    <MenuPlaceholder>
                        <Menu>
                            <MenuItem style={{ fontWeight: '600' }}>Меню</MenuItem>
                            <Link to='/' style={{textDecoration: 'none'}}>
                                <MenuItem>Главная</MenuItem>
                            </Link>
                            <MenuItem>О нас</MenuItem>
                            <Link to='/проверка' style={{textDecoration: 'none'}}>
                                <MenuItem>Проверка свидетельства</MenuItem>
                            </Link>
                            <Link to='/реестр' style={{textDecoration: 'none'}}>
                                <MenuItem>Реестр РИД</MenuItem>
                            </Link>
                        </Menu>

                        <Menu style={{ width: '80px' }}>
                            <Doc href={'/documents/privacy_policy.pdf'} rel='noreferrer' target='_blank'>
                                <MenuItem>Политика конфиденциальности</MenuItem>
                            </Doc>
                            <Doc href={'/documents/terms_and_conditions.pdf'} rel='noreferrer' target='_blank'>
                                <MenuItem>Пользовательское соглашение</MenuItem>
                            </Doc>
                        </Menu>
                    </MenuPlaceholder>
                </LeftPart>    

                <RightPart>
                    <Rights>© Все права защищены. НИ ТГУ</Rights>
                    <Social>
                        <Icon src={yt}/>
                        <Icon src={tg}/>
                        <Icon src={vk}/>
                    </Social>
                </RightPart>
            </Inner>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    width: 100%;
    height: 250px;
    background-color: #5F7787;
    color: white;
    display: flex;
    justify-content: center;
    
    @media (max-width: 400px) {
        height: 450px;
    }
`;

const Doc = styled.a`
    text-decoration: none;
`;

const Inner = styled.div`
    max-width: 1200px;
    width: 100%;
    padding-top: 54px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 768px) {
        max-width: 670px;
        padding: 24px;
        align-items: flex-start;
        gap: 40px;
        justify-content: flex-start;
    }

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: center;
    }
`;

const LeftPart = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 3;

    @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

    @media (max-width: 400px) {
        align-items: center;
        gap: 10px;
    }
`;

const Logo = styled.img`

    @media (max-width: 768px) {
        width: 190px;
    }
`;

const MenuPlaceholder = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-grow: 2;

    @media (max-width: 768px) {
        flex-grow: 0;
        width: 100%;
        justify-content: space-between;
    }

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;

const MenuItem = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: white;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

const RightPart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    flex-grow: 1;

    @media (max-width: 768px) {
        height: 100%;
        justify-content: space-between;
    }

    @media (max-width: 400px) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 10px;
    }
`;

const Rights = styled.span`
    font-weight: 300;
    font-size: 15px;
    text-align: right;
    color: white;
    width: 188px;

    @media (max-width: 400px) {
        width: 100%;
    }
`;

const Social = styled.div`
    display: flex;
    gap: 10px;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;