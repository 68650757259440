import React, { useState } from 'react'
import styled from 'styled-components'
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import { CForm, CFormInput, CFormLabel, CFormTextarea } from '@coreui/react';
import { IMaskMixin } from 'react-imask';
import EmailAPI from '../helpers/api/EmailAPI';
import toast from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
));

export const ContactsPage = () => {

    const [data, setData] = useState({})
    const [captcha, setCaptcha] = useState(undefined);

    return (
        <Wrapper>
            <Info>
                <LeftPart onSubmit={async (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    
                    await EmailAPI.sendContacts(data);
                    toast.success('Письмо отправлено');
                }}>
                    <Header>Контакты<br/></Header>
                    <Text><b>Адрес: </b>г. Томск, ул. Ленина, 36</Text>

                    <Header><br/>Предложение сотрудничества</Header>
                    <CFormCustom>
                        <CFormLabel>ФИО</CFormLabel>
                        <CFormInputCustom
                            placeholder='ФИО'
                            required
                            value={data.name || ''}
                            onChange={evt => {
                                if (evt.target.value.match(/[^А-Яа-яёЁ]/g) != null) {
                                    return
                                }
                                setData({
                                    ...data,
                                    name: evt.target.value
                                })
                            }}
                        />

                        <CFormLabel>Телефон</CFormLabel>
                        <CFormInputWithMask
                            required
                            mask="+{7} (000) 000-00-00"
                            lazy={false}
                            unmask={true}
                            defaultValue={data?.phone}
                            onComplete={(value, mask) => {
                                setData({
                                    ...data,
                                    phone: value
                                });
                            }}
                        />

                        <CFormLabel>Почта</CFormLabel>
                        <CFormInputCustom
                            required
                            placeholder='Почта'
                            type='email'
                            value={data?.email}
                            onChange={evt => {
                                if (evt.target.value.match(/[А-Яа-яёЁ]+/g) != null) {
                                    return
                                }
                                setData({
                                    ...data,
                                    email: evt.target.value
                                })
                            }}
                        />

                        <CFormLabel>Ваш вопрос</CFormLabel>
                        <CFormTextareaCustom
                            required
                            placeholder='Ваш вопрос'
                            value={data.text || ''}
                            onChange={evt => {
                                setData({
                                    ...data,
                                    text: evt.target.value
                                })
                            }}
                        />

                        <ReCAPTCHA
                            style={{marginTop: '10px'}}
                            sitekey={'6LeT7DQpAAAAAPvjApEbXm-y3FnE3lbIpKSB2_lP'}
                            onChange={evt => setCaptcha(evt)}
                        />

                        <BlueButton disabled={!captcha}>Отправить</BlueButton>
                    </CFormCustom>
                </LeftPart>

                <RightPart>
                    <YMaps>
                        <Map
                            width={window.innerWidth > 600 ? 500 : window.innerWidth - 20}
                            height={window.innerWidth > 600 ? 500 : window.innerWidth - 20}
                            defaultState={{ center: [56.469463, 84.947568], zoom: 15 }}
                        >
                            <Placemark geometry={[56.469463, 84.947568]} />
                            <ZoomControl options={{ float: "right" }} />
                        </Map>
                    </YMaps>
                </RightPart>
            </Info>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: auto;
    padding: 50px 0;
    max-width: 1200px;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        padding: 20px 0;
    }
`;

const Header = styled.span`
    font-size: 28px;
    font-weight: 600;
    color: var(--text-dark-blue);
`;

const Info = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;

const LeftPart = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 20px;
`;

const Text = styled.span`
    
`;

const RightPart = styled.div`
    padding: 20px;

    * {
        border-radius: 10px;
    }
`;

const CFormCustom = styled(CForm)`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;

    label {
        margin: 0;
    }
`;

const CFormInputCustom = styled(CFormInput)`

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormTextareaCustom = styled(CFormTextarea)`
    
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const BlueButton = styled.button`
    margin-top: 10px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: ${props => props.disabled ? 'lightgrey' : 'var(--primary)'};
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;