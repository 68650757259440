import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import logo from '../assets/img/tsu_patent_logo_blue.svg'
import burger from '../assets/img/menu_burger.svg'
import { SidebarMenu } from './SidebarMenu';
import { Link } from 'react-router-dom';
import avatar from '../assets/img/user_icon.svg';
import shevron from '../assets/img/shevron.svg';
import noNotifications from '../assets/img/no_notifications.svg';
import hasNotifications from '../assets/img/has_notifications.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from './DropdownItem';
import * as userActions from '../redux/actions/UsersActions.js';
import { API_ENDPOINT } from '../helpers/config.js';
import { Notification } from './notifications/Notification.js';
import UsersAPI from '../helpers/api/UserAPI.js';

export const Header = (props) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const [menuOpen, setMenuOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const menuRef = useRef();
    const notificationsRef = useRef();
    const menuTimer = useRef();
    
    const dispatch = useDispatch();
    const { currentUser, notifications } = useSelector(state => state.users);

    const closeMenu = (evt) => {
        if (menuOpen && menuRef.current && !menuRef.current.contains(evt.target)) {
            setMenuOpen(false);
        }

        if (notificationsOpen && notificationsRef.current && !notificationsRef.current.contains(evt.target)) {
            setNotificationsOpen(false);
        }
    }

    document.addEventListener('mousedown', closeMenu);

    const avatarSrc = currentUser && currentUser.photo ? `${API_ENDPOINT}/api/user/avatar/` + currentUser.photo : undefined;

    useEffect(() => {
        if (notifications.length == 0) {
            dispatch(userActions.getNotifications());
        }
    }, [])

    return (
        <Wrapper>
            <HeaderPlaceholder>
                <Link to="/">
                    <Logo src={logo} alt="logo" />
                </Link>
                
                <CenterPlaceholder>
                    <MenuLink>О нас</MenuLink>
                    <MenuLink
                        onClick={() => {
                            setTimeout(() => {
                                setDropdownVisible(true);
                            }, 200);
                        }}
                        onMouseEnter={() => {
                            setTimeout(() => {
                                setDropdownVisible(true);
                            }, 200)
                        }}
                        onMouseLeave={() => {
                            menuTimer.current = setTimeout(() => {
                                setDropdownVisible(false);
                            }, 200);
                        }}
                    >
                        Услуги
                        <Shevron src={shevron} />
                    </MenuLink>
                    {dropdownVisible == false ? null :
                        <DropdownMenu
                            onMouseEnter={() => {
                                clearTimeout(menuTimer.current);
                                // setDropdownVisible(true);
                            }}
                            onMouseLeave={() =>
                                menuTimer.current = setTimeout(() => {
                                    setDropdownVisible(false)
                                }, 200)
                            }
                        >
                            <Link to='/депонирование' style={{textDecoration: 'none'}}>
                                <DropdownMenuItem onClick={() => setDropdownVisible(false)}>
                                    Депонирование
                                </DropdownMenuItem>
                            </Link>
                            
                            <Link to='/консультации' style={{textDecoration: 'none'}}>
                                <DropdownMenuItem>Консультации</DropdownMenuItem>
                            </Link>
                            
                            <Link to='/реестр' style={{ textDecoration: 'none' }}>
                                <DropdownMenuItem>Реестр РИД</DropdownMenuItem>
                            </Link>

                            <Link to='/проверка' style={{textDecoration: 'none'}}>
                                <DropdownMenuItem onClick={() => setDropdownVisible(false)}>
                                    Проверка свидетельства
                                </DropdownMenuItem>
                            </Link>
                            <DropdownMenuItem>Авторизованный доступ к копии файла</DropdownMenuItem>
                        </DropdownMenu>
                    }
                    <MenuLink>Правовая информация</MenuLink>

                    <Link to='/контакты' style={{textDecoration: 'none'}}>
                        <MenuLink>Контакты</MenuLink>
                    </Link>
                </CenterPlaceholder>

                <RightPlaceholder>
                    <Burger src={burger} onClick={() => {
                        setSidebarVisible(!sidebarVisible);
                    }} />
                    {currentUser == undefined ?
                        <LoginPlaceholder>
                            <Link to='/вход'>
                                <Login>Войти</Login>
                            </Link>
                            <Link to='/регистрация'>
                                <Register>Регистрация</Register>
                            </Link>
                        </LoginPlaceholder>
                        :
                        <UserPlaceholder>
                            <div ref={menuRef}>
                                <Profile onClick={() => setMenuOpen(!menuOpen)}>
                                    <Name>{currentUser.lastName} {currentUser.firstName}</Name>
                                    <Icon src={avatarSrc || avatar} />
                                </Profile>
                                {menuOpen == false ? null :
                                    <Dropdown>
                                        <DropdownItem text={'Профиль'} link={'/аккаунт'} onClick={() => setMenuOpen(false)} />
                                        <DropdownItem text={'Выйти'} onClick={() => {
                                            dispatch(userActions.logout());
                                        }} />
                                    </Dropdown>
                                }
                            </div>
                            <div ref={notificationsRef}>
                                <NotificationsIcon
                                    src={notifications.filter(x => x?.is_read == false).length > 0 ? hasNotifications : noNotifications}
                                    onClick={() => setNotificationsOpen(!notificationsOpen)}
                                />
                                {notificationsOpen == false ? null : 
                                    <NotificationsPlaceholder>
                                        {notifications.length == 0 ?
                                            <div>Нет уведомлений</div>
                                            :
                                            <>
                                                {notifications.map((notification, id) => {
                                                    return (
                                                        <Notification
                                                            key={`notification_${id}`}
                                                            id={notification?.ID}
                                                            date={notification?.date}
                                                            header={notification?.theme}
                                                            text={notification?.bodynotif}
                                                            read={notification?.is_read}
                                                        />
                                                    )
                                                })}
                                            </>
                                        }
                                    </NotificationsPlaceholder>
                                }
                            </div>
                        </UserPlaceholder>
                        }
                </RightPlaceholder>
                    
            </HeaderPlaceholder>

            {sidebarVisible == false ? null :
                <Sidebar>
                    <SidebarMenu
                        currentUser={currentUser}
                        toggleMenu={() => setSidebarVisible(!sidebarVisible)}
                    />
                </Sidebar>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    /* pointer-events: none; */
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
`;

const HeaderPlaceholder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    height: inherit;
    background-color: white;
    pointer-events: all;

    @media (max-width: 400px) {
        padding: 0 20px;
    }
`;

const Logo = styled.img`
    cursor: pointer;
    user-select: none;

    @media (max-width: 400px) {
        max-width: 217px;
    }
`;

const CenterPlaceholder = styled.nav`
    display: flex;
    gap: 40px;
    margin: 0 20px;

    :hover {
        opacity: 0.7;
    }

    @media (max-width: 1100px) {
        display: none;
    }
`;

const Shevron = styled.img`
    margin-left: 5px;
`;

const MenuLink = styled.button`
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    color: var(--primary);
`;

const DropdownMenu = styled.div`
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 60px;
    right: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);

    &:hover {
        opacity: 1;
    }

    :last-child {
        border-radius: 10px;
    }
`;

const DropdownMenuItem = styled.div`
    max-width: 200px;
    padding: 10px 0px;
    background-color: white;
    color: var(--primary);
    cursor: pointer;

    :visited {
        color: var(--primary);
    }
`;

const Burger = styled.button`
    display: none;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    margin-left: 20px;

    @media (max-width: 1100px) {
        display: block;
        cursor: pointer;
    }
`;

const Sidebar = styled.div`
    width: 400px;
    pointer-events: all;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
`;

const LoginPlaceholder = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    :hover {
        opacity: 0.7;
    }
`;

const Login = styled.button`
    border: none;
    background-color: transparent;
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
    font-size: 15px;

    @media (max-width: 640px) {
        display: none;
    }
`;

const Register = styled.button`
    border: 1px solid var(--primary);
    border-radius: 4px;
    padding: 12px 16px;
    background-color: transparent;
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
    font-size: 15px;

    @media (max-width: 640px) {
        display: none;
    }
`;

const UserPlaceholder = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;

    @media (max-width: 700px) {
        display: none;
    }
`;

const Icon = styled.img`
    border-radius: 15px;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;
`;

const NotificationsIcon = styled.img`
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;
`;

const Name = styled.span`
    color: var(--primary);
    margin-right: -5px;
    font-weight: 600;
    cursor: pointer;
`;

const Profile = styled.div`

`;

const Dropdown = styled.div`
    position: absolute;
    top: 65px;
    right: 100px;
    width: 200px;
    background-color: white;
    border: 1px solid var(--border-light);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const NotificationsPlaceholder = styled.div`
    position: absolute;
    top: 65px;
    right: 60px;
    width: 350px;
    max-height: 250px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid var(--border-light);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &::-webkit-scrollbar {
        width: 7px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        width: 7px;
        background-color: white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(165, 165, 165);
        width: 7px;
        border-radius: 10px;
        /* border-radius: 10px; */
    }
`;

const RightPlaceholder = styled.div`
    display: flex;
    align-items: center;
`;