import React, { useState } from 'react'
import styled from 'styled-components';
import { CForm, CFormInput, CSpinner } from '@coreui/react'
import logo from '../assets/img/tsu_patent_logo_blue.svg'
import UsersAPI from '../helpers/api/UserAPI';
import toast from 'react-hot-toast'

export const ResetPasswordPage = () => {
    const encodedEmail = window.location.search.replace(/\?code=/, '');

    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>
            <Form>
                <Logo src={logo} />
                <CForm onSubmit={async (evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    setLoading(true);
                    UsersAPI.updateForgotPassword(encodedEmail, password).then(x => {
                        setLoading(false);
                        toast.success('Пароль обновлен');
                        setTimeout(() => {
                            window.location.href = window.location.origin + '/вход';
                        }, 1000)
                    });
                }}>
                    <b>Введите новый пароль</b>
                    <CFormInputCustom
                        placeholder='Новый пароль'
                        onChange={(evt) => {
                            setPassword(evt.target.value);
                        }}
                    />
                    <BlueButton type='submit'>
                        {loading ? <CSpinner size='sm' color='light' /> : 'Изменить'}
                    </BlueButton>
                </CForm>
            </Form>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    margin-bottom: 15px;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
`;

const CFormInputCustom = styled(CFormInput)`
    width: 100%;

    &:focus, &:active {
        box-shadow: none;
        border-color: var(--border-light);
    }
`; 

const BlueButton = styled.button`
    margin-top: 10px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;