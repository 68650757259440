import React, { useEffect, useState } from 'react'
import styled from'styled-components'
import DepositAPI from '../helpers/api/DepositAPI'
import { RegistryTable } from '../components/RegistryTable'
import { CSpinner, CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import { Pagination } from '../components/Pagination';
import { Link } from 'react-router-dom';

export const RegistryPage = () => {

    const [data, setData] = useState(undefined)
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setLoading(true);
        DepositAPI.getRegister()
            .then(res => {
                // res = res.sort((a, b) => a.creationDate > b.creationDate ? 1 : -1)
                setData(res);
            })
            .catch(err => {
                console.log(err);
            })
        DepositAPI.getRegistryCount().then(x => {
                setTotalPages(Math.ceil(x.count / 10));
                setLoading(false);
            }).catch(err => {
                setLoading(false)
            })
    }, [])

    if (data == undefined && loading) {
        return (
            <Wrapper>
                <CSpinner style={{marginTop: 50}} />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <TopPlaceholder>
                <CBreadcrumb>
                    <CBreadcrumbItemCustom>
                        <Link to='/' style={{textDecoration: 'none'}}>
                            Главная
                        </Link>
                    </CBreadcrumbItemCustom>
                    <CBreadcrumbItemCustom active>Реестр РИД</CBreadcrumbItemCustom>
                </CBreadcrumb>
            </TopPlaceholder>

            <Table>
                {data == undefined ?
                    <div>Нет данных</div>
                    :
                    <RegistryTable currentPage={currentPage} data={data} />
                }
            </Table>
            
            {data == undefined ? null :
                <PaginationPlaceholder>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={async (page) => {
                            let newData = await DepositAPI.getRegister(page, 10);
                            // newData = newData.sort((a, b) => a.creationDate > b.creationDate ? 1 : -1)
                            setCurrentPage(page);
                            setData(newData);
                        }}
                    />
                </PaginationPlaceholder>
            }

        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TopPlaceholder = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const Header = styled.h1`
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
`;

const Table = styled.div`
    margin-bottom: 50px;
`;

const PaginationPlaceholder = styled.div`
    margin-top: -40px;
    margin-bottom: 40px;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;