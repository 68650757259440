import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Navigate, Link } from 'react-router-dom'
import AdminAPI from '../helpers/api/AdminAPI';
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";

const getData = async () => {
    let data = {};

    let pld = await AdminAPI.getUsersCount();
    data.users = pld;
    
    pld = await AdminAPI.getRidCount();
    data.rids = pld;

    return data;
}

export const AdminStatsPage = () => {
    const currentUser = useSelector(state => state.users.currentUser);

    const [data, setData] = useState(undefined);

    useEffect(() => {

        getData().then(x => {
            setData(x);
        })
    }, [])

    if (currentUser?.userType != 4) {
        return <Navigate to='/'/>
    }

    return (
        <Wrapper>
            <CBreadcrumb>
                <CBreadcrumbItemCustom>
                    <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                        Личный кабинет администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom>
                    <Link to='/админ' style={{textDecoration: 'none'}}>
                        Панель администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom active>Статистика</CBreadcrumbItemCustom>
            </CBreadcrumb>

            <Stats>
                <Row>
                    Пользователей зарегистрировано: {data?.users?.all?.count}
                </Row>
                
                <Row>
                    Пользователей, которые подтвердили почту: {data?.users?.confirmed?.count}
                </Row>

                <Row>
                    Пользователей, которые привязали ТГУ.Аккаунт: {data?.users?.tsu?.count}
                </Row>
                
                <Row>
                    Всего РИД: {data?.rids?.all?.count}
                </Row>
            </Stats>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
`;

const Stats = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Row = styled.div`
    font-size: 18px;
    font-weight: 500;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;