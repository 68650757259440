import * as types from '../ActionTypes.js';

const initialState = {
    depositedFiles: [],
    loading: false,
    error: undefined,
};

const startLoading = (state, action) => {
    return { ...state, loading: true, error: undefined }
};

const stopLoading = (state, action) => {
    return { ...state, loading: false, error: action.error };
};

const FilesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.LOAD_DEPOSITED_FILES:
        case types.DEPOSIT_FILE:
            return startLoading(state, action);

        case types.LOAD_DEPOSITED_FILES_SUCCESS:
            return {
                ...state,
                depositedFiles: action?.files,
                loading: false
            }
        case types.LOAD_DEPOSITED_FILES_FAIL:
        case types.DEPOSIT_FILE_FAIL:
            return stopLoading(state, action);
        
        case types.DEPOSIT_FILE_SUCCESS:
            return {
                ...state,
                depositedFiles: [...state.depositedFiles, action?.file],
                loading: false,
            }
            
        default:
            return state
    }
};

export default FilesReducer;