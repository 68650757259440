import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { File } from '../components/File.js';
import { CBreadcrumb, CBreadcrumbItem, CSpinner } from '@coreui/react';
import { Link } from 'react-router-dom';
import plus from '../assets/img/add.svg';
import { useDispatch, useSelector } from'react-redux';
import * as filesActions from '../redux/actions/FilesActions.js';
import { Modal } from '../components/Modal.js';
import { CommonHelper } from '../helpers/CommonHelper.js';
import { DepositedFileInfo } from '../components/DepositedFileInfo.js';
import { getRoleName } from './ProfilePage.js';

const temp = []
for (let i = 0; i < 10; i++) {
    temp.push(i);
}

export const FilesPage = () => {
    const dispatch = useDispatch();
    const files = useSelector((state) => state.files.depositedFiles);
    const loading = useSelector((state) => state.files.loading);
    const currentUser = useSelector(state => state.users.currentUser);

    const [fileInfo, setFileInfo] = useState(undefined);

    useEffect(() => {
        dispatch(filesActions.loadDepositedFiles());
    }, [])

    return (
        <Wrapper>
            <TopPlaceholder>
                <CBreadcrumb>
                        <CBreadcrumbItemCustom>
                            <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                                Личный кабинет {getRoleName(currentUser.userType)}
                            </Link>
                        </CBreadcrumbItemCustom>
                    <CBreadcrumbItemCustom active>Мои свидетельства</CBreadcrumbItemCustom>
                </CBreadcrumb>

                {/* <Link to='/депонирование'>
                    <DepositFileButton>
                        Загрузить файл
                        <PlusIcon src={plus} />
                    </DepositFileButton>
                </Link> */}
            </TopPlaceholder>
            
            {loading == false ? null :
                <Loading>
                    <CSpinner color='dark'/>
                </Loading>
            }

            {loading || files == undefined || files.length == 0 ? null :
                <FilesPlaceholder>
                    {files.map((file, key) => {
                        return (
                            <File
                                id={file.ridId}
                                uploadDate={CommonHelper.correctDate(file?.creationDate)}
                                fileType={file?.ridFile}
                                type={file?.typeName}
                                key={`file_key_${key}`}
                                fileName={file?.ridTitle}
                                confirmed={file?.isActual}
                                onInfoClick={() => { 
                                    setFileInfo(file);
                                }}
                            />
                        );
                    }
                    )}
                </FilesPlaceholder>
            }

            {fileInfo == undefined ? null :
                <Modal
                    header={'Информация о депонировании'}
                    visible={fileInfo != undefined}
                    onClose={() => {
                        setFileInfo(undefined);
                    }}
                >
                    {/* TODO: put into a separate component */}
                    <FileModalInner>
                        <DepositedFileInfo fileInfo={fileInfo} />
                    </FileModalInner>
                </Modal>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: auto;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
`;

const TopPlaceholder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;

const FilesPlaceholder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
`;

const DepositFileButton = styled.button`
    border: none;
    border-radius: 10px;
    background-color: var(--button-light);
    color: var(--primary);
    font-size: 20px;
    font-weight: 600;
    padding: 15px 30px;
`;

const PlusIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 10px;
`;

const FileModalInner = styled.div`
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Loading = styled.div`
    margin: auto;
    margin-top: 50px;
`;