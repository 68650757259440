import React from 'react'
import styled from 'styled-components'

export const DepositObjects = (props) => {
    const {
        image,
        header,
        items = []
    } = props;

    return (
        <Wrapper>
            <ImagePlaceholder>
                <img src={image}/>
            </ImagePlaceholder>

            <Header>{header}</Header>

            <Ul>
                {items.map((item, id) => {
                        return (
                            <Li key={id}>{item}</Li>
                        )
                    }
                )}
            </Ul>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 320px;
    height: 220px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.10));
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: var(--text-dark-blue);
`;

const ImagePlaceholder = styled.div`
    width: 64px;
    height: 64px;
    background-color: #41B4FF;
    border: 3px solid #9FD9FF;
    border-radius: 10px;
    position: absolute;
    right: 22px;
    top: -21px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Ul = styled.ul`
    padding-left: 18px;
    margin-top: 20px;

    li:not(:last-child) { 
        margin-bottom: 13px;
    }
`;

const Li = styled.li`
    font-size: 18;
    color: rgba(10, 27, 50, 0.50);
`;