import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tsu from '../assets/img/tsu_img.jpg';
import logo from '../assets/img/tsu_patent_logo_blue.svg';
import { CForm, CFormInput, CFormLabel, CSpinner } from '@coreui/react'
import { Link, Navigate, redirect } from 'react-router-dom';
import { CommonHelper } from '../helpers/CommonHelper';
import UsersAPI from '../helpers/api/UserAPI';
import { useDispatch, useSelector } from 'react-redux';
import * as usersActions from '../redux/actions/UsersActions.js';
import { Spinner } from '@blueprintjs/core';

export const LoginPage = (props) => {

    const currentUser = useSelector((state) => state.users.currentUser);
    const loading = useSelector((state) => state.users.loading);

    const dispatch = useDispatch();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [invalidData, setInvalidData] = useState(false);

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        let isEmailCorrect = CommonHelper.checkEmail(email);
        if (isEmailCorrect) {
            setIsEmailValid(true);
            await dispatch(usersActions.logIn({ email, password }));
            if (currentUser == undefined) {
                setInvalidData(true);
            }
        } else {
            setIsEmailValid(false);
        }
    } 

    if (currentUser != undefined) {
        return (
            <Navigate to='/аккаунт' />
        )
    }

    return (
        <Wrapper>
            <LeftPart>
                <Link to='/'>
                    <Logo src={logo} />
                </Link>

                <CFormCustom onSubmit={async (evt) => {
                    setInvalidData(false);
                    await handleSubmit(evt);
                }}>
                    <Header>Вход</Header>

                    <FeedbackInvalid $hidden={!invalidData}>Неверный email или пароль</FeedbackInvalid>

                    <CFormLabelCustom>Электронная почта</CFormLabelCustom>
                    <CFormInputCustom
                        type="email"
                        placeholder="example@mail.com"
                        value={email}
                        invalid={!isEmailValid}
                        onChange={(evt) => {
                            setEmail(evt.target.value);
                        }}
                    />
                    {isEmailValid == true ? null :
                        <FeedbackInvalid $hidden={isEmailValid}>Введена неверная электронная почта</FeedbackInvalid>
                    }

                    <CFormLabelCustom>Пароль</CFormLabelCustom>
                    <CFormInputCustom
                        type="password"
                        value={password}
                        onChange={(evt) => {
                            setPassword(evt.target.value);
                        }}
                    />
                    <ForgotPassword onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();


                        window.location.href = window.location.origin + '/сбросПароля';
                        // return (
                        //     <Navigate to='/сбросПароля' />
                        // )
                    }}>
                        Забыли пароль?
                    </ForgotPassword>
                    
                    <BlueButton onClick={(evt) => {
                        // UsersAPI.loginUser({ email: email, password: password });
                    }}>
                        {loading ?
                            <CSpinner size='sm' color='light'/>
                            :
                            'Войти'
                        }
                    </BlueButton>
                </CFormCustom>
  
                <BlueBorderButton onClick={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    let redirectUrl = encodeURI('https://депонирую.рф/тгу');
                    window.location.href=`https://accounts.tsu.ru/Account/Login2/?applicationId=1060&&redirectUri=${redirectUrl}`
                }}
                >
                    Войти через ТГУ.Аккаунт
                </BlueBorderButton>

                <DontHaveAccount>
                    Нет аккаунта?&nbsp;
                    <Link to='/регистрация'>
                        <Span>Зарегестрироваться</Span>
                    </Link>
                </DontHaveAccount>
            </LeftPart>
            <RightPart>
                <TSU $src={tsu} />
            </RightPart>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`;

const LeftPart = styled.div`
    flex-basis: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-basis: 100%;
    }
`;

const Logo = styled.img`
    height: 70px;

    @media (max-width: 400px) {
        height: 50px;
    }
`;

const Header = styled.span`
    font-size: 24px;
    font-weight: 600;
    color: #555555;
`;

const CFormCustom = styled(CForm)`
    width: 350px;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    @media (max-width: 400px) {
        width: 270px;
    }
`;

const CFormLabelCustom = styled(CFormLabel)`
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
`;

const CFormInputCustom = styled(CFormInput)`
    width: 100%;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const ForgotPassword = styled.button`
    border: none;
    background-color: transparent;
    align-self: flex-end;
    margin-top: -5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover { 
        opacity: 0.7;
    }
`;

const DontHaveAccount = styled.p`
    margin-top: 10px;
    cursor: default;
    font-size: 14px;
    font-weight: 500;
`;

const Span = styled.span`
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const RightPart = styled.div`
    flex-basis: 50%;
    height: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

const TSU = styled.div`
    background-image: url(${props => props.$src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
`;

const BlueButton = styled.button`
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;

const BlueBorderButton = styled.button`
    width: 350px;
    height: fit-content;
    padding: 10px 40px;
    margin-top: 10px;
    border: 1px solid var(--primary);
    background-color: white;
    border-radius: 5px;
    color: var(--primary);
    font-size: 18px;
    font-weight: 600;
    
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 400px) {
        font-size: 16px;
        padding: 10px 25px;
        width: 270px;
    }
`;

const FeedbackInvalid = styled.p`
    font-size: 13px;
    margin: 0;
    margin-top: -10px;

    user-select: ${props => props.$hidden ? 'none' : 'default'};
    color: ${props => props.$hidden ? 'white' : '#e55353'};
`;