import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal';
import { CFormLabel, CFormInput, CTooltip, CFormCheck } from '@coreui/react'
import info_icon from '../../assets/img/info.svg';
import { IMaskMixin } from 'react-imask';
import countries from '../../countriesList';
import Select from 'react-select';
import AdminAPI from '../../helpers/api/AdminAPI';
import UsersAPI from '../../helpers/api/UserAPI';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
));

const getCurrentCitizenship = (citizenship) => {
    if (citizenship) {
        return countries.find(c => c.label == citizenship)
    }
    return undefined;
}

const userRoles = [
    {
        type: 1,
        name: 'Автор'
    },
    {
        type: 2,
        name: 'Правообладатель'
    },
    {
        type: 3,
        name: 'Представитель правообладателя'
    },
    {
        type: 4,
        name: 'Администратор'
    },
]

const getUserRoleName = (role) => {
    let roleName = userRoles.filter(x => x.type == role);
    if (roleName.length == 0) {
        return role;
    }
    return roleName[0].name;
}

export const User = (props) => {
    const {
        data,
        bold = false,
        onChange = () => {

        }
    } = props;

    const [changeValue, setChangeValue] = useState(undefined);
    const [changeName, setChangeName] = useState(undefined);

    return (
        <Wrapper>
            <ID style={{fontWeight: bold ? 'bold' : ''}}>{data?.ID}</ID>
            <Name
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeName({
                        lname: data?.lname,
                        sname: data?.sname,
                        fname: data?.fname,
                    })
                }}
            >
                {data?.lname} {data?.fname} {data?.sname}
            </Name>

            <Name
                style={{ fontWeight: bold ? 'bold' : '', wordBreak: 'break-all' }}
                onClick={() => {
                    setChangeValue({
                        type: 'Email',
                        property: 'email',
                        value: data?.email
                    })
                }}
            >
                {data?.email}
            </Name>

            <Text
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeValue({
                        type: 'Телефон',
                        property: 'phone',
                        value: data?.phone
                    })
                }}
            >
                {bold || data.phone == undefined ? '' : '+'}{data.phone}
            </Text>

            <Text
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeValue({
                        type: 'Гражданство',
                        property: 'citizenship',
                        value: data?.citizenship
                    })
                }}
            >
                {data?.citizenship}
            </Text>

            <Text
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeValue({
                        type: 'Адрес',
                        property: 'address',
                        value: data?.address
                    })
                }}
            >
                {data?.address}
            </Text>

            <Text
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeValue({
                        type: 'ИНН',
                        property: `"INN"`,
                        value: data?.INN
                    })
                }}
            >
                {data?.INN}
            </Text>

            {bold ? 
                <News>Новости</News>
                :
                <News>
                    <Checkbox
                        type='checkbox'
                        checked={data?.news}
                        onChange={async () => {
                            let pld = await AdminAPI.changeUserProperty(data?.ID, 'news', !data?.news);
                            onChange();
                        }}
                    />    
                </News>
            }

            <Role
                style={{ fontWeight: bold ? 'bold' : '' }}
                onClick={() => {
                    setChangeValue({
                        type: 'Роль',
                        property: 'type',
                        value: data?.type
                    })
                }}
            >
                {getUserRoleName(data?.type)}
            </Role>

            {changeName == undefined || bold == true ? null :
                <Modal
                    visible={changeName}
                    onClose={() => setChangeName(undefined)}
                    header={'Изменить ФИО'}
                >
                    <ChangeName>
                        <Row>
                            <CFormLabelCustom>Фамилия</CFormLabelCustom>
                            <CFormInputCustom
                                value={changeName?.lname}
                                onChange={(evt) => {
                                    let text = evt.target.value;
                                    if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                        return;
                                    }
                                    setChangeName({ ...changeName, lname: text });
                                }}
                            />
                        </Row>
                        <Row>
                            <CFormLabelCustom>Имя</CFormLabelCustom>
                            <CFormInputCustom
                                value={changeName?.fname}
                                onChange={(evt) => {
                                    let text = evt.target.value;
                                    if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                        return;
                                    }
                                    setChangeName({ ...changeName, fname: text });
                                }}
                            />
                        </Row>
                        <Row>
                            <CFormLabelCustom>
                                Отчество
                                <CTooltip content="Оставьте пустым, если отсутствует">
                                    <Info src={info_icon} />
                                </CTooltip>
                            </CFormLabelCustom>
                            <CFormInputCustom
                                value={changeName?.sname}
                                onChange={(evt) => {
                                    let text = evt.target.value;
                                    if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                        return;
                                    }
                                    setChangeName({ ...changeName, sname: text });
                                }}
                            />
                        </Row>

                        <BlueButton
                            onClick={async () => {
                                if (data?.lname != changeName.lname) {
                                    await AdminAPI.changeUserProperty(data?.ID, 'lname', changeName.lname);
                                }
                                if (data?.fname != changeName.fname) {
                                    await AdminAPI.changeUserProperty(data?.ID, 'fname', changeName.fname);
                                }
                                if (data?.sname != changeName.sname) {
                                    await AdminAPI.changeUserProperty(data?.ID, 'sname', changeName.sname);
                                }
                                setChangeName(undefined);
                                onChange();
                            }}
                        >
                            Сохранить
                        </BlueButton>
                    </ChangeName>
                </Modal>
            }

            {changeValue == undefined || bold == true ? null :
                <Modal
                    header={changeValue.type}
                    visible={changeValue}
                    onClose={() => setChangeValue(undefined)}
                >
                    <ChangeValue $tall={changeValue.type == 'Гражданство'}>
                        <Row>
                        <CFormLabelCustom>{changeValue.type}</CFormLabelCustom>
                        {changeValue.type == 'Телефон' ? 
                            <CFormInputWithMask
                                mask="+{7} (000) 000-00-00"
                                lazy={false}
                                unmask={true}
                                defaultValue={changeValue?.value}
                                onComplete={(value, mask) => {
                                    setChangeValue({
                                        ...changeValue,
                                        value: value
                                    });
                                }}
                            />
                            : changeValue.type == 'ИНН' ?
                                <CFormInputWithMask
                                    required
                                    id='author_inn'
                                    placeholder='ИНН'
                                    mask='0000000000[00]'
                                    value={changeValue?.value}
                                    onComplete={(value, mask) => {
                                        setChangeValue({
                                            ...changeValue,
                                            value: value
                                        });
                                    }}
                                />
                                : changeValue.type == 'Гражданство' ?
                                    <Select
                                        value={getCurrentCitizenship(changeValue?.value) || ''}
                                        isClearable={true}
                                        options={countries}
                                        placeholder={'Гражданство'}
                                        maxMenuHeight={120}
                                        noOptionsMessage={() => 'Нет результатов'}
                                        onChange={(option, type) => {
                                            if (type.action == 'clear') {
                                                setChangeValue({
                                                    ...changeValue,
                                                    value: ''
                                                });
                                            } else {
                                                setChangeValue({
                                                    ...changeValue,
                                                    value: option.label
                                                })
                                            }
                                        }}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                borderColor: 'var(--border-light)',
                                                cursor: 'pointer',
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            })
                                        }}
                                        on
                                    /> 
                                        : changeValue.type == 'Роль' ?
                                            <Roles>
                                                {userRoles.map((role, id) => {
                                                    return (
                                                        <div>
                                                            <CFormCheckCustom
                                                                id={`role_${id}`}    
                                                                checked={role.type == changeValue.value}
                                                                onChange={(evt) => {
                                                                    setChangeValue({
                                                                        ...changeValue,
                                                                        value: role.type
                                                                    })
                                                                }}
                                                            />
                                                            <CFormLabelCheck
                                                                htmlFor={`role_${id}`}
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                {role.name}
                                                            </CFormLabelCheck>    
                                                        </div>
                                                    )
                                                })}
                                            </Roles>
                                            :
                                            <CFormInputCustom
                                                value={changeValue.value}
                                                onChange={(evt) => {
                                                    setChangeValue({
                                                        ...changeValue,
                                                        value: evt.target.value
                                                    })
                                                }}
                                            />
                            }
                            </Row>
                        <BlueButton
                            onClick={async () => {
                                if (changeValue.property == 'email') {
                                    let isUsed = await UsersAPI.isEmailAlreadyUsed(changeValue.value);
                                    if (isUsed == false) {
                                        let pld = await AdminAPI.changeUserProperty(data?.ID, changeValue.property, changeValue.value);
                                    }
                                } else {
                                    let pld = await AdminAPI.changeUserProperty(data?.ID, changeValue.property, changeValue.value);
                                }
                                onChange();
                                setChangeValue(undefined);
                            }}
                        >
                            Сохранить
                        </BlueButton>
                    </ChangeValue>
                </Modal>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    gap: 15px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid var(--border-light);
`;

const ID = styled.div`
    max-width: 30px;
    width: 100%;
    height: 100%;
    font-weight: 600;
`;

const Name = styled.div`
    max-width: 230px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const Text = styled.div`
    max-width: 140px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const News = styled.div`
    font-weight: bold;
    max-width: 60px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Checkbox = styled.input`
    cursor: pointer;
`;

const Role = styled.div`
    max-width: 100px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const ChangeName = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ChangeValue = styled.div`
    width: 420px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: ${props => props.$tall ? '200px' : ''};
`;

const Row = styled.div`
    width: 400px;
`;

const CFormLabelCustom = styled(CFormLabel)`
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
`;

const CFormInputCustom = styled(CFormInput)`
    margin-bottom: -5px;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
    cursor: pointer;
`;

const CFormLabelCheck = styled(CFormLabel)`
    cursor: pointer;
    margin: 0;
`;

const BlueButton = styled.button`
    margin-top: 5px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;

const Info = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 5px;
`;

const Roles = styled.div`
    display: flex;
    flex-direction: column;
`;