import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';

export const AdminChangeDocsPage = () => {
    
    const currentUser = useSelector(state => state.users.currentUser);

    useEffect(() => {

    }, [])

    useEffect(() => {

    }, [])

    if (currentUser?.userType != 4) {
        return <Navigate to='/'/>
    }

    return (
        <Wrapper>
            <CBreadcrumb>
                <CBreadcrumbItemCustom>
                    <Link to='/аккаунт' style={{textDecoration: 'none'}}>
                        Личный кабинет администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom>
                    <Link to='/админ' style={{textDecoration: 'none'}}>
                        Панель администратора
                    </Link>
                </CBreadcrumbItemCustom>
                <CBreadcrumbItemCustom active>Изменить документы</CBreadcrumbItemCustom>
            </CBreadcrumb>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 1300px;
    margin: auto;
    margin-top: 50px;
`;

const CBreadcrumbItemCustom = styled(CBreadcrumbItem)`
    color: var(--text-dark-blue);
    font-weight: 600;
    font-size: 20px;

    a {
        color: inherit;
    }
`;