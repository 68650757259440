import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CModal, CModalBody, CForm, CFormInput, CModalHeader, CFormCheck, CFormLabel, CSpinner } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import { IMaskMixin } from 'react-imask';
import { BlueButton } from '../BlueButton';
import UsersAPI from '../../helpers/api/UserAPI';
import Select from 'react-select';
import countries from '../../countriesList';

const getCurrentCitizenship = (citizenship) => {
    if (citizenship) {
        return countries.find(c => c.label == citizenship)
    }
    return undefined;
}

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}}/>
));

export const RegisterModal = (props) => {
    const {
        loading = false,
        onSubmit = (data) => {

        },
    } = props;

    const [hasPatronymic, setHasPatronymic] = useState(true);
    const [data, setData] = useState({});

    return (
        <Wrapper>

            <CFormCustom
                onSubmit={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    onSubmit(data);
                }}
            >
                <Row>
                    <CFormLabelCustom>Фамилия</CFormLabelCustom>
                    <CFormInputCustom
                        required
                        placeholder="Фамилия"
                        value={data.lastName || ''}
                        onChange={(evt) => {
                            let text = evt.target.value;
                            if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                    return;
                            }
                            setData({
                                ...data,
                                lastName: text
                            })
                        }}
                    />
                </Row>

                <Row>
                    <CFormLabelCustom>Имя</CFormLabelCustom>
                    <CFormInputCustom
                        required
                        placeholder="Имя"
                        value={data.firstName || ''}
                        onChange={(evt) => {
                            let text = evt.target.value;
                            if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                    return;
                            }
                            setData({
                                ...data,
                                firstName: text
                            });
                        }}
                    />
                </Row>
                <Row>
                    <CFormLabelCustom>Отчество</CFormLabelCustom>
                    <CFormInputCustom
                        placeholder="Отчество"
                        value={data.secondName || ''}
                        disabled={!hasPatronymic}
                        onChange={(evt) => {
                            let text = evt.target.value;
                            if (text.match(/[^А-Яа-яёЁ]+/g)) {
                                    return;
                            }
                            setData({
                                ...data,
                                secondName: text,
                                hasPatronymic: true
                            });
                        }}
                    />
                    <HasPatronymic>
                        <CFormCheckCustom
                            id='register_patronymic'
                            style={{cursor: 'pointer'}}
                            checked={!hasPatronymic}
                            onChange={(evt) => {
                                evt.stopPropagation();
                                let newState = !hasPatronymic;
                                setHasPatronymic(newState);

                                if (newState == false) {
                                    setData({
                                        ...data,
                                        hasPatronymic: newState,
                                        secondName: ''
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        hasPatronymic: newState,
                                    });
                                }
                            }}
                        />
                        <CFormLabel htmlFor='register_patronymic' style={{cursor: 'pointer'}}>Отчество отсутствует</CFormLabel>
                    </HasPatronymic>
                </Row>

                <Row>
                    <CFormLabelCustom>Почта</CFormLabelCustom>
                    <CFormInputCustom
                        required
                        type="email"
                        placeholder="example@email.com"
                        value={data.email || ''}
                        onChange={(evt) => {
                            let text = evt.target.value;
                            setData({
                                ...data,
                                email: text
                            });
                        }}
                    />
                </Row>

                <Row>
                    <CFormLabelCustom>Гражданство</CFormLabelCustom>
                    <SelectPlaceholder>
                        <Select
                            required
                            value={getCurrentCitizenship(data?.citizenship) || ''}
                            isClearable={true}
                            options={countries}
                            placeholder={'Гражданство'}
                            maxMenuHeight={120}
                            noOptionsMessage={() => 'Нет результатов'}
                            onChange={(option, type) => {
                                if (type.action == 'clear') {
                                    setData({
                                        ...data,
                                        citizenship: ''
                                    });
                                } else {
                                    setData({
                                        ...data,
                                        citizenship: option.label
                                    })
                                }
                            }}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    borderColor: 'var(--border-light)',
                                    cursor: 'pointer',
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    cursor: 'pointer',
                                })
                            }}
                            on
                        />
                    </SelectPlaceholder>
                </Row>

                <Row>
                    <CFormLabelCustom>Телефон</CFormLabelCustom>
                    <CFormInputWithMask
                        required
                        mask="+{7} (000) 000-00-00"
                        lazy={false}
                        unmask={true}
                        defaultValue={data?.phone}
                        onComplete={(value, mask) => {
                            setData({
                                ...data,
                                phone: value
                            });
                        }}
                    />
                </Row>

                <Row>
                    <CFormLabelCustom>Дата рождения</CFormLabelCustom>
                    <CFormInputWithMask
                        required
                        placeholder='Дата рождения'
                        mask={Date}
                        max={new Date()}
                        lazy={false}
                        value={data?.dateBorn}
                        onComplete={(value, mask) => {
                            setData({
                                ...data,
                                dateBorn: value
                            })
                        }}
                    />
                </Row>

                <Row>
                    <CFormLabelCustom>ИНН</CFormLabelCustom>
                    <CFormInputWithMask
                        required
                        mask='0000000000[00]'
                        lazy={true}
                        unmask={true}
                        defaultValue={data?.inn}
                        onComplete={(value, mask) => {
                            setData({
                                ...data,
                                inn: value
                            });
                        }}
                    />
                </Row>
                    
                <BlueButton onSubmit={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                }}>
                    {loading == true ? <CSpinner color='light' size='sm'/> : 'Зарегистрировать'}
                </BlueButton>

                {/* <HaveAccount>Уже есть аккаунт? <Span onClick={openLogin}>Войти</Span></HaveAccount> */}
            </CFormCustom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 655px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px; 
`;

const Header = styled.p`
    font-size: 24px;
    font-weight: 600;
    color: #555555;
`;

const CFormLabelCustom = styled(CFormLabel)`
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -10px;
`;

const CFormInputCustom = styled(CFormInput)`
    width: 100%;
    max-width: 300px;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const HasPatronymic = styled.div`
    display: flex;
    gap: 10px;
    align-self: flex-start;
    margin-bottom: -5px;
    margin-top: 5px;
    cursor: pointer;

    :focus, :active {
        box-shadow: none;
    }
`;

const CFormCheckCustom = styled(CFormCheck)`
  
`;

const CFormCustom = styled(CForm)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;
`;

const HaveAccount = styled.p`
    cursor: default;
    font-size: 14px;
    font-weight: 500;
`;

const Span = styled.span`
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;
const SelectPlaceholder = styled.div`
    width: 100%;
    /* max-width: 300px; */
`; 

const Row = styled.div`
    width: 300px;
`;