import DepositAPI from '../../helpers/api/DepositAPI.js';
import * as types from '../ActionTypes.js';

let startLoadingDepositedFiles = () => {
    return {
        type: types.LOAD_DEPOSITED_FILES
    }
};

let onLoadDepositedFilesSuccess = (files) => {
    return {
        type: types.LOAD_DEPOSITED_FILES_SUCCESS,
        files: files
    };
};

let onLoadDepositedFilesFail = (error) => {
    return {
        type: types.LOAD_DEPOSITED_FILES_FAIL,
        error: error
    }
};

export function loadDepositedFiles() {
    return (dispatch, getState) => {
        dispatch(startLoadingDepositedFiles());
        return DepositAPI.getAllDeposits().then(
            files => dispatch(onLoadDepositedFilesSuccess(files)),
            error => dispatch(onLoadDepositedFilesFail(error))
        )
    }
}

let startDepositing = () => {
    return {
        type: types.DEPOSIT_FILE
    }
};

let onDepositSuccess = (file) => {
    return {
        type: types.DEPOSIT_FILE_SUCCESS,
        file: file
    };
};

let onDepositFail = (error) => {
    return {
        type: types.DEPOSIT_FILE_FAIL,
        error: error
    }
};

export function depositFile(data) {
    return (dispatch, getState) => {
        dispatch(startDepositing());
        return DepositAPI.deposit(data).then(
            file => dispatch(onDepositSuccess(file)),
            error => dispatch(onDepositFail(error))
        )
    }
}