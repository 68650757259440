let countries = [
{ "id": 643, "alpha2": "ru", "alpha3": "rus", "name": "Россия" },
{ "id": 398, "alpha2": "kz", "alpha3": "kaz", "name": "Казахстан" },
{ "id": 36, "alpha2": "au", "alpha3": "aus", "name": "Австралия" },
{ "id": 40, "alpha2": "at", "alpha3": "aut", "name": "Австрия" },
{ "id": 31, "alpha2": "az", "alpha3": "aze", "name": "Азербайджан" },
{ "id": 248, "alpha2": "ax", "alpha3": "ala", "name": "Аландские острова" },
{ "id": 8, "alpha2": "al", "alpha3": "alb", "name": "Албания" },
{ "id": 12, "alpha2": "dz", "alpha3": "dza", "name": "Алжир" },
{ "id": 850, "alpha2": "vi", "alpha3": "vir", "name": "Виргинские Острова (США)" },
{ "id": 16, "alpha2": "as", "alpha3": "asm", "name": "Американское Самоа" },
{ "id": 660, "alpha2": "ai", "alpha3": "aia", "name": "Ангилья" },
{ "id": 24, "alpha2": "ao", "alpha3": "ago", "name": "Ангола" },
{ "id": 20, "alpha2": "ad", "alpha3": "and", "name": "Андорра" },
{ "id": 10, "alpha2": "aq", "alpha3": "ata", "name": "Антарктика" },
{ "id": 28, "alpha2": "ag", "alpha3": "atg", "name": "Антигуа и Барбуда" },
{ "id": 32, "alpha2": "ar", "alpha3": "arg", "name": "Аргентина" },
{ "id": 51, "alpha2": "am", "alpha3": "arm", "name": "Армения" },
{ "id": 533, "alpha2": "aw", "alpha3": "abw", "name": "Аруба" },
{ "id": 4, "alpha2": "af", "alpha3": "afg", "name": "Афганистан" },
{ "id": 44, "alpha2": "bs", "alpha3": "bhs", "name": "Багамские Острова" },
{ "id": 50, "alpha2": "bd", "alpha3": "bgd", "name": "Бангладеш" },
{ "id": 52, "alpha2": "bb", "alpha3": "brb", "name": "Барбадос" },
{ "id": 48, "alpha2": "bh", "alpha3": "bhr", "name": "Бахрейн" },
{ "id": 84, "alpha2": "bz", "alpha3": "blz", "name": "Белиз" },
{ "id": 112, "alpha2": "by", "alpha3": "blr", "name": "Беларусь" },
{ "id": 56, "alpha2": "be", "alpha3": "bel", "name": "Бельгия" },
{ "id": 204, "alpha2": "bj", "alpha3": "ben", "name": "Бенин" },
{ "id": 60, "alpha2": "bm", "alpha3": "bmu", "name": "Бермуды" },
{ "id": 100, "alpha2": "bg", "alpha3": "bgr", "name": "Болгария" },
{ "id": 68, "alpha2": "bo", "alpha3": "bol", "name": "Боливия" },
{ "id": 535, "alpha2": "bq", "alpha3": "bes", "name": "Бонайре, Синт-Эстатиус и Саба" },
{ "id": 70, "alpha2": "ba", "alpha3": "bih", "name": "Босния и Герцеговина" },
{ "id": 72, "alpha2": "bw", "alpha3": "bwa", "name": "Ботсвана" },
{ "id": 76, "alpha2": "br", "alpha3": "bra", "name": "Бразилия" },
{ "id": 86, "alpha2": "io", "alpha3": "iot", "name": "Британская Территория в Индийском Океане" },
{ "id": 92, "alpha2": "vg", "alpha3": "vgb", "name": "Виргинские Острова (Великобритания)" },
{ "id": 96, "alpha2": "bn", "alpha3": "brn", "name": "Бруней" },
{ "id": 854, "alpha2": "bf", "alpha3": "bfa", "name": "Буркина-Фасо" },
{ "id": 108, "alpha2": "bi", "alpha3": "bdi", "name": "Бурунди" },
{ "id": 64, "alpha2": "bt", "alpha3": "btn", "name": "Бутан" },
{ "id": 548, "alpha2": "vu", "alpha3": "vut", "name": "Вануату" },
{ "id": 336, "alpha2": "va", "alpha3": "vat", "name": "Ватикан" },
{ "id": 826, "alpha2": "gb", "alpha3": "gbr", "name": "Великобритания" },
{ "id": 348, "alpha2": "hu", "alpha3": "hun", "name": "Венгрия" },
{ "id": 862, "alpha2": "ve", "alpha3": "ven", "name": "Венесуэла" },
{ "id": 581, "alpha2": "um", "alpha3": "umi", "name": "Внешние малые острова США" },
{ "id": 626, "alpha2": "tl", "alpha3": "tls", "name": "Восточный Тимор" },
{ "id": 704, "alpha2": "vn", "alpha3": "vnm", "name": "Вьетнам" },
{ "id": 266, "alpha2": "ga", "alpha3": "gab", "name": "Габон" },
{ "id": 332, "alpha2": "ht", "alpha3": "hti", "name": "Гаити" },
{ "id": 328, "alpha2": "gy", "alpha3": "guy", "name": "Гайана" },
{ "id": 270, "alpha2": "gm", "alpha3": "gmb", "name": "Гамбия" },
{ "id": 288, "alpha2": "gh", "alpha3": "gha", "name": "Гана" },
{ "id": 312, "alpha2": "gp", "alpha3": "glp", "name": "Гваделупа" },
{ "id": 320, "alpha2": "gt", "alpha3": "gtm", "name": "Гватемала" },
{ "id": 254, "alpha2": "gf", "alpha3": "guf", "name": "Гвиана" },
{ "id": 324, "alpha2": "gn", "alpha3": "gin", "name": "Гвинея" },
{ "id": 624, "alpha2": "gw", "alpha3": "gnb", "name": "Гвинея-Бисау" },
{ "id": 276, "alpha2": "de", "alpha3": "deu", "name": "Германия" },
{ "id": 831, "alpha2": "gg", "alpha3": "ggy", "name": "Гернси" },
{ "id": 292, "alpha2": "gi", "alpha3": "gib", "name": "Гибралтар" },
{ "id": 340, "alpha2": "hn", "alpha3": "hnd", "name": "Гондурас" },
{ "id": 344, "alpha2": "hk", "alpha3": "hkg", "name": "Гонконг" },
{ "id": 308, "alpha2": "gd", "alpha3": "grd", "name": "Гренада" },
{ "id": 304, "alpha2": "gl", "alpha3": "grl", "name": "Гренландия" },
{ "id": 300, "alpha2": "gr", "alpha3": "grc", "name": "Греция" },
{ "id": 268, "alpha2": "ge", "alpha3": "geo", "name": "Грузия" },
{ "id": 316, "alpha2": "gu", "alpha3": "gum", "name": "Гуам" },
{ "id": 208, "alpha2": "dk", "alpha3": "dnk", "name": "Дания" },
{ "id": 832, "alpha2": "je", "alpha3": "jey", "name": "Джерси" },
{ "id": 262, "alpha2": "dj", "alpha3": "dji", "name": "Джибути" },
{ "id": 212, "alpha2": "dm", "alpha3": "dma", "name": "Доминика" },
{ "id": 214, "alpha2": "do", "alpha3": "dom", "name": "Доминиканская Республика" },
{ "id": 180, "alpha2": "cd", "alpha3": "cod", "name": "ДР Конго" },
{ "id": 818, "alpha2": "eg", "alpha3": "egy", "name": "Египет" },
{ "id": 894, "alpha2": "zm", "alpha3": "zmb", "name": "Замбия" },
{ "id": 732, "alpha2": "eh", "alpha3": "esh", "name": "САДР" },
{ "id": 716, "alpha2": "zw", "alpha3": "zwe", "name": "Зимбабве" },
{ "id": 376, "alpha2": "il", "alpha3": "isr", "name": "Израиль" },
{ "id": 356, "alpha2": "in", "alpha3": "ind", "name": "Индия" },
{ "id": 360, "alpha2": "id", "alpha3": "idn", "name": "Индонезия" },
{ "id": 400, "alpha2": "jo", "alpha3": "jor", "name": "Иордания" },
{ "id": 368, "alpha2": "iq", "alpha3": "irq", "name": "Ирак" },
{ "id": 364, "alpha2": "ir", "alpha3": "irn", "name": "Иран" },
{ "id": 372, "alpha2": "ie", "alpha3": "irl", "name": "Ирландия" },
{ "id": 352, "alpha2": "is", "alpha3": "isl", "name": "Исландия" },
{ "id": 724, "alpha2": "es", "alpha3": "esp", "name": "Испания" },
{ "id": 380, "alpha2": "it", "alpha3": "ita", "name": "Италия" },
{ "id": 887, "alpha2": "ye", "alpha3": "yem", "name": "Йемен" },
{ "id": 132, "alpha2": "cv", "alpha3": "cpv", "name": "Кабо-Верде" },
{ "id": 136, "alpha2": "ky", "alpha3": "cym", "name": "Острова Кайман" },
{ "id": 116, "alpha2": "kh", "alpha3": "khm", "name": "Камбоджа" },
{ "id": 120, "alpha2": "cm", "alpha3": "cmr", "name": "Камерун" },
{ "id": 124, "alpha2": "ca", "alpha3": "can", "name": "Канада" },
{ "id": 634, "alpha2": "qa", "alpha3": "qat", "name": "Катар" },
{ "id": 404, "alpha2": "ke", "alpha3": "ken", "name": "Кения" },
{ "id": 196, "alpha2": "cy", "alpha3": "cyp", "name": "Кипр" },
{ "id": 417, "alpha2": "kg", "alpha3": "kgz", "name": "Киргизия" },
{ "id": 296, "alpha2": "ki", "alpha3": "kir", "name": "Кирибати" },
{ "id": 158, "alpha2": "tw", "alpha3": "twn", "name": "Китайская Республика" },
{ "id": 408, "alpha2": "kp", "alpha3": "prk", "name": "КНДР (Корейская Народно-Демократическая Республика)" },
{ "id": 156, "alpha2": "cn", "alpha3": "chn", "name": "Китай (Китайская Народная Республика)" },
{ "id": 166, "alpha2": "cc", "alpha3": "cck", "name": "Кокосовые острова" },
{ "id": 170, "alpha2": "co", "alpha3": "col", "name": "Колумбия" },
{ "id": 174, "alpha2": "km", "alpha3": "com", "name": "Коморы" },
{ "id": 188, "alpha2": "cr", "alpha3": "cri", "name": "Коста-Рика" },
{ "id": 384, "alpha2": "ci", "alpha3": "civ", "name": "Кот-д’Ивуар" },
{ "id": 192, "alpha2": "cu", "alpha3": "cub", "name": "Куба" },
{ "id": 414, "alpha2": "kw", "alpha3": "kwt", "name": "Кувейт" },
{ "id": 531, "alpha2": "cw", "alpha3": "cuw", "name": "Кюрасао" },
{ "id": 418, "alpha2": "la", "alpha3": "lao", "name": "Лаос" },
{ "id": 428, "alpha2": "lv", "alpha3": "lva", "name": "Латвия" },
{ "id": 426, "alpha2": "ls", "alpha3": "lso", "name": "Лесото" },
{ "id": 430, "alpha2": "lr", "alpha3": "lbr", "name": "Либерия" },
{ "id": 422, "alpha2": "lb", "alpha3": "lbn", "name": "Ливан" },
{ "id": 434, "alpha2": "ly", "alpha3": "lby", "name": "Ливия" },
{ "id": 440, "alpha2": "lt", "alpha3": "ltu", "name": "Литва" },
{ "id": 438, "alpha2": "li", "alpha3": "lie", "name": "Лихтенштейн" },
{ "id": 442, "alpha2": "lu", "alpha3": "lux", "name": "Люксембург" },
{ "id": 480, "alpha2": "mu", "alpha3": "mus", "name": "Маврикий" },
{ "id": 478, "alpha2": "mr", "alpha3": "mrt", "name": "Мавритания" },
{ "id": 450, "alpha2": "mg", "alpha3": "mdg", "name": "Мадагаскар" },
{ "id": 175, "alpha2": "yt", "alpha3": "myt", "name": "Майотта" },
{ "id": 446, "alpha2": "mo", "alpha3": "mac", "name": "Макао" },
{ "id": 807, "alpha2": "mk", "alpha3": "mkd", "name": "Северная Македония" },
{ "id": 454, "alpha2": "mw", "alpha3": "mwi", "name": "Малави" },
{ "id": 458, "alpha2": "my", "alpha3": "mys", "name": "Малайзия" },
{ "id": 466, "alpha2": "ml", "alpha3": "mli", "name": "Мали" },
{ "id": 462, "alpha2": "mv", "alpha3": "mdv", "name": "Мальдивы" },
{ "id": 470, "alpha2": "mt", "alpha3": "mlt", "name": "Мальта" },
{ "id": 504, "alpha2": "ma", "alpha3": "mar", "name": "Марокко" },
{ "id": 474, "alpha2": "mq", "alpha3": "mtq", "name": "Мартиника" },
{ "id": 584, "alpha2": "mh", "alpha3": "mhl", "name": "Маршалловы Острова" },
{ "id": 484, "alpha2": "mx", "alpha3": "mex", "name": "Мексика" },
{ "id": 583, "alpha2": "fm", "alpha3": "fsm", "name": "Микронезия" },
{ "id": 508, "alpha2": "mz", "alpha3": "moz", "name": "Мозамбик" },
{ "id": 498, "alpha2": "md", "alpha3": "mda", "name": "Молдавия" },
{ "id": 492, "alpha2": "mc", "alpha3": "mco", "name": "Монако" },
{ "id": 496, "alpha2": "mn", "alpha3": "mng", "name": "Монголия" },
{ "id": 500, "alpha2": "ms", "alpha3": "msr", "name": "Монтсеррат" },
{ "id": 104, "alpha2": "mm", "alpha3": "mmr", "name": "Мьянма" },
{ "id": 516, "alpha2": "na", "alpha3": "nam", "name": "Намибия" },
{ "id": 520, "alpha2": "nr", "alpha3": "nru", "name": "Науру" },
{ "id": 524, "alpha2": "np", "alpha3": "npl", "name": "Непал" },
{ "id": 562, "alpha2": "ne", "alpha3": "ner", "name": "Нигер" },
{ "id": 566, "alpha2": "ng", "alpha3": "nga", "name": "Нигерия" },
{ "id": 528, "alpha2": "nl", "alpha3": "nld", "name": "Нидерланды" },
{ "id": 558, "alpha2": "ni", "alpha3": "nic", "name": "Никарагуа" },
{ "id": 570, "alpha2": "nu", "alpha3": "niu", "name": "Ниуэ" },
{ "id": 554, "alpha2": "nz", "alpha3": "nzl", "name": "Новая Зеландия" },
{ "id": 540, "alpha2": "nc", "alpha3": "ncl", "name": "Новая Каледония" },
{ "id": 578, "alpha2": "no", "alpha3": "nor", "name": "Норвегия" },
{ "id": 784, "alpha2": "ae", "alpha3": "are", "name": "ОАЭ" },
{ "id": 512, "alpha2": "om", "alpha3": "omn", "name": "Оман" },
{ "id": 74, "alpha2": "bv", "alpha3": "bvt", "name": "Остров Буве" },
{ "id": 833, "alpha2": "im", "alpha3": "imn", "name": "Остров Мэн" },
{ "id": 184, "alpha2": "ck", "alpha3": "cok", "name": "Острова Кука" },
{ "id": 574, "alpha2": "nf", "alpha3": "nfk", "name": "Остров Норфолк" },
{ "id": 162, "alpha2": "cx", "alpha3": "cxr", "name": "Остров Рождества" },
{ "id": 612, "alpha2": "pn", "alpha3": "pcn", "name": "Острова Питкэрн" },
{ "id": 654, "alpha2": "sh", "alpha3": "shn", "name": "Остров Святой Елены" },
{ "id": 586, "alpha2": "pk", "alpha3": "pak", "name": "Пакистан" },
{ "id": 585, "alpha2": "pw", "alpha3": "plw", "name": "Палау" },
{ "id": 275, "alpha2": "ps", "alpha3": "pse", "name": "Государство Палестина" },
{ "id": 591, "alpha2": "pa", "alpha3": "pan", "name": "Панама" },
{ "id": 598, "alpha2": "pg", "alpha3": "png", "name": "Папуа — Новая Гвинея" },
{ "id": 600, "alpha2": "py", "alpha3": "pry", "name": "Парагвай" },
{ "id": 604, "alpha2": "pe", "alpha3": "per", "name": "Перу" },
{ "id": 616, "alpha2": "pl", "alpha3": "pol", "name": "Польша" },
{ "id": 620, "alpha2": "pt", "alpha3": "prt", "name": "Португалия" },
{ "id": 630, "alpha2": "pr", "alpha3": "pri", "name": "Пуэрто-Рико" },
{ "id": 178, "alpha2": "cg", "alpha3": "cog", "name": "Республика Конго" },
{ "id": 410, "alpha2": "kr", "alpha3": "kor", "name": "Республика Корея" },
{ "id": 638, "alpha2": "re", "alpha3": "reu", "name": "Реюньон" },
{ "id": 646, "alpha2": "rw", "alpha3": "rwa", "name": "Руанда" },
{ "id": 642, "alpha2": "ro", "alpha3": "rou", "name": "Румыния" },
{ "id": 222, "alpha2": "sv", "alpha3": "slv", "name": "Сальвадор" },
{ "id": 882, "alpha2": "ws", "alpha3": "wsm", "name": "Самоа" },
{ "id": 674, "alpha2": "sm", "alpha3": "smr", "name": "Сан-Марино" },
{ "id": 678, "alpha2": "st", "alpha3": "stp", "name": "Сан-Томе и Принсипи" },
{ "id": 682, "alpha2": "sa", "alpha3": "sau", "name": "Саудовская Аравия" },
{ "id": 748, "alpha2": "sz", "alpha3": "swz", "name": "Эсватини" },
{ "id": 580, "alpha2": "mp", "alpha3": "mnp", "name": "Северные Марианские Острова" },
{ "id": 690, "alpha2": "sc", "alpha3": "syc", "name": "Сейшельские Острова" },
{ "id": 652, "alpha2": "bl", "alpha3": "blm", "name": "Сен-Бартелеми" },
{ "id": 663, "alpha2": "mf", "alpha3": "maf", "name": "Сен-Мартен" },
{ "id": 666, "alpha2": "pm", "alpha3": "spm", "name": "Сен-Пьер и Микелон" },
{ "id": 686, "alpha2": "sn", "alpha3": "sen", "name": "Сенегал" },
{ "id": 670, "alpha2": "vc", "alpha3": "vct", "name": "Сент-Винсент и Гренадины" },
{ "id": 659, "alpha2": "kn", "alpha3": "kna", "name": "Сент-Китс и Невис" },
{ "id": 662, "alpha2": "lc", "alpha3": "lca", "name": "Сент-Люсия" },
{ "id": 688, "alpha2": "rs", "alpha3": "srb", "name": "Сербия" },
{ "id": 702, "alpha2": "sg", "alpha3": "sgp", "name": "Сингапур" },
{ "id": 534, "alpha2": "sx", "alpha3": "sxm", "name": "Синт-Мартен" },
{ "id": 760, "alpha2": "sy", "alpha3": "syr", "name": "Сирия" },
{ "id": 703, "alpha2": "sk", "alpha3": "svk", "name": "Словакия" },
{ "id": 705, "alpha2": "si", "alpha3": "svn", "name": "Словения" },
{ "id": 90, "alpha2": "sb", "alpha3": "slb", "name": "Соломоновы Острова" },
{ "id": 706, "alpha2": "so", "alpha3": "som", "name": "Сомали" },
{ "id": 729, "alpha2": "sd", "alpha3": "sdn", "name": "Судан" },
{ "id": 740, "alpha2": "sr", "alpha3": "sur", "name": "Суринам" },
{ "id": 840, "alpha2": "us", "alpha3": "usa", "name": "США" },
{ "id": 694, "alpha2": "sl", "alpha3": "sle", "name": "Сьерра-Леоне" },
{ "id": 762, "alpha2": "tj", "alpha3": "tjk", "name": "Таджикистан" },
{ "id": 764, "alpha2": "th", "alpha3": "tha", "name": "Таиланд" },
{ "id": 834, "alpha2": "tz", "alpha3": "tza", "name": "Танзания" },
{ "id": 796, "alpha2": "tc", "alpha3": "tca", "name": "Теркс и Кайкос" },
{ "id": 768, "alpha2": "tg", "alpha3": "tgo", "name": "Того" },
{ "id": 772, "alpha2": "tk", "alpha3": "tkl", "name": "Токелау" },
{ "id": 776, "alpha2": "to", "alpha3": "ton", "name": "Тонга" },
{ "id": 780, "alpha2": "tt", "alpha3": "tto", "name": "Тринидад и Тобаго" },
{ "id": 798, "alpha2": "tv", "alpha3": "tuv", "name": "Тувалу" },
{ "id": 788, "alpha2": "tn", "alpha3": "tun", "name": "Тунис" },
{ "id": 795, "alpha2": "tm", "alpha3": "tkm", "name": "Туркменистан" },
{ "id": 792, "alpha2": "tr", "alpha3": "tur", "name": "Турция" },
{ "id": 800, "alpha2": "ug", "alpha3": "uga", "name": "Уганда" },
{ "id": 860, "alpha2": "uz", "alpha3": "uzb", "name": "Узбекистан" },
{ "id": 804, "alpha2": "ua", "alpha3": "ukr", "name": "Украина" },
{ "id": 876, "alpha2": "wf", "alpha3": "wlf", "name": "Уоллис и Футуна" },
{ "id": 858, "alpha2": "uy", "alpha3": "ury", "name": "Уругвай" },
{ "id": 234, "alpha2": "fo", "alpha3": "fro", "name": "Фарерские острова" },
{ "id": 242, "alpha2": "fj", "alpha3": "fji", "name": "Фиджи" },
{ "id": 608, "alpha2": "ph", "alpha3": "phl", "name": "Филиппины" },
{ "id": 246, "alpha2": "fi", "alpha3": "fin", "name": "Финляндия" },
{ "id": 238, "alpha2": "fk", "alpha3": "flk", "name": "Фолклендские острова" },
{ "id": 250, "alpha2": "fr", "alpha3": "fra", "name": "Франция" },
{ "id": 258, "alpha2": "pf", "alpha3": "pyf", "name": "Французская Полинезия" },
{ "id": 260, "alpha2": "tf", "alpha3": "atf", "name": "Французские Южные и Антарктические территории" },
{ "id": 334, "alpha2": "hm", "alpha3": "hmd", "name": "Херд и Макдональд" },
{ "id": 191, "alpha2": "hr", "alpha3": "hrv", "name": "Хорватия" },
{ "id": 140, "alpha2": "cf", "alpha3": "caf", "name": "ЦАР" },
{ "id": 148, "alpha2": "td", "alpha3": "tcd", "name": "Чад" },
{ "id": 499, "alpha2": "me", "alpha3": "mne", "name": "Черногория" },
{ "id": 203, "alpha2": "cz", "alpha3": "cze", "name": "Чехия" },
{ "id": 152, "alpha2": "cl", "alpha3": "chl", "name": "Чили" },
{ "id": 756, "alpha2": "ch", "alpha3": "che", "name": "Швейцария" },
{ "id": 752, "alpha2": "se", "alpha3": "swe", "name": "Швеция" },
{ "id": 744, "alpha2": "sj", "alpha3": "sjm", "name": "Шпицберген и Ян-Майен" },
{ "id": 144, "alpha2": "lk", "alpha3": "lka", "name": "Шри-Ланка" },
{ "id": 218, "alpha2": "ec", "alpha3": "ecu", "name": "Эквадор" },
{ "id": 226, "alpha2": "gq", "alpha3": "gnq", "name": "Экваториальная Гвинея" },
{ "id": 232, "alpha2": "er", "alpha3": "eri", "name": "Эритрея" },
{ "id": 233, "alpha2": "ee", "alpha3": "est", "name": "Эстония" },
{ "id": 231, "alpha2": "et", "alpha3": "eth", "name": "Эфиопия" },
{ "id": 710, "alpha2": "za", "alpha3": "zaf", "name": "ЮАР" },
{ "id": 239, "alpha2": "gs", "alpha3": "sgs", "name": "Южная Георгия и Южные Сандвичевы Острова" },
{ "id": 728, "alpha2": "ss", "alpha3": "ssd", "name": "Южный Судан" },
{ "id": 388, "alpha2": "jm", "alpha3": "jam", "name": "Ямайка" },
{ "id": 392, "alpha2": "jp", "alpha3": "jpn", "name": "Япония" }];

countries = countries.reduce((arr, country) => arr.concat({
    value: country.alpha2,
    label: country.name
}), []);

export default countries;