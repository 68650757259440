import axios from 'axios';
import { API_ENDPOINT } from '../config';

const $api = axios.create({
    withCredentials: true
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = localStorage.getItem('accessToken')
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status == 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${API_ENDPOINT}/api/user/updateTokens`, {
                withCredentials: true
            });
            localStorage.setItem('accessToken', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (error) {
            console.log('error: unauthorized: ', error);
        }
    }
    throw error;
});

export default $api;