import React, { useState } from 'react'
import styled from 'styled-components'
import { CForm, CFormInput, CFormLabel, CFormTextarea } from '@coreui/react'
import { IMaskMixin } from 'react-imask';
import EmailAPI from '../helpers/api/EmailAPI';
import toast from 'react-hot-toast'
import ReCAPTCHA from 'react-google-recaptcha';

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
    <CFormInput {...props} ref={inputRef} style={{boxShadow: 'none', border: '1px solid var(--border-light)'}} />
));

export const ConsultationsPage = () => {

    const [data, setData] = useState({});
    const [captcha, setCaptcha] = useState(undefined);

    return (
        <Wrapper>
            <Header>Консультации</Header>
            <Text>
                Оказываем консультационные услуги по вопросам Авторского права для этого:
                необходимо заполнить форму запроса
            </Text>

            <CFormCustom onSubmit={async (evt) => {
                evt.stopPropagation();
                evt.preventDefault();

                await EmailAPI.sendConsultation(data);
                toast.success('Письмо отправлено');
            }}>
                <CFormLabel>ФИО</CFormLabel>
                <CFormInputCustom
                    placeholder='ФИО'
                    required
                    value={data.name || ''}
                    onChange={evt => {
                        if (evt.target.value.match(/[^А-Яа-яёЁ]/g) != null) {
                            return
                        }
                        setData({
                            ...data,
                            name: evt.target.value
                        })
                    }}
                />

                <CFormLabel>Телефон</CFormLabel>
                <CFormInputWithMask
                    required
                    mask="+{7} (000) 000-00-00"
                    lazy={false}
                    unmask={true}
                    defaultValue={data?.phone}
                    onComplete={(value, mask) => {
                        setData({
                            ...data,
                            phone: value
                        });
                    }}
                />

                <CFormLabel>Почта</CFormLabel>
                <CFormInputCustom
                    required
                    placeholder='Почта'
                    type='email'
                    value={data?.email}
                    onChange={evt => {
                        if (evt.target.value.match(/[А-Яа-яёЁ]/g) != null) {
                            return
                        }
                        setData({
                            ...data,
                            email: evt.target.value
                        })
                    }}
                />

                <CFormLabel>Ваш вопрос</CFormLabel>
                <CFormTextareaCustom
                    required
                    placeholder='Ваш вопрос'
                    value={data.text || ''}
                    onChange={evt => {
                        setData({
                            ...data,
                            text: evt.target.value
                        })
                    }}
                />

                <ReCAPTCHA
                    style={{marginTop: '10px'}}
                    sitekey={'6LeT7DQpAAAAAPvjApEbXm-y3FnE3lbIpKSB2_lP'}
                    onChange={evt => setCaptcha(evt)}
                />

                <SendButton disabled={!captcha}>
                    Отправить
                </SendButton>
            </CFormCustom>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: auto;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    padding: 50px 10px;
`;

const Header = styled.span`
    font-size: 28px;
    font-weight: 600;
    color: var(--text-dark-blue);
`;

const Text = styled.p`
    font-size: 20px;
`;

const CFormCustom = styled(CForm)`
    margin: auto;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
`;

const CFormInputCustom = styled(CFormInput)`
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const CFormTextareaCustom = styled(CFormTextarea)`
    
    &:focus {
        box-shadow: none;
        border: 1px solid var(--border-light);
    }
`;

const SendButton = styled.button`
    margin-top: 10px;
    width: 100%;
    height: fit-content;
    padding: 10px 40px;
    border: none;
    background-color: ${props => props.disabled ? 'lightgrey' : 'var(--primary)'};
    border-radius: 5px;
    color: white;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        opacity: 0.8;
    }
`;